import * as React from 'react';
import { Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Grid, Box, Typography, Container } from '@mui/material';
import { LockOutlined as LockOutlinedIcon } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { authenticationService } from '../_services';
import { useNavigate } from 'react-router-dom';
import { config, authHeader, utils } from '../_helpers';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const errorStyle = {
    color: '#c51244',
    padding: '10px',
    marginTop: '10px'
}

const theme = createTheme();

export default function LoginPage(props) {

    const [page, setPage] = React.useState('/');
    const [errMessage, setErrMessage] = React.useState('');
    const navigate = useNavigate();

    const [loginState, setLoginState] = React.useState({
        username: '',
        password: '',
        isSubmitting: false,
        isRemember: false,
        isOk: false
    });

    // console.log("Login Page => Auth", authenticationService.currentUserValue);
    // if (authenticationService.currentUserValue != null) {
    //     console.log("Redirect from Login Page to Home Page");
    //     navigate("/");
    // }

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const username = data.get('username').trim();
        const password = data.get('password').trim();
        const rememberMe = data.get('remember_me') == 'on' ? true : false;

        if ((username == null) || username == '') {
            setErrMessage(prevValue => prevValue = 'Please specify your username')
            return;
        }
        if ((password == null) || password == '') {
            setErrMessage(prevValue => prevValue = 'Please specify your password');
            return;
        }

        setLoginState(prevValue => prevValue = { ...loginState, isSubmitting: true });
        authenticationService.login(username, password, rememberMe)
            .then(() => {
                if (utils.IsDebugMode)
                    console.log('Resolve');
                //const { from } = this.props.location.state || { from: { pathname: "/" } };
                //this.props.history.push(from);                
                //const newLoginState = { ...loginState, isSubmitting: false, isOk: true }
                //setLoginState(newLoginState);
                //setErrMessage("");
                navigate("/");
            })
            .catch(
                error => {
                    console.error('Reject', error);
                    if (typeof error === 'string') {
                        setErrMessage(prevValue => prevValue = error);
                    } else {
                        setErrMessage(prevValue => prevValue = "Cannot communicate to Server");
                    }
                    //const newLoginState = { ...loginState, isSubmitting: false, isOk: false }
                    //setLoginState(prevValue => prevValue = newLoginState);
                    //setLoggedIn(false);

                    setLoginState(prevValue => prevValue = { ...loginState, isSubmitting: false });
                }
            );
    };

    const handleCheck = (event) => {
        setLoginState(prevValue => prevValue = { ...loginState, isRemember: event.target.checked });
        setErrMessage("");
    }
    const handleTextChanged = (event) => {
        setErrMessage("");
    }

    React.useEffect(() => {
    })

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Login
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            autoFocus
                            onChange={handleTextChanged}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={handleTextChanged}
                        />
                        <FormControlLabel id="remember" name="remember"
                            control={<Checkbox color="primary" onChange={handleCheck} checked={loginState.isRemember} name="remember_me" />}
                            label="Remember me"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={loginState.isSubmitting}
                        >
                            Login
                        </Button>
                        {(errMessage != "") && <div style={errorStyle}>{errMessage}</div>}
                        {/*
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="#" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                        */}
                    </Box>
                </Box>
                {/*<Copyright sx={{ mt: 8, mb: 4 }} />*/}
            </Container>
        </ThemeProvider>
    );
}