import { InstallDesktop, StarRateOutlined } from '@mui/icons-material';
import PropTypes from 'prop-types';
import {
    Box, Button, Card, FormControl, Grid, InputLabel, MenuItem, Modal, Select, Stack,
    TextField, Typography, Tabs, Tab, Autocomplete, Checkbox, FormGroup, FormControlLabel, Paper, InputBase, IconButton, Container, Switch, RadioGroup, Radio, useMediaQuery, Backdrop, CircularProgress
} from '@mui/material';
import React, { useMemo, useState, useEffect, useReducer } from 'react';
import { authenticationService, masterService, partService } from '../_services';
import PartsTable from '../components/Parts/PartsTable';

import { PARTS_DATA } from '../components/Parts/mock_data'
import { BIG_DATA } from '../components/Parts/big_data'
import { TRANS_DATA } from '../components/Parts/trans_data'

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { format } from 'date-fns'
import TransactionTable from '../components/Parts/TransactionTable';
import PartModifierForm from '../components/Parts/PartModifierForm';
import PartConsumeForm from '../components/Parts/PartConsumeForm';
import { utils } from '../_helpers';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function PartsFormWithdraw2(props) {
    const selectedPart = { ...props.part };
    const [withdrawValue, setWithDrawValue] = useState(() => { return 1 });
    const [open, setOpen] = useState(() => { return props.show });
    /*
    if ((open != props.show) && props.show) {
        setOpen(true);
    }
    */

    const handleClose = () => {
        setOpen(false);
        props.notifyFormEvent({ name: 'close', value: 0, remark: '' });
    }
    const handleWithdrawNumberChanged = (e) => {
        setWithDrawValue(e.target.value);
    }
    const handleWithdrawConfirmClicked = (value) => {
        props.notifyFormEvent({ name: 'withdraw', value: value, remark: '' });
    };

    return (
        <Modal
            open={props.show}
        >
            <Box sx={{ ...style, width: 550, height: 420, borderRadius: 3 }}>
                <h5 id="child-modal-title"># {selectedPart.id}</h5>
                <div className='part-withdrawer-form-line' />
                <Stack direction="row">
                    <div className="part-withdrawer-form-panel-field">
                        <div className='row col-12'>
                            <p id="child-modal-description">
                                {selectedPart.title}
                            </p>
                        </div>
                        <div className='row'>
                            <strong className='col-4'>Operation:</strong>
                            <div className='col-8'>{selectedPart.operationName}</div>
                        </div>
                        <div className='row'>
                            <strong className='col-4'>Product:</strong>
                            <div className='col-8'>{selectedPart.product}</div>
                        </div>
                        <div className='row'>
                            <strong className='col-4'>Type:</strong>
                            <div className='col-8'>{(selectedPart.category == 1 ? 'Consumable' : 'Spare Part')}</div>
                        </div>
                    </div>
                    <div className="part-withdrawer-form-image-field">
                        <img width={120} height={120} />
                    </div>
                </Stack>
                <div className='row'>
                    <div className="col">
                        <Card style={{ margin: '10px', padding: '5px', height: '150px', backgroundColor: '#bbcded' }}>
                            <Stack direction="row">
                                <div className='part-withdrawer-form-onhand-field'>
                                    <div className='row'>
                                        <strong className='col-6'>Safety Level:</strong>
                                        <div className='col-6'>{selectedPart.safetyLevel}</div>
                                    </div>
                                    <div className='row'>
                                        <strong className='col-6'>Onhand:</strong>
                                        <div className='col-6'>{selectedPart.onhand}</div>
                                    </div>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& > :not(style)': { m: 1, width: '25ch' },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <TextField id="outlined-basic" label="Remark" variant="outlined" type="text"
                                            rows="2"
                                            multiline={true}
                                            style={{ width: 250, height: 120 }}
                                        />
                                    </Box>
                                </div>
                                <div className='part-withdrawer-form-withdraw-buttons'>
                                    <Stack>
                                        <Box
                                            component="form"
                                            sx={{
                                                '& > :not(style)': { m: 1, width: '25ch' },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >

                                        </Box>

                                        <Button variant="contained" onClick={(e) => handleWithdrawConfirmClicked(withdrawValue)}
                                            style={{ width: 100, margin: 10 }}>Confirm</Button>
                                    </Stack>

                                </div>
                            </Stack>
                            <TextField id="outlined-basic" label="Withdraw" variant="outlined" type="number"
                                style={{ width: 150, marginTop: 15 }}
                                defaultValue={1}
                                inputProps={{ min: 1, max: selectedPart.onhand }}
                                onChange={handleWithdrawNumberChanged}
                            />
                        </Card>
                    </div>
                </div>
                <Stack direction="row" justifyContent="space-between">
                    <Button onClick={handleClose} align='center'>Close</Button>
                    <Button variant="outlined" >Barcode</Button>
                </Stack>
            </Box>
        </Modal>
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component={span}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const theme = createTheme({
    components: {
        // Name of the component
        MuiTabPanel: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    padding: '0px',
                },
            },
        },
    },
});

const PART_FORM_ACTIONS = {
    MAIN_TAB_CHANGED: "mainTabChanged",
    SUB_TAB_CHANGED: "subTabChanged",
    MODAL_OPEN: "modalOpen",
    MODAL_CLOSE: "modalClose",
    MODE_CHANGED: "modeChanged"
}
function partFormReducer(state, action) {
    switch (action.type) {
        case PART_FORM_ACTIONS.MAIN_TAB_CHANGED:
            return { ...state, mainTab: action.payload.mainTab, subTab: 0 }
        case PART_FORM_ACTIONS.SUB_TAB_CHANGED:
            return { ...state, subTab: action.payload.subTab }
        case PART_FORM_ACTIONS.MODAL_OPEN:
            return { ...state, modalShow: true }
        case PART_FORM_ACTIONS.MODAL_CLOSE:
            return { ...state, modalShow: false }
        case PART_FORM_ACTIONS.MODE_CHANGED:
            if (utils.IsDeBugMode) {
                console.log(PART_FORM_ACTIONS.MODE_CHANGED, state);
            }
            if (action.payload.mode === 'add') {
                return { ...state, mode: 'add', editing: false, adding: true }
            } else if (action.payload.mode === 'edit') {
                return { ...state, mode: 'edit', editing: true, adding: false }
            } else {
                return { ...state, mode: 'view', editing: false, adding: false }
            }
        default:
            return state
    }
}

const PartsPage2 = (props) => {
    const pageStatusCallback = props.pageStatusChanged;

    let activeUser = authenticationService.currentUserValue;
    let roleName = "guest";
    if (activeUser) {
        roleName = "operator"
        if (activeUser.role != "Withdrawer") {
            roleName = "admin";
        }
    }

    const categoryList = [{ label: "Consumable", id: 1 }, { label: "Spare Part", id: 2 }]
    const [partData, setPartData] = useState(() => { return { date: 0, records: [] } });
    const [selectedPart, setSelectedPart] = useState(() => { return { date: 0, data: null } });
    const [openWithdrawer, setOpenWithdrawer] = useState(() => { return false });
    const [openController, setOpenController] = useState(false);
    const [openAddMode, setOpenAddMode] = useState(false)
    const [operationList, setOperationList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [supplierList, setSupplierList] = useState([]);
    const [roleNow, setRowNow] = useState(roleName);
    const [isChecked, setIsChecked] = useState(false);

    const [requery, setRequery] = useState(false);

    const [beingQuery, setBeingQuery] = useState(false);

    /* Get original data */
    const [updateRequest, setUpdateRequest] = useState(() => {
        return true;
    })

    const DEBUG = true;

    if (utils.IsDebugMode) {
        console.log("***PartsPage2 <ENTER>***");
        console.log("--openWithdrawer", openWithdrawer);
        console.log("--openController", openController);
        console.log("--openAddMode", openAddMode);
        console.log("--data", partData);
        console.log("--selectedPart");
        console.log("----selectedPart.date", selectedPart.date);
        console.log("----selectedPart.data", selectedPart.data);       
    }
    /*
    const partData = useMemo(() => {
        setUpdateRequest(prevValue => prevValue = false);
        return BIG_DATA;
    }, [updateRequest])
    */

    const containerStyle = {
        color: "black",
        backgroundColor: "#eee",
        padding: "0px",
        fontFamily: "Arial",
        border: "5px dashed red"
    };
    const stackStyle = {
        backgroundColor: "#aaa",
        padding: "0px",
        border: "5px dashed yellow"
    };
    const rowStyle = {
        backgroundColor: "#aaa",
        padding: "0px",
        border: "5px dotted gray"
    };

    useEffect(() => {
        if (utils.IsDebugMode) {
            console.log("***PartsPage2 <USE-EFFECT>***");
            console.log("--openWithdrawer", openWithdrawer);
            console.log("--openController", openController);
            console.log("--openAddMode", openAddMode);
            console.log("--data", partData);
            console.log("--selectedPart");
            console.log("----selectedPart.date", selectedPart.date);
            console.log("----selectedPart.data", selectedPart.data); 
            console.log("useEffect: PartsPage2", roleName); 
        }        
        if (roleName == "admin") {
            queryPartsWithTrans();
        } else {
            queryParts();
        }
    }, []);
    console.log("partData", partData);
    console.log("selectedPart", selectedPart);

    const rowClickedEventCallback = (rowData) => {
        if (utils.IsDebugMode) {
            console.log("Row Click", rowData);
        }
        setSelectedPart(prevValue => prevValue = { ...selectedPart, date: Date.now(), data: rowData.original });
        if (utils.IsDebugMode)
            console.log("PartsPage2 <RowClick>", selectedPart);
        if (activeUser && (activeUser.role != 'Withdrawer')) {
            setOpenController(true);
        } else {
            setOpenController(true); //setOpenWithdrawer(true);
        }
    }
    const addNewPartClickedEventCallback = async () => {
        const newPart = {
            id: "", category: 1, categoryName: "Consumable", created: new Date().toISOString(), creatorName: "", creatorId: 0,
            groupId: 1, groupName: "",
            customField1: null, customField2: null, customField3: null, customField4: null, customField5: null,
            imageContentType: null, imageData: null, leadTime: 0, onhand: 0,
            operationId: 1, operationName: "", owner: "-",
            price: 0, product: "", remark: null, safetyLevel: 0, status: 0, storeLocation: "",
            supplierId: 1, supplierName: "", title: "", updated: null
        }
        //await queryInformation();
        setSelectedPart(prevValue => prevValue = { ...selectedPart, date: Date.now(), data: newPart });
        setOpenAddMode(prevValue => prevValue = true);
        setOpenController(true);
    }

    const handlePartNoClicked = (row) => {
        setSelectedPart(row);
        if (roleNow == 'admin') {

        } else {
            setOpenWithdrawer(true);
        }
    }
    const handleWithdrawerFormNotify = (event) => {
        if (event.name === 'close') {
            setOpenAddMode(false);
            setOpenController(false);
            setOpenWithdrawer(false);
        } else if (event.name === 'open') {
            if (activeUser) {
                if (roleNow === 'admin') {
                    setOpenController(true);
                } else {
                    setOpenWithdrawer(true);
                }
            }
        } else if (event.name === 'requery') {
            if (roleName == "admin") {
                queryPartsWithTrans();
            } else {
                queryParts();
            }
        }
        else if (event.name === 'updated') {
            //if (activeUser && (roleNow === 'admin')) {
            let copyRecords = [...partData.records];
            let index = copyRecords.findIndex((item) => item.id === event.data.id);
            if (utils.IsDebugMode || true)
                console.log("Part is updated with this data", event, " found index", index);

            if (index > -1) {
                if (utils.IsDebugMode || true) {
                    console.log(`Before: copyRecords[${index}]`, copyRecords[index]);  
                }                  
                copyRecords[index] = event.data;
                if (utils.IsDebugMode || true) {
                    console.log(`After: copyRecords[${index}]`, copyRecords[index]);   
                }
                setPartData(prevValue => prevValue = { ...partData, date: Date.now(), records: copyRecords });
                setSelectedPart(prevValue => prevValue = { ...selectedPart, date: Date.now(), data: event.data });                                     
            }
        }
        else if (event.name === 'deleted') {
            //if (activeUser && (roleNow === 'admin')) {    
            let copyRecords = [...partData.records];
            let result = copyRecords.filter(p => {
                return p.id != event.data.partId;
            });            
            if (utils.IsDebugMode)
                console.log("Part is deleted", event.data, "Before records", copyRecords.length, "After Records", result.length);
            if (result.length < copyRecords.length) {
                setPartData(prevValue => prevValue = { ...partData, date: Date.now(), records: result });
                setSelectedPart(prevValue => prevValue = { ...selectedPart, date: Date.now(), data: null });
            }
            //}
        } else if (event.name === 'transaction') {
            let copyRecords = [...partData.records];
            let index = copyRecords.findIndex((item) => item.id === event.data.partId);
            if (utils.IsDebugMode) {
                console.log("Part's transaction is added", event.data, " found index", index);
            }
            if (index > -1) {
                let record = copyRecords[index];
                record.onhand = event.data.onhand;
                record.status = record.onhand > record.safetyLevel ? 1 : 0;
                copyRecords[index] = record;
                setPartData(prevValue => prevValue = { ...partData, date: Date.now(), records: copyRecords });
                if (utils.IsDebugMode) {
                    console.log("Transaction added, current selectedPart", selectedPart);
                }            
                let newObj = { date: Date.now(), data: record };
                if (utils.IsDebugMode) {
                    console.log("Transaction added, new selectedPart", newObj)
                }
                setSelectedPart(prevValue => prevValue = { ...selectedPart, date: newObj.date, data: newObj.data });
            }
        } else if (event.name === 'consume') {

        } else if (event.name === 'image-changed') {       
            setSelectedPart(prevValue => prevValue = { ...selectedPart, date: event.value, data: event.content });
        }
    }

    function queryParts_Backup() {
        setBeingQuery(true);
        const startExec = +new Date();
        partService.getAll().then((data) => {
            var endExec = +new Date();
            setBeingQuery(false);
            if (utils.IsDebugMode) {
                console.log(`Server responds Parts (records=${data.length}) within ${endExec - startExec}`);
            }
            //const unique = [...new Set(data.map(item => item.operationId))]; // [ 'A', 'B']
            //console.log("Unique ID", unique);
            const unique = Array.from(new Set(data.map(item => item.operationId)))
                .map(id => {
                    return {
                        id: id,
                        label: data.find(s => s.operationId === id).operationName
                    };
                });
            //console.log("Operations", unique);
            setOperationList(unique);
            //const unique2 = [...new Set(data.map(item => item.groupName))]; // [ 'A', 'B']
            const unique2 = Array.from(new Set(data.map(item => item.groupId)))
                .map(id => {
                    return {
                        id: id,
                        label: data.find(s => s.groupId === id).groupName
                    };
                });
            setGroupList(unique2);
            //const unique3 = [...new Set(data.map(item => item.supplierName))]; // [ 'A', 'B']
            const unique3 = Array.from(new Set(data.map(item => item.supplierId)))
                .map(id => {
                    return {
                        id: id,
                        label: data.find(s => s.supplierId === id).supplierName
                    };
                });
            setSupplierList(unique3);

            setPartData(prevValue => prevValue = { ...partData, date: Date.now(), records: data });

        }).catch((error) => {
            setBeingQuery(false);
            var endExec = +new Date();
            console.error(`Server responds Parts [Error: ${error}] within ${endExec - startExec}`);
        });
    }
    if (utils.IsDebugMode) {
        console.log("***PartsPage2 <EXIT>***");
        console.log("--openWithdrawer", openWithdrawer);
        console.log("--openController", openController);
        console.log("--openAddMode", openAddMode);
        console.log("--data", partData);
        console.log("--selectedPart");
        console.log("----selectedPart.date", selectedPart.date);
        console.log("----selectedPart.data", selectedPart.data);  
    }

    function queryParts() {
        setBeingQuery(true);
        const startExec = +new Date();
        partService.getAll().then(async (data) => {
            if (utils.IsDebugMode) {
                console.log('Part Page', data);
            }
            var endExec = +new Date();
            setBeingQuery(false);
            if (utils.IsDebugMode) {
                console.log(`Server responds Parts (records=${data.length}) within ${endExec - startExec}`);
            }

            await queryInformation(data);
            if (utils.IsDebugMode) {
                console.log("**** QUERY DONE ****");
            }

            setPartData(prevValue => prevValue = { ...partData, date: Date.now(), records: data });

        }).catch((error) => {
            setBeingQuery(false);
            var endExec = +new Date();
            console.error(`Server responds Parts [Error: ${error}] within ${endExec - startExec}`);
        });
    }

    function queryPartsWithTrans() {
        setBeingQuery(true);
        const startExec = +new Date();
        partService.getAllWithLastTransaction().then(async (data) => {
            if (utils.IsDebugMode || true) {
                console.log('Part Page', data);
            }    
            var endExec = +new Date();
            setBeingQuery(false);
            if (utils.IsDebugMode) {
                console.log(`Server responds Parts (records=${data.length}) within ${endExec - startExec}`);
            }

            await queryInformation(data);
            if (utils.IsDebugMode) {
                console.log("**** QUERY DONE ****");
            }

            setPartData(prevValue => prevValue = { ...partData, date: Date.now(), records: data });

        }).catch((error) => {
            setBeingQuery(false);
            var endExec = +new Date();
            console.error(`Server responds Parts [Error: ${error}] within ${endExec - startExec}`);
        });
    }

    async function queryInformation(data) {
        const qGroup = queryGroups(data);
        const qOper = queryOperations(data);
        const qSupp = querySuppliers(data);

        await qGroup;
        await qOper;
        await qSupp;
    }
    async function queryGroups(parts) {
        masterService.getAllGroups().then((data) => {
            const results = data.map(item => {
                return {
                    id: item.id,
                    label: item.name
                };
            })
            if (utils.IsDebugMode) {
                console.log(`Parts: Query Groups => records={$results.length}`);
            }
            setGroupList(results);
        }).catch((error) => {
            const results = Array.from(new Set(parts.map(item => item.groupId)))
                .map(id => {
                    return {
                        id: id,
                        label: parts.find(s => s.groupId === id).groupName
                    };
                });
            console.error(`Parts: Query Groups => records=${results.length} with Error=${error}`);
            setGroupList(results);
        });
    }
    async function queryOperations(parts) {
        masterService.getAllOperations().then((data) => {
            const results = data.map(item => {
                return {
                    id: item.id,
                    label: item.name
                };
            })
            if (utils.IsDebugMode) {
                console.log(`Parts: Query Operations => records={$results.length}`);
            }
            setOperationList(results);
        }).catch((error) => {
            const results = Array.from(new Set(parts.map(item => item.operationId)))
                .map(id => {
                    return {
                        id: id,
                        label: parts.find(s => s.operationId === id).operationName
                    };
                });
            console.error(`Parts: Query Operations => records=${results.length} with Error=${error}`);
            setOperationList(results);
        });
    }
    async function querySuppliers(parts) {
        masterService.getAllSuppliers().then((data) => {
            const results = data.map(item => {
                return {
                    id: item.id,
                    label: item.name
                };
            })
            if (utils.IsDebugMode) {
                console.log(`Parts: Query Suppliers => records={$results.length}`);
            }
            setSupplierList(results);
        }).catch((error) => {
            const results = Array.from(new Set(parts.map(item => item.supplierId)))
                .map(id => {
                    return {
                        id: id,
                        label: parts.find(s => s.supplierId === id).supplierName
                    };
                });
            console.error(`Parts: Query Suppliers => records=${results.length} with Error=${error}`);
            setSupplierList(results);
        });
    }

    return (
        <Container fixed>
            <Stack>
                <PartsTable data={partData} rowClicked={rowClickedEventCallback} addNewPartClicked={addNewPartClickedEventCallback} />
                {(selectedPart && selectedPart.data && openWithdrawer) && <PartConsumeForm part={selectedPart} show={openWithdrawer} notifyFormEvent={handleWithdrawerFormNotify} />}
                {(selectedPart && selectedPart.data && openController) && <PartModifierForm part={selectedPart}
                    operationList={operationList}
                    groupList={groupList}
                    supplierList={supplierList}
                    categoryList={categoryList}
                    openMode={openAddMode}
                    show={openController}
                    newData={requery}
                    notifyFormEvent={handleWithdrawerFormNotify} />}

            </Stack>
            {
                beingQuery && <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={beingQuery}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
        </Container>
    );
}

export default PartsPage2;