import { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Typography, Card, CardContent, Container, CardHeader } from '@mui/material';
import { seedService } from '../_services';

const theme = createTheme();

export default function AboutPage(props) {
    const gridContainer = {
        display: "grid",                
        gridTemplateAreas: `'header header'
                            'aside main'
                            'footer footer'
                            `,
        padding: "10px"
      };
      const gridItemLabel = {
        fontSize: "20px",
        fontWeight: "bold",
        padding: "10px",
        textAlign: "right"
      };    
      const gridItemValue = {
        fontSize: "18px",
        padding: "10px",
        textAlign: "left"
      };      

      const [ver, setVer] = useState("");    
      useEffect(() => {
          seedService.getBackendVersion()
              .then((data) => {
                  setVer(data);
              });
      }, []);      

    return (
        <ThemeProvider theme={theme}>
            <Card sx={{ minWidth: 275 }}>
                <CardContent>
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="h3" component="span">About</Typography>
                        <div/>
                        <hr />
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom component="span">            
                            <div style={gridContainer}>
                                <div style={gridItemLabel}>Admin: </div>
                                <div style={gridItemValue}>Phongpun n</div>
                                <div style={gridItemLabel}>Email: </div>
                                <div style={gridItemValue}>phongpun.n@gmail.com</div>
                                <div style={gridItemLabel}></div>
                                <div style={gridItemValue}></div>
                                <div style={gridItemLabel}>Implement Date: </div>
                                <div style={gridItemValue}>20 Mar 2023</div>     
                                <div style={gridItemLabel}></div>
                                <div style={gridItemValue}></div>
                                <div style={gridItemLabel}>Programmer: </div>
                                <div style={gridItemValue}>Rattagarn V</div>    
                                <div style={gridItemLabel}>Email: </div>
                                <div style={gridItemValue}>support@pstaweesup.com</div>    
                                <div style={gridItemLabel}></div>
                                <div style={gridItemValue}></div>     
                                <div style={gridItemLabel}>Version: </div>
                                <div style={gridItemValue}>{ver}</div>            
                            </div>
                        </Typography>
                    </Box>
                {/*<Copyright sx={{ mt: 8, mb: 4 }} />*/}                   
                </CardContent>
            </Card>                       
        </ThemeProvider>
    );
}