import React, { useState, useEffect, useReducer, useRef } from 'react';
import {
    Modal, Tab, Tabs, Typography, Autocomplete, TextField, Box,
    Stack, Card, Switch, Grid, Radio, RadioGroup, FormControlLabel, Button
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { format, intervalToDuration } from 'date-fns';
import TransactionTable from './TransactionTable';
import { masterService, partService } from '../../_services';
import { authenticationService } from '../../_services';
import { Role } from '../../_helpers';

import ConfirmDialog from '../ConfirmDialog';
import DialogYesNo from '../ModalYesNo';
import BarcodeForm from '../Parts/BarcodeForm';
import LargeImageForm from './LargeImageForm';

import { utils } from '../../_helpers';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function subTabProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const theme = createTheme({
    components: {
        // Name of the component
        MuiTabPanel: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    padding: '0px',
                },
            },
        },
    },
});

export default function PartModifierForm(props) {
    const activeUser = authenticationService.currentUserValue;

    const [selectedPart, setSelectedPart] = useState({ date: props.part.date, data: props.part.data });
    //const [modifyingPart, setModifyingPart] = useState(() => { return { date: 0, data: null } });
    const [modifyingPart, setModifyingPart] = useState({ date: props.part.date, data: props.part.data });
    const [transactionData, setTransactionData] = useState(() => { return { date: 0, records: [] } });

    const [mode, setMode] = useState('view');
    const [mainTab, setMainTab] = useState(0);
    const [subTab, setSubTab] = useState(0);
    const [modalShow, setModalShow] = useState(false);

    const [operationList, setOperationList] = useState(props.operationList);
    const [groupList, setGroupList] = useState(props.groupList);
    const [supplierList, setSupplierList] = useState(props.supplierList);

    const [operation, setOperation] = useState(null);
    const [group, setGroup] = useState(null);
    const [supplier, setSupplier] = useState(null);
    const [category, setCategory] = useState(null);
    const [partImageInfo, setPartImageInfo] = useState({ key: 0, name: "", file: null, data: null, type: "" });
    const [base64Image, setBase64Image] = useState(null);

    const [confirmOpen, setConfirmOpen] = useState(false); // Confirm Yes/No Delete
    const [waitServerReply, setWaitServerReply] = useState(false);
    const [persistImageBeforeMod, setPersistImageBeforeMod] = useState({
        "imageContentType" : "",
        "imageData" : null
    });

    const [openBarcode, setOpenBarcode] = useState(false);
    const notifyBarcodeFormClosed = e => {
        setOpenBarcode(prevValue => prevValue = false);
        if (utils.IsDebugMode) {
            console.log('notifyBarcodeFormClosed');
        }
    }

    const [openLargeImage, setOpenLargeImage] = useState(false);
    const notifyLargeImageFormClosed = e => {
        setOpenLargeImage(prevValue => prevValue = false);
        if (utils.IsDebugMode) {
            console.log('notifyLargeImageFormClosed');
        }
    }

    const [partInventoryStatus, setPartInventoryStatus] = useState(() => {
        if (selectedPart.data && (selectedPart.data.onhand > selectedPart.data.safetyLevel)) {
            return 1;
        } else {
            return 0;
        }
    });

    const [currentSafetyLevel, setCurrentSafetyLevel] = useState(() => {
        if (selectedPart.data) {
            return selectedPart.data.safetyLevel;
        } else {
            return 0;
        }
    });

    const [firstCall, setFirstCall] = useState(false);

    const defaultTransaction = {
        onhand: (selectedPart && selectedPart.data) ? selectedPart.data.onhand : 0,
        transaction: 'consume',
        quantity: 1,
        note: ""
    };

    const FORM_ACTIONS = {
        TRANSACTION_SELECT: "transactionSelected",
        QUANTITY_CHANGED: "quantityChanged",
        NOTE_CHANGED: "noteChanged",
        SUBMIT: "submitted"
    }

    const [transType, setTransType] = useState('Consume');

    const reducer = (state, action) => {
        if (utils.IsDebugMode) {
            console.log("STATE", state, "ACTION", action);
        }
        switch (action.type) {
            case FORM_ACTIONS.TRANSACTION_SELECT:
                //console.log("TRANSACTION SELECT", state);
                if (action.payload.transaction === "consume") {
                    if (state.quantity > selectedPart.onhand) {
                        setTransType('Consume');
                        return { ...state, transaction: "consume", quantity: selectedPart.data.onhand }
                    } else {
                        setTransType('Consume');
                        return { ...state, transaction: "consume" }
                    }
                } else {
                    setTransType('Supply');
                    return { ...state, transaction: "supply" }
                }
            case FORM_ACTIONS.QUANTITY_CHANGED:
                if (utils.IsDebugMode) {
                    console.log("Quantity Changed", state);
                    console.log(`--BEFORE: props.part=${props.part.data.onhand}, selectedPart.part=${selectedPart.data.onhand}, state.onhand=${state.onhand}`);
                }
                if (action.payload.transaction === "consume") {
                    if ((selectedPart.data.onhand - state.quantity) > currentSafetyLevel) {
                        setPartInventoryStatus(1);
                    } else {
                        setPartInventoryStatus(0);
                    }
                }
                if (utils.IsDebugMode) {
                    console.log(`--AFTER: props.part=${props.part.data.onhand}, selectedPart.part=${selectedPart.data.onhand}, state.onhand=${state.onhand}`);
                }
                return { ...state, quantity: action.payload.quantity }
            case FORM_ACTIONS.NOTE_CHANGED:
                return { ...state, note: action.payload.note }
            case FORM_ACTIONS.SUBMIT:
                return state;
            default:
                return state;
        }
    };
    const [state, dispatch] = useReducer(reducer, defaultTransaction);

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        getValues,
        formState: { errors },
        clearErrors,
    } = useForm({
        mode: "onBlur",
        shouldUnregister: false
    });

    const DEBUG = true;
    const DEBUG_USE_EFFECT = true;

    if (utils.IsDebugMode) {
        console.log("***PartModifierForm <ENTER>***");
        console.log("--selectedPart", selectedPart);
        console.log("----selectedPart.date", selectedPart.date);
        console.log("----selectedPart.data", selectedPart.data);
        console.log("--props.part", props.part);
        console.log('----props.part.date', props.part.date);
        console.log('----props.part.data', props.part.data); 
        console.log("--modifyingPart", modifyingPart);
        console.log("--mode", mode);
        console.log("--mainTab", mainTab);
        console.log("--subTab", subTab);
        console.log("--partImageInfo", partImageInfo);   
        console.log("--persistImageBeforeMod", persistImageBeforeMod);    
    }

    const disableAll = activeUser === null ? true : false;
    const disableSupply = disableAll || (activeUser.role === Role.Withdrawer);

    /* Assign values of Part to Form */
    const formFields = [
        { name: 'id', type: 'string' },
        { name: 'title', type: 'string' },
        { name: 'category', type: 'int' },
        { name: 'operationId', type: 'int' },
        { name: 'product', type: 'string' },
        { name: 'storeLocation', type: 'string' },
        { name: 'groupId', type: 'int' },
        { name: 'supplierId', type: 'int' },
        { name: 'owner', type: 'string' },
        { name: 'price', type: 'decimal' },
        { name: 'leadTime', type: 'int' },
        { name: 'safetyLevel', type: 'int' },
        { name: 'onhand', type: 'int' },
        { name: 'note', type: 'string' },
        { name: 'imageFile', type: 'file' },
        { name: 'imageType', type: 'string' },
        { name: 'remark', type: 'string'}
    ]
    const setFormFields = () => {
        if (utils.IsDebugMode) {
            console.log("setFormFields CALLED-> selectedPart", selectedPart);
        }
        if (selectedPart && selectedPart.data) {
            formFields.forEach(field => setValue(field.name, selectedPart.data[field.name]));
        } else if (props.part && props.part.data) {
            formFields.forEach(field => setValue(field.name, props.part.data[field.name]));
        }
    }

    if ((modalShow != props.show) && props.show) {
        setModalShow(prevValue => prevValue = true);
    }

    function isSelectedPartDataNull() {
        return (!selectedPart || !selectedPart.data);
    }

    if (isSelectedPartDataNull() && (props.part.data !== null)) {
        if (utils.IsDebugMode) {
            console.log("*****CASE 1*****", props.part);
        }
        setSelectedPart(prevValue => prevValue = { date: props.part.date, data: props.part.data });
        setCurrentSafetyLevel(props.part.data.safetyLevel);
        dispatch({ type: FORM_ACTIONS.QUANTITY_CHANGED, payload: { quantity: 1 } });
        dispatch({ type: FORM_ACTIONS.TRANSACTION_SELECT, payload: { transaction: "consume" } });
        setTransactionData({ ...transactionData, date: 0, records: [] })
        setFormFields();
        updatePartImageInfo(Date.now(), "", null, props.part.data.imageData, props.part.data.imageContentType);
        
    } else if (!isSelectedPartDataNull() && (selectedPart.date != props.part.date)) {
        if (utils.IsDebugMode) {
            console.log("*****CASE 2*****", props.part, selectedPart);
        }
        setSelectedPart(prevValue => prevValue = { date: props.part.date, data: props.part.data });
        setCurrentSafetyLevel(props.part.data.safetyLevel);
        dispatch({ type: FORM_ACTIONS.QUANTITY_CHANGED, payload: { quantity: 1 } });
        dispatch({ type: FORM_ACTIONS.TRANSACTION_SELECT, payload: { transaction: "consume" } });
        setTransactionData({ ...transactionData, date: 0, records: [] })
        setFormFields();
        //updatePartImageInfo(Date.now(), "", null, props.part.data.imageData, props.part.data.imageContentType);
    
    } else {
        if (utils.IsDebugMode) {
            console.log("***** NO CASE *****");
        }
        if (currentSafetyLevel != selectedPart.data.safetyLevel)
            setCurrentSafetyLevel(selectedPart.data.safetyLevel);
    }

    /* Handle Controls in Form */
    /* --- Tab selection */
    const handleMainTabChanged = (event, newValue) => {
        if (utils.IsDebugMode) {
            console.log("Handle Main Tab", event, newValue);
        }
        setMainTab(prevValue => prevValue = newValue);
    };
    const handleSubTabChanged = (event, newValue) => {
        setSubTab(prevValue => prevValue = newValue);
    }
    /* --- Field data changed */
    function updateEditingPart(fieldName, fieldValue) {
        //let tempPart = JSON.parse(JSON.stringify(selectedPart));
        let tempPart = JSON.parse(JSON.stringify(modifyingPart));
        if (utils.IsDebugMode) {
            console.log('(org) tempPart', tempPart);
            console.log('fieldName', fieldName, fieldValue);     
        }
        tempPart.data[fieldName] = fieldValue;        
        if (utils.IsDebugMode) {
            console.log('(upate) tempPart', tempPart);       
        }    
        //setValue(fieldName, fieldValue, { shouldValidate: true })
        setModifyingPart(prevValue => prevValue = tempPart);
    }
    function updateImageEditingPart(fieldName, fieldValue, fileObj) {
        let tempPart = JSON.parse(JSON.stringify(selectedPart));
        if (utils.IsDebugMode) {
            console.log('Update Image Editing Part', selectedPart, tempPart, fieldName, fieldValue);
        }
        tempPart.data[fieldName] = fieldValue;
        setValue(fieldName, fileObj, { shouldValidate: true })
        setModifyingPart(prevValue => prevValue = tempPart);
    }    
    const handleTextFieldIdChanged = e => {
        //let textValue = e.target.value.replace(/[^\w]/gi, "")
        let textValue = e.target.value.replace(/[,\t]/gi, "")
        setValue(e.target.name, textValue, { shouldValidate: true });
        updateEditingPart(e.target.name, e.target.value);
    }
    const handleTextFieldChanged = e => {
        //let textValue = e.target.value.replace(/[^\w\s]/gi, "")
        let textValue = e.target.value.replace(/[,\t]/gi, "")
        setValue(e.target.name, textValue, { shouldValidate: true });
        updateEditingPart(e.target.name, e.target.value);
    }
    const handleNumberFieldChanged = e => {
        if (e.target.name === "safetyLevel") {
            let num = parseInt(e.target.value);
            if (selectedPart.data.onhand > num) {
                setPartInventoryStatus(1);
            } else {
                setPartInventoryStatus(0);
            }
            setCurrentSafetyLevel(num);
        }
        setValue(e.target.name, e.target.value);
        updateEditingPart(e.target.name, e.target.value);
    }
    const handleSelectFieldChanged = e => {
        updateEditingPart(e.target.name, e.target.value);
        switch (e.target.name) {
            case "category":
                setCategory(prevValue => prevValue = e.target.value);
                break;
            case "operationId":
                setOperation(prevValue => prevValue = e.target.value);
                break;
            case "groupId":
                setGroup(prevValue => prevValue = e.target.value);
                break;
            case "supplierId":
                setSupplier(prevValue => prevValue = e.target.value);
                break;
        }
    }
    const handleFileSelectionChanged = e => {
        if (utils.IsDebugMode) {
            console.log('handleFileSelectionChanged', e.target.name, e.target.value);
            console.log(e.target.files);
            console.log(selectedPart.data);
            console.log(modifyingPart.data);
        }
        var tgt = e.target || window.event.srcElement,
            files = tgt.files;
        // FileReader support
        if (FileReader && files && files.length) {
            var fr = new FileReader();
            fr.onload = function () {
                //setValue(e.target.name, e.target.value);
                let imgData = fr.result.split(',');
                if (imgData.length == 2) {
                    updateEditingPart('imageContentType', imgData[0].replace(/data:/,'').replace(/;base64/, ''));
                    updateEditingPart('imageData', imgData[1]);
                    //const imageFile = URL.createObjectURL(files[0]);
                    if (utils.IsDebugMode) {
                        console.log('Image File', files[0]);
                    }
                    updatePartImageInfo(files[0].lastModified, files[0].name, files[0], imgData[1], files[0].type);
                }                
                //updateEditingPart(e.target.name, fr.result);
                //setModifyingPart(prevValue => prevValue = tempPart);
                
                /*
                document.getElementById("partImage").src = fr.result;
                setPartImageInfo((prevValue) => {
                    return { ...prevValue, data: fr.result }
                });
                let imgData = fr.result.split(',');
                if (imgData.length == 2) {          
                    let imgFile = files[0];    
                    console.log('FILE', imgFile);  
                    console.log('Last Modified:', imgFile.lastModified);
                    setValue('imageFile', imgFile);
                    //let ctrl = document.getElementById('partImageFile');
                    //console.log('**** CTRL', ctrl);
                    //ctrl.value = files[0].name;
                    //updateImageEditingPart(e.target.name, e.target.value, files[0]);
                    let newData = clone(selectedPart.data);
                    console.log('****CLONE', newData);
                    newData.imageContentType = imgData[0].replace(/data:/,'').replace(/;base64/, '');
                    newData.imageData = imgData[1];        
                    newData.imageFile = imgFile;      
                    //const imageFile = URL.createObjectURL(files[0]);
                    //createImage(imageFile, convertImage);    
                    //props.notifyFormEvent({ name: 'image-changed', value: Date.now(), remark: '', content: newData });
                    setSelectedPart(prevValue => prevValue = { 
                        ...selectedPart,
                        date: Date.now(), 
                        data: newData 
                    });
                    //console.log('***new selectedPart', selectedPart);
                    updatePartImageInfo(imgFile.lastModified, imgFile.name, imgFile, newData.imageData, imgFile.type);
                    
                }
                //console.log(base64String);
                //setBase64Image(base64String);
                */
                
            }
            fr.readAsDataURL(files[0]);
            //console.log('File.lastModified', files[0].lastModified);
            //updatePartImageInfo(files[0].lastModified, files[0].name, files[0], null, files[0].type);
        }
        //"../default-avatar.png"
        // Not supported        
        else {
            if (utils.IsDebugMode) {
                console.log("++++++++++++ NO FILE +++++++++++++++");
            }
            // fallback -- perhaps submit the input to an iframe and temporarily store
            // them on the server until the user's session ends.
            updatePartImageInfo(0, "", null, null, "");
        }
    }
    function updatePartImageInfo(modified, fileName, fileObj, imgSrcData, imgType) {
        if (utils.IsDebugMode) {
            console.log('-----------------------------------------------');
            console.log('updatePartImageInfo');
            console.log('===============================================');
        }
        if (fileObj) {
            setPartImageInfo(prevValue => prevValue = {
                key: modified, name: fileName,
                file: fileObj, data: `data:${imgType};base64,${imgSrcData}`, type: imgType
            });            
            if (utils.IsDebugMode) {
                console.log('Update Image:', imgType, modified);
            }
            /*
            let newData = clone(selectedPart.data);
            newData.imageContentType = imgType;
            newData.imageData = imgSrcData;
            setSelectedPart(prevValue => prevValue = { 
                date: modified, 
                data: newData 
            });
            console.log('CHANGED.....', selectedPart);
            */
        } else {
            setPartImageInfo(prevValue => prevValue = {
                key: modified, name: fileName,
                file: fileObj, data: null, type: imgType
            });
        }        
    }

    function clone(obj) {
        if (null == obj || "object" != typeof obj) return obj;
        var copy = obj.constructor();
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
        }
        return copy;
    }    
    function createImage(imageFile, callback) {
        const image = document.createElement('img');
        image.onload = () => callback(image);
        image.setAttribute('src', imageFile);    
    }
    function convertImage(image) {
        const canvas = drawImageToCanvas(image);
        const ctx = canvas.getContext('2d');
        
        let result = [];
        for (let y = 0; y < canvas.height; y++) {
          result.push([]);
          for (let x = 0; x < canvas.width; x++) {
            let data = ctx.getImageData(x, y, 1, 1).data;
            result[y].push(data[0]);
            result[y].push(data[1]);
            result[y].push(data[2]);
          }
        }
        
        const arrayCode = `
          #define IMAGE_WIDTH ${canvas.width}
          #define IMAGE_HEIGHT ${canvas.height}
          #define BYTES_PER_PIXEL 3
          uint8_t imageData[IMAGE_HEIGHT][IMAGE_WIDTH * BYTES_PER_PIXEL] = ${convertArray(result)};
        `;
        if (utils.IsDebugMode) {
            console.log(arrayCode);
        }
        //document.getElementById('result').innerHTML = arrayCode;
      }
      
      function drawImageToCanvas(image) {
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        canvas.getContext('2d').drawImage(image, 0, 0, image.width, image.height);
        return canvas;
      }
      
      function convertArray(array) {
        return JSON.stringify(array).replace(/\[/g, '{').replace(/\]/g, '}');
      }    
    /* --- Dialog related */
    const handleClose = () => {
        setModalShow(prevValue => prevValue = false);
        props.notifyFormEvent({ name: 'close', value: 0, remark: '' });
    }
    const handleCancelClicked = e => {
        if (mode === "add") {
            cancelAddMode();
        } else if (mode === 'edit') {
            cancelEditMode();
        }
    }
    function onSubmit(data) {
        if (utils.IsDebugMode) {
            console.log("**********************");
            console.log("* Submit <mode>", mode);
            console.log("* Submit data", data);
        }
        const part = convertFormDataToPartObject(data);
        if (utils.IsDebugMode) {
            console.log("converted 'data' => 'part'", part);
        }
        if (mode === 'add') {
            addPartToServer(part);
        } else if (mode === 'edit') {
            if (data.imageFile && data.imageFile.length > 0) {
                updatePartToServer(part, partImageInfo);
            } else {
                updatePartToServer(part, null);
            }            
        }
        if (utils.IsDebugMode) {
            console.log("-----------------------");
        }
    }
    const handleBarcodeForm = () => {
        setOpenBarcode(prevValue => prevValue = true);
    }

    useEffect(() => {
        if (utils.IsDebugMode) {
            console.log("***PartModifierForm <USE-EFFECT>***");
            console.log("<<useEffect call setFormfields>>");
            console.log("--selectedPart", selectedPart);
            console.log("----selectedPart.date", selectedPart.date);
            console.log("----selectedPart.data", selectedPart.data);
            console.log("--props.part", props.part);
            console.log('----props.part.date', props.part.date);
            console.log('----props.part.data', props.part.data); 
            console.log("--modifyingPart", modifyingPart);
            console.log("--mode", mode);
            console.log("--mainTab", mainTab);
            console.log("--subTab", subTab);
            console.log("--partImageInfo ", partImageInfo);              
        }
        //setSelectedPart(props.part);
        // get user and set form fields,
        setFormFields();
        // if (selectedPart) {
        //     queryTransactions(selectedPart.id);
        // }
    }, [props.part.data]);

    useEffect(() => {
        if (utils.IsDebugMode)
            console.log("useEffect call setOperation")
        if (selectedPart && selectedPart.data) {
            setOperation({ ...operation, id: selectedPart.data.operationId, label: selectedPart.data.operationName })
        }
    }, [(selectedPart ? selectedPart.data : null) ? selectedPart.data.operationId : null])
    useEffect(() => {
        if (utils.IsDebugMode)
            console.log("useEffect call setSupplier")
        if (selectedPart && selectedPart.data) {
            setSupplier({ ...supplier, id: selectedPart.data.supplierId, label: selectedPart.data.supplierName })
        }
    }, [(selectedPart ? selectedPart.data : null) ? selectedPart.data.supplierId : null])
    useEffect(() => {
        if (utils.IsDebugMode)
            console.log("useEffect call setGroup")
        if (selectedPart && selectedPart.data) {
            setGroup({ ...group, id: selectedPart.data.groupId, label: selectedPart.data.groupName })
        }
    }, [(selectedPart ? selectedPart.data : null) ? selectedPart.data.groupId : null])
    useEffect(() => {
        if (utils.IsDebugMode)
            console.log("useEffect call setCategory")
        if (selectedPart && selectedPart.data) {
            setCategory({ ...category, id: selectedPart.data.category, label: props.categoryList.find(c => c.id === selectedPart.data.category).label })
        }
    }, [(selectedPart ? selectedPart.data : null) ? selectedPart.data.category : null])

    /* ADD Part */
    if (props.openMode && (mode !== "add")) {
        setMode(prevValue => prevValue = 'add');
    }

    function addPartToServer(part) {
        setWaitServerReply(true);
        partService.createNewPart(part)
            .then((data) => {
                setWaitServerReply(false);
                alert('Successfully added');
                closeAddForm();
                props.notifyFormEvent({ name: 'requery', value: 0, remark: '' });
            })
            .catch((error) => {
                setWaitServerReply(false);
                alert('Fail to add with this error:' + error);
            });
    }
    function closeAddForm() {
        setPartImageInfo((prevValue) => {
            return { key: 0, name: "", file: null, data: null, type: "" }
        });
        setMode(prevValue => prevValue = "view");
        reset();
        clearErrors();
        handleClose(null);
    }
    function cancelAddMode() {
        setPartImageInfo((prevValue) => {
            return { key: 0, name: "", file: null, data: null, type: "" }
        });
        setMode(prevValue => prevValue = "view");
        reset();
        clearErrors();
        handleClose(null);
    }

    /* Query Information for Edit mode */
    async function queryInformation(data) {
        const qGroup = queryGroups(data);
        const qOper = queryOperations(data);
        const qSupp = querySuppliers(data);

        await qGroup;
        await qOper;
        await qSupp;
    }
    async function queryGroups() {
        masterService.getAllGroups().then((data) => {
            const results = data.map(item => {
                return {
                    id: item.id,
                    label: item.name
                };
            })
            if (utils.IsDebugMode) {
                console.log(`Parts: Query Groups => records={$results.length}`);
            }
            setGroupList(results);
        }).catch((error) => {
            console.error(`Parts: Query Groups => Error=${error}`);
        });
    }
    async function queryOperations() {
        masterService.getAllOperations().then((data) => {
            const results = data.map(item => {
                return {
                    id: item.id,
                    label: item.name
                };
            })
            if (utils.IsDebugMode) {
                console.log(`Parts: Query Operations => records={$results.length}`);
            }
            setOperationList(results);
        }).catch((error) => {
            console.error(`Parts: Query Operations => Error=${error}`);
        });
    }
    async function querySuppliers() {
        masterService.getAllSuppliers().then((data) => {
            const results = data.map(item => {
                return {
                    id: item.id,
                    label: item.name
                };
            })
            if (utils.IsDebugMode) {
                console.log(`Parts: Query Suppliers => records={$results.length}`);
            }
            setSupplierList(results);
        }).catch((error) => {
            console.error(`Parts: Query Suppliers => Error=${error}`);
        });
    }

    /* Edit Part */
    const handleEditClicked = e => {
        setMode(prevValue => prevValue = 'edit');
        setModifyingPart(prevValue => prevValue = selectedPart);
        setPersistImageBeforeMod(prevValue => prevValue = {
            ...persistImageBeforeMod, imageContentType: modifyingPart.data.imageContentType,
            imageData: modifyingPart.data.imageData
        });
        if (utils.IsDebugMode) {
            console.log('Edit set PersistImageBeforeMod', persistImageBeforeMod);
        }
    }
    function cancelEditMode() {
        if (selectedPart != null) {
            setFormFields();
            setMode(prevValue => prevValue = "view");
        }
    }
    function updatePartToServer(part, updateImage) {
        setWaitServerReply(true);
        if (utils.IsDebugMode) {
            console.log("updatePartToServer", part, updateImage);
        }
        if (updateImage) {        
            partService.updatePart(part)
                .then((resp) => {
                    alert('Successfully updated');
                    part.updated = new Date().toUTCString();
                    if (authenticationService.currentUserValue != null)
                        part.creatorName = authenticationService.currentUserValue.firstName;
                    setMode(prevValue => prevValue = "view");
                    clearErrors();
                    if (utils.IsDebugMode) {
                        console.log('Update Part Response (with Image)', resp);
                    }
                    let imgData = updateImage.data.split(',');
                    part.imageContentType = imgData[0].replace(/data:/,'').replace(/;base64/, '');
                    part.imageData = imgData[1]; 
                    setWaitServerReply(false);
                    props.notifyFormEvent({ name: 'updated', value: 0, remark: part.remark, data: part });
                    //props.notifyFormEvent({ name: 'requery', value: 0, remark: '' });
                })
                .catch((error) => {
                    setWaitServerReply(false);
                    alert('Fail to update with this error:' + error);
                    //closeAddForm();
                });
        } else {
            if (utils.IsDebugMode || true) {
                console.log('Update Part Response (no Image) : Sending to server', part);
                console.log('--> [1]modifyingPart.data', modifyingPart.data);
            }
                       
            partService.updatePartWithoutImage(part)
                .then((resp) => {
                    setWaitServerReply(false);
                    part.updated = new Date().toUTCString();
                    if (authenticationService.currentUserValue != null)
                        part.creatorName = authenticationService.currentUserValue.firstName;
                    alert('Successfully updated');
                    setMode(prevValue => prevValue = "view");
                    clearErrors();
                    if (utils.IsDebugMode) {
                        console.log('Update Part Response (no Image): Get response', resp);      
                        console.log('--> [2]persistImageBeforeMod', persistImageBeforeMod);  
                    }         
                    part.imageContentType = persistImageBeforeMod.imageContentType;
                    part.imageData =  persistImageBeforeMod.imageData;                   
                    props.notifyFormEvent({ name: 'updated', value: 0, remark: part.remark, data: part });
                    //props.notifyFormEvent({ name: 'requery', value: 0, remark: '' });
                })
                .catch((error) => {
                    setWaitServerReply(false);
                    alert('Fail to update with this error:' + error);
                    //closeAddForm();
                });            
        }
    }

    /* Delete Part */
    const handleDeleteClicked = e => {
        setConfirmOpen(true);
    }
    function deletePart(part) {
        partService.deletePart(part.data)
            .then((data) => {
                alert('Successfully deleted');
                closeAfterDeleted(part);
            })
            .catch((error) => {
                alert('Fail to delete with this error:' + error);
            });
    }
    function closeAfterDeleted(part) {
        setPartImageInfo((prevValue) => {
            return { key: 0, name: "", file: null, data: null, type: "" }
        });
        setMode(prevValue => prevValue = "view");
        clearErrors();
        reset();
        handleClose(null);
        props.notifyFormEvent({ name: 'requery', value: 0, remark: '', data: part.data });
    }

    /* Generic Functions */
    function convertFormDataToPartObject(data) {
        const part = {
            id: data.id,
            title: data.title,
            category: data.category,
            owner: data.owner || "",
            groupId: data.groupId,
            safetyLevel: data.safetyLevel,
            operationId: data.operationId,
            supplierId: data.supplierId,
            price: data.price,
            leadTime: data.leadTime,
            product: data.product || "",
            storeLocation: data.storeLocation || "",
            created: null,
            remark: data.remark || "",
            onhand: data.onhand,
            status: data.safetyLevel < data.onhand ? 1 : 0,
            active: true,
            imageContentType: partImageInfo.type,
            imageFile: partImageInfo.file
        }
        if (utils.IsDebugMode)
            console.log("convertFormDataToPartObject", part);
        return part;
    }


    /* handle Tracaction */

    const handleWithdrawNumberChanged = (e) => {

    }
    const handleWithdrawConfirmClicked = (value) => {
        props.notifyFormEvent({ name: 'withdraw', value: value, remark: '' });
    };
    useEffect(() => {
        if (utils.IsDebugMode) {
            console.log("useEffect on transaction", selectedPart);
        }
        if (!isSelectedPartDataNull() && (selectedPart.data.id !== "")) {
            queryTransactions(selectedPart.data.id);
        }
    }, [selectedPart.date]);
    function queryTransactions(partId) {
        const startExec = +new Date();
        partService.getTransactions(partId)
            .then((data) => {
                var endExec = +new Date();
                if (utils.IsDebugMode) {
                    console.log(`Server responds Transactions (records=${data.length}) within ${endExec - startExec}`);
                }
                setTransactionData({ ...transactionData, date: Date.now(), records: data });
                if (utils.IsDebugMode) {
                    console.log("Transaction return", data);
                }
            }).catch((error) => {
                var endExec = +new Date();
                console.error(`Server responds Transactions [Error: ${error}] within ${endExec - startExec}`);
            });
    }
    // useEffect(() => {
    //     console.log("UseEffect Trans", selectedPart);
    //     // if (selectedPart) {
    //     //     queryTransactions(selectedPart.id);
    //     // }
    // }, [selectedPart.partId]);
    const handleNoteChanged = e => {
        if (utils.IsDebugMode) {
            console.log(e.target.value);
        }
        //let textValue = e.target.value.replace(/[^\w\s]/gi, "")
        let textValue = e.target.value.replace(/[,\t]/gi, "")
        dispatch({ type: FORM_ACTIONS.NOTE_CHANGED, payload: { note: textValue } });
    }
    const handleQuantityChanged = e => {
        let newValue = parseInt(e.target.value);
        if (utils.IsDebugMode) {
            console.log(`Quantity changed => ${newValue}`);
        }
        dispatch({ type: FORM_ACTIONS.QUANTITY_CHANGED, payload: { quantity: newValue } });
    }
    const handleTransactionTypeSelected = e => {
        if (utils.IsDebugMode) {
            console.log("Transaction changed to ", e.target.value);
        }
        if (e.target.value == "Consume") {
            dispatch({ type: FORM_ACTIONS.TRANSACTION_SELECT, payload: { transaction: "consume" } });
        } else {
            dispatch({ type: FORM_ACTIONS.TRANSACTION_SELECT, payload: { transaction: "supply" } });
        }
    }
    const handleTransactionSubmitted = e => {
        let record = {
            partId: selectedPart.data.id,
            category: state.transaction == "consume" ? 1 : 0,
            quantity: state.quantity,
            note: state.note,
            status: 0
        }
        if (utils.IsDebugMode) {
            console.log("EXECUTE TRANSACTION: category", state.transaction, record.category)
            console.log("EXECUTE TRANSACTION: selectedPart", selectedPart);
            console.log("EXECUTE TRANSACTION: props.part", props.part);
        }

        partService.addTransaction(record)
            .then((data) => {
                alert('Successfully add transaction');
                if (utils.IsDebugMode) {
                    console.log("ADD TRANSACTION", data);
                    console.log("--ADD TRANSACTION: selectedPart", selectedPart);
                    console.log("--ADD TRANSACTION: props.part", props.part);
                }
                //dispatch({ type: FORM_ACTIONS.QUANTITY_CHANGED, payload: { quantity: data.onhand } });
                props.notifyFormEvent({ name: 'transaction', value: 0, remark: '', data: data });

                if (selectedPart.data && (selectedPart.data.onhand > selectedPart.data.safetyLevel)) {
                    setPartInventoryStatus(1);
                } else {
                    setPartInventoryStatus(2);
                }
            })
            .catch((error) => {
                alert('Fail to add transaction with this error:' + error);
                //closeAddForm();
            });
    }

    const handleLargeImageClicked = e => {
        setOpenLargeImage(true);
    }

    /* Set default Tab when Open */
    if (utils.IsDebugMode) {
        console.log("activeUser", activeUser);
        console.log("firstCall", firstCall);
    }
    if (activeUser) {
        if ((activeUser.role === Role.Withdrawer) && !firstCall) {
            setMainTab(1);
            setFirstCall(true);
        }
    } else if (!firstCall) {
        setFirstCall(true);
    }

    if (utils.IsDebugMode) {
        console.log("***PartModifierForm <EXIT>***");
        console.log("--selectedPart", selectedPart);
        console.log("----selectedPart.date", selectedPart.date);
        console.log("----selectedPart.data", selectedPart.data);
        console.log("--props.part", props.part);
        console.log('----props.part.date', props.part.date);
        console.log('----props.part.data', props.part.data); 
        console.log("--modifyingPart", modifyingPart);
        console.log("--mode", mode);
        console.log("--mainTab", mainTab);
        console.log("--subTab", subTab);
        console.log("--File", partImageInfo); 
        console.log("--persistImageBeforeMod", persistImageBeforeMod);
    }

    return (
        (selectedPart && selectedPart.data) &&
        <Modal
            open={modalShow}
        >
            <Box sx={{ ...style, width: 750, height: 740, borderRadius: 3 }}>
                <h5 id="child-modal-title"># {selectedPart.data.id}</h5>
                <div className='part-withdrawer-form-line' />
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={mainTab} onChange={handleMainTabChanged} aria-label="basic tabs example">
                            <Tab label="Parts" {...a11yProps(0)} />
                            <Tab label="Transactions" {...a11yProps(1)} disabled={(mode === 'add') || (mode === 'edit')} />
                        </Tabs>
                    </Box>
                    <ThemeProvider theme={theme}>
                        <TabPanel value={mainTab} index={0}>
                            <Box style={{ height: '500px' }}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={subTab} onChange={handleSubTabChanged} aria-label="basic tabs example">
                                            <Tab label="Information" {...subTabProps(0)} />
                                            <Tab label="Detail" {...subTabProps(1)} />
                                            <Tab label="More Detail" {...subTabProps(2)} />
                                            <Tab label="Others" {...subTabProps(3)} />
                                        </Tabs>
                                    </Box>
                                    <TabPanel value={subTab} index={0}>
                                        <Box style={{ height: '350px' }}>
                                            <div className="form-row">

                                                <label className="form-custom-label col-5">Part Number</label>
                                                <div className="form-group col-7">
                                                    <input name="partNumber" type="text" {...register('id', { required: true })} className={`form-control ${errors.id ? 'is-invalid' : ''}`}
                                                        onChange={handleTextFieldIdChanged}
                                                        readOnly={(mode !== 'add')}
                                                    />
                                                    {errors.id?.type === 'required' && <span className="form-field-error-message">Part Number is required</span>}
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <label className="form-custom-label col-5">Description</label>
                                                <div className="form-group col-7">
                                                    <input name="description" type="text" {...register('title', { required: true })} className={`form-control ${errors.title ? 'is-invalid' : ''}`}
                                                        onChange={handleTextFieldChanged}
                                                        readOnly={(mode !== 'edit') && (mode !== 'add')}
                                                    />
                                                    {errors.title?.type === 'required' && <span className="form-field-error-message">Description is required</span>}
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <label className="form-custom-label col-5">Category</label>
                                                <div className="form-group col-7">
                                                    <select {...register("category")}
                                                        className={`form-control ${errors.category ? 'is-invalid' : ''}`}
                                                        onChange={handleSelectFieldChanged}
                                                        disabled={(mode !== 'edit') && (mode !== 'add')}>
                                                        {
                                                            props.categoryList.map((item) => {
                                                                return <option key={item.id} value={item.id}>{item.label}</option>
                                                            })
                                                        }
                                                    </select>
                                                    <div className="invalid-feedback">{errors.category?.message}</div>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-5">
                                                    {/*<input name="id" type="hidden" {...register('id')} />*/}
                                                    <label className="form-custom-label">Part Image</label>
                                                    {((mode === 'edit') || (mode === 'add')) && <div className="form-row">
                                                        <label className="form-custom-label"></label>
                                                        <div className="col" style={{ margin: '5px' }}>
                                                            <div className="form-group">
                                                                <input name="partImageFile" type="file" accept="image/*"
                                                                    {...register('imageFile', { required: false })}
                                                                    className="form-control" id="partImageFile"
                                                                    onChange={handleFileSelectionChanged} />
                                                            </div>
                                                        </div>
                                                    </div>}

                                                </div>
                                                <div className="col-7" style={{ alignItems: 'center' }}>
                                                    <Box style={{ height: '200px', width: '300px', margin: '0px auto 10px auto' }}>
                                                        {(mode == 'edit' || mode == 'add') && 
                                                            modifyingPart.data && modifyingPart.data.imageData &&
                                                            <img src={`data:${modifyingPart.data.imageContentType};base64,${modifyingPart.data.imageData}`} alt="part-image" id="partImage" name="partImage"
                                                                style={{ border: '1px solid black', borderRadius: '10px', objectFit: 'contain', width: '300px', height: '170px' }} />
                                                        }                                                        
                                                        {(mode != 'edit' && mode != 'add') &&                                                        
                                                            selectedPart.data && selectedPart.data.imageData &&
                                                                <img src={`data:${selectedPart.data.imageContentType};base64,${selectedPart.data.imageData}`} alt="part-image" id="partImage" name="partImage"
                                                                    style={{ border: '1px solid black', borderRadius: '10px', objectFit: 'contain', width: '300px', height: '170px' }} />
                                                        }                                                      
                                                        {(mode != 'edit' && mode != 'add') &&
                                                            (!selectedPart.data || !selectedPart.data.imageData) &&
                                                                <img src="../image-not-available.jpg" alt="partImage" id="partImage" name="partImage"
                                                                    style={{ border: '1px solid black', borderRadius: '10px', objectFit: 'contain', width: '300px', height: '170px' }} />
                                                            
                                                        } 
                                                        <Button onClick={handleLargeImageClicked}>Large Image</Button>                                                       
                                                    </Box>
                                                </div>
                                            </div>
                                        </Box>
                                    </TabPanel>
                                    <TabPanel value={subTab} index={1}>
                                        <Box style={{ height: '350px' }}>
                                            <div className="form-row">
                                                <label className="form-custom-label col-5">Operation</label>
                                                <div className="form-group col-7">
                                                    <select {...register('operationId')}
                                                        className={`form-control ${errors.operationId ? 'is-invalid' : ''}`}
                                                        onChange={handleSelectFieldChanged}
                                                        disabled={(mode !== 'edit') && (mode !== 'add')}>
                                                        {
                                                            props.operationList.map((item) => {
                                                                return <option key={item.id} value={item.id}>{item.label}</option>
                                                            })
                                                        }
                                                    </select>
                                                    <div className="invalid-feedback">{errors.operationId?.message}</div>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <label className="form-custom-label col-5">Product</label>
                                                <div className="form-group col-7">
                                                    <input name="product" type="text" {...register('product')} className={`form-control ${errors.product ? 'is-invalid' : ''}`}
                                                        onChange={handleTextFieldChanged}
                                                        readOnly={(mode !== 'edit') && (mode !== 'add')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <label className="form-custom-label col-5">Store Location</label>
                                                <div className="form-group col-7">
                                                    <input name="storeLocation" type="text" {...register('storeLocation')} className={`form-control ${errors.storeLocation ? 'is-invalid' : ''}`}
                                                        onChange={handleTextFieldChanged}
                                                        readOnly={(mode !== 'edit') && (mode !== 'add')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <label className="form-custom-label col-5">Group</label>
                                                <div className="form-group col-7">
                                                    <select {...register("groupId")}
                                                        className={`form-control ${errors.groupId ? 'is-invalid' : ''}`}
                                                        onChange={handleSelectFieldChanged}
                                                        disabled={(mode !== 'edit') && (mode !== 'add')}>
                                                        {
                                                            props.groupList.map((item) => {
                                                                return <option key={item.id} value={item.id}>{item.label}</option>
                                                            })
                                                        }
                                                    </select>
                                                    <div className="invalid-feedback">{errors.groupId?.message}</div>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <label className="form-custom-label col-5">Supplier</label>
                                                <div className="form-group col-7">
                                                    <select {...register("supplierId")}
                                                        className={`form-control ${errors.supplierId ? 'is-invalid' : ''}`}
                                                        onChange={handleSelectFieldChanged}
                                                        disabled={(mode !== 'edit') && (mode !== 'add')}>
                                                        {
                                                            props.supplierList.map((item) => {
                                                                return <option key={item.id} value={item.id}>{item.label}</option>
                                                            })
                                                        }
                                                    </select>
                                                    <div className="invalid-feedback">{errors.supplierId?.message}</div>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <label className="form-custom-label col-5">Owner</label>
                                                <div className="form-group col-7">
                                                    <input name="owner" type="text" {...register('owner')} className={`form-control ${errors.owner ? 'is-invalid' : ''}`}
                                                        onChange={handleTextFieldChanged}
                                                        readOnly={(mode !== 'edit') && (mode !== 'add')}
                                                    />
                                                </div>
                                            </div>
                                        </Box>
                                    </TabPanel>
                                    <TabPanel value={subTab} index={2}>
                                        <Box style={{ height: '350px' }}>

                                            <div className="form-row">
                                                <label className="form-custom-label col-5">Price</label>
                                                <div className="form-group col-7">
                                                    <input name="price" type="number" {...register('price')} className={`form-control ${errors.price ? 'is-invalid' : ''}`}
                                                        onChange={handleNumberFieldChanged}
                                                        readOnly={(mode !== 'edit') && (mode !== 'add')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <label className="form-custom-label col-5">Lead Time</label>
                                                <div className="form-group col-5">
                                                    <input name="leadTime" type="number" {...register('leadTime')} className={`form-control ${errors.leadTime ? 'is-invalid' : ''}`}
                                                        min="0"
                                                        onChange={handleNumberFieldChanged}
                                                        readOnly={(mode !== 'edit') && (mode !== 'add')}
                                                    />
                                                </div>
                                                <span style={{ marginLeft: '10px' }}>Week(s)</span>
                                            </div>
                                            <div className="form-row">
                                                <label className="form-custom-label col-5">Safety Level</label>
                                                <div className="form-group col-7">
                                                    <input name="safetyLevel" type="number" {...register('safetyLevel')} className={`form-control ${errors.safetyLevel ? 'is-invalid' : ''}`}
                                                        min="0"
                                                        onChange={handleNumberFieldChanged}
                                                        readOnly={(mode !== 'edit') && (mode !== 'add')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <label className="form-custom-label col-5">Onhand</label>
                                                <div className="form-group col-7">
                                                    <label>{selectedPart.data.onhand}</label>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <label className="form-custom-label col-5">Status</label>
                                                <div className="form-group col-7">
                                                    <FormControlLabel control={<Switch checked={partInventoryStatus === 1} />} label={partInventoryStatus === 1 ? 'OK' : 'LOW'} />
                                                </div>
                                            </div>
                                        </Box>
                                    </TabPanel>
                                    <TabPanel value={subTab} index={3}>
                                        <Box style={{ height: '350px' }}>
                                            <div className="form-row">
                                                <label className="form-custom-label col-5">Remark</label>
                                                <div className="form-group col-5">
                                                    <Box
                                                        component="form"
                                                        sx={{
                                                            '& > :not(style)': { m: 1, width: '25ch' },
                                                        }}
                                                        noValidate
                                                        autoComplete="off"
                                                    >
                                                        <TextField id="outlined-basic" label="Remark" variant="outlined" type="text"
                                                            rows="2"
                                                            multiline={true}
                                                            style={{ width: 300, height: 100 }}
                                                            {...register('remark')}
                                                            onChange={handleTextFieldChanged}
                                                            inputProps={{ readOnly: (mode !== 'edit') && (mode !== 'add') }}
                                                        />
                                                    </Box>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <label className="form-custom-label col-5">Updated By</label>
                                                <div className="form-group col-7">
                                                    <label>{selectedPart.data.creatorName}</label>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <label className="form-custom-label col-5">Updated</label>
                                                <div className="form-group col-7">
                                                    <label>{format(new Date(selectedPart.data.updated || selectedPart.data.created), 'dd-MMM-yyyy HH:mm')}</label>
                                                </div>
                                            </div>
                                        </Box>
                                    </TabPanel>
                                    <div className='light-line' />
                                    <div className="form-group master-button-panels">
                                        <div className="row">
                                            <div className="col" style={{ display: 'flex' }}>
                                                {(mode === 'view' && activeUser && (activeUser.role !== Role.Withdrawer)) && <div className="btn-group" role="group">
                                                    <button type="button" className="btn btn-primary form-user-button"
                                                        onClick={handleEditClicked}>Edit</button>
                                                </div>}
                                                {((mode === 'edit') || (mode === 'add')) &&
                                                    <div className="btn-group" role="group">
                                                        <button type="submit" className="btn btn-success form-user-button" disabled={waitServerReply}>Save</button>
                                                        <button type="button" className="btn btn-secondary form-user-button"
                                                            onClick={handleCancelClicked}>Cancel</button>
                                                        {(mode === 'edit') &&
                                                            <button type="button" className="btn btn-danger form-user-button" disabled={waitServerReply}
                                                                onClick={handleDeleteClicked}>Delete</button>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                            {/*
                                            <div className="col">
                                                {((mode === 'edit') || (mode === 'add')) &&
                                                    <button type="button" onClick={() => reset()}
                                                        className="btn btn-light form-user-button">Clear</button>
                                                }
                                            </div>
                                            */}
                                        </div>
                                    </div>
                                </form>
                            </Box>
                        </TabPanel>
                        <TabPanel value={mainTab} index={1} >
                            <Box style={{ height: '500px' }}>
                                <Stack spacing={2}>
                                    <Card style={{ height: '300px' }}>
                                        <TransactionTable data={transactionData} />
                                    </Card>
                                    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="stretch">
                                        <Grid item xs={2}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        id="safetyLevelTrans"
                                                        label="Safety Level"
                                                        type="number"
                                                        inputProps={{
                                                            shrink: true,
                                                            readOnly: true,
                                                        }}
                                                        value={currentSafetyLevel}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        id="onHandTrans"
                                                        label="Onhand"
                                                        type="number"
                                                        inputProps={{
                                                            shrink: true,
                                                            readOnly: true,
                                                        }}
                                                        value={selectedPart.data.onhand}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid style={{ height: "100%" }}>
                                                <Box
                                                    component="form"
                                                    noValidate
                                                    autoComplete="off"
                                                >
                                                    <TextField
                                                        id="nodeTrans"
                                                        label="Note"
                                                        multiline
                                                        rows={4}
                                                        variant="filled"
                                                        fullWidth
                                                        inputProps={{
                                                            shrink: true,
                                                        }}
                                                        value={state.note}
                                                        onChange={handleNoteChanged}
                                                        disabled={disableAll}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Grid style={{ height: "100%" }}>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-form-control-label-placement"
                                                    name="transaction"

                                                    onChange={handleTransactionTypeSelected}
                                                    value={transType}
                                                >
                                                    <FormControlLabel
                                                        value="Consume"
                                                        control={<Radio disabled={disableAll} />}
                                                        label="Consume"
                                                        labelPlacement="end"
                                                    />
                                                    <FormControlLabel
                                                        value="Supply"
                                                        control={<Radio disabled={disableSupply} />}
                                                        label="Supply"
                                                        labelPlacement="end"
                                                    />
                                                </RadioGroup>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Grid style={{ height: "100%" }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            id="outlined-number"
                                                            label="Quantity"
                                                            type="number"
                                                            inputProps={{
                                                                shrink: true,
                                                                readOnly: false,
                                                                min: 1,
                                                                max: (state.transaction === 'consume' ?
                                                                    (selectedPart.data.onhand)
                                                                    : Number.MAX_SAFE_INTEGER)
                                                            }}
                                                            disabled={disableAll}
                                                            value={state.quantity}
                                                            onChange={handleQuantityChanged}
                                                        />
                                                        {
                                                            utils.IsDebugMode &&
                                                                console.log("Set Quantity in Field", state.quantity)
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button variant="contained"
                                                            disabled={disableAll || (
                                                                (state.quantity === 0) ||
                                                                ((state.transaction === 'consume') &&
                                                                    (selectedPart.data.onhand < state.quantity)))}
                                                            onClick={handleTransactionSubmitted}>Submit</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Box>
                        </TabPanel>
                    </ThemeProvider>
                </Box>
                <div className='part-withdrawer-form-line' />
                <Stack direction="row" justifyContent="space-between" style={{ marginTop: 10 }}>
                    <Button onClick={handleClose} align='center' disabled={(mode !== 'view')}>Close</Button>
                    <Button variant="outlined" disabled={(mode !== 'view')}
                        onClick={handleBarcodeForm}>Barcode</Button>
                </Stack>
                <DialogYesNo
                    title="Delete Part?"
                    open={confirmOpen}
                    setOpen={setConfirmOpen}
                    onConfirm={() => deletePart(selectedPart)}
                >
                    Are you sure you want to delete this Part?
                </DialogYesNo>
                {openBarcode && <BarcodeForm data={selectedPart.data.id} show={openBarcode} closed={notifyBarcodeFormClosed}/>}
                {openLargeImage && <LargeImageForm data={selectedPart.data} show={openLargeImage} closed={notifyLargeImageFormClosed}/>}
            </Box>           
        </Modal>
    );
}