import React from 'react';
import { useButton } from '@mui/base/ButtonUnstyled';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { green, red } from '@mui/material/colors';
import ThumbUpTwoToneIcon from '@mui/icons-material/ThumbUpTwoTone';
import ThumbDownTwoToneIcon from '@mui/icons-material/ThumbDownTwoTone';
import { authenticationService } from '../../_services';
import { utils } from '../../_helpers';

const blue = {
    500: '#007FFF',
    600: '#0072E5',
    700: '#0059B2',
};

const CustomButtonRoot = styled('button')`
  font-family: IBM Plex Sans, sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  background-color: ${blue[500]};
  padding: 12px 24px;
  border-radius: 8px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  border: none;

  &:hover {
    background-color: ${blue[600]};
  }

  &.active {
    background-color: ${blue[700]};
  }

  &.focusVisible {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const CustomButton = React.forwardRef(function CustomButton(props, ref) {
    const { children } = props;
    const { active, disabled, focusVisible, getRootProps } = useButton({
        ...props,
        ref,
        component: CustomButtonRoot,
    });

    const classes = {
        active,
        disabled,
        focusVisible,
    };

    return (
        <CustomButtonRoot {...getRootProps()}>
            {children}
        </CustomButtonRoot>
    );
});

const lowStatusStyle = {
    backgroundColor: red,
}
const okStatusStyle = {
    backgroundColor: green
}
CustomButton.propTypes = {
    children: PropTypes.node,
};

export default function getColumnsInfo(rowClickCallbackFunc) {
    let activeUser = authenticationService.currentUserValue;
    let roleName = "guest";
    if (activeUser) {
        roleName = "operator"
        if (activeUser.role != "Withdrawer") {
            roleName = "admin";
        }
    }

    let columns = [];
    if (utils.IsDebugMode)
        console.log("PartColumns.js -> Role", roleName);

    if (roleName == "admin") {
        columns = [
            {
                Header: 'Part Number',
                accessor: 'id',
                sticky: 'left',
                width: 200,
                Cell: row => (
                    <div style={{ textAlign: "left" }}>
                        <Button onClick={() => rowClickCallbackFunc(row.row)} size="small" variant="outlined">{row.value}</Button>
                    </div>
                )
            },
            {
                Header: 'Description',
                accessor: 'title',
                sticky: 'left',
                width: 300,
                Cell: row => (
                    <div style={{ textAlign: "left" }}>{row.value}</div>
                )
            },
            {
                Header: 'Group',
                accessor: 'groupName',
            },
            {
                Header: 'Operation',
                accessor: 'operationName',
            },
            {
                Header: 'Product',
                accessor: 'product',
            },
            {
                Header: 'Supplier',
                accessor: 'supplierName',
            },
            {
                accessor: 'leadTime',
                Header: 'Lead Time (Weeks)'
            },
            {
                accessor: 'safetyLevel',
                Header: 'Safety Level'
            },
            {
                accessor: 'onhand',
                Header: 'Onhand'
            },
            {
                accessor: 'status',
                Header: 'Status',
                Cell: row => {
                    if (row.value > 0) {
                        return (<div>OK<span>{'  '}</span><ThumbUpTwoToneIcon style={{ color: green[500] }} /></div>)
                    } else {
                        return (<div>LOW<span>{'  '}</span><ThumbDownTwoToneIcon style={{ color: red[500] }} /></div>)
                    }
                }
            },
            {
                accessor: 'category',
                Header: 'Type',
                Cell: row => (
                    <div>{row.value === 1 ? 'Consumable' : 'Spare Part'}</div>
                )
            },
            {
                accessor: 'formattedTransactionDate',
                Header: 'Last Transaction',
                width: 170       
            }
        ]    
    } else {
        columns = [
            {
                Header: 'Part Number',
                accessor: 'id',
                sticky: 'left',
                width: 200,
                Cell: row => (
                    <div style={{ textAlign: "left" }}>
                        <Button onClick={() => rowClickCallbackFunc(row.row)} size="small" variant="outlined">{row.value}</Button>
                    </div>
                )
            },
            {
                Header: 'Description',
                accessor: 'title',
                sticky: 'left',
                width: 300,
                Cell: row => (
                    <div style={{ textAlign: "left" }}>{row.value}</div>
                )
            },
            {
                Header: 'Group',
                accessor: 'groupName',
            },
            {
                Header: 'Operation',
                accessor: 'operationName',
            },
            {
                Header: 'Product',
                accessor: 'product',
            },
            {
                Header: 'Supplier',
                accessor: 'supplierName',
            },
            {
                accessor: 'leadTime',
                Header: 'Lead Time (Weeks)'
            },
            {
                accessor: 'safetyLevel',
                Header: 'Safety Level'
            },
            {
                accessor: 'onhand',
                Header: 'Onhand'
            },
            {
                accessor: 'status',
                Header: 'Status',
                Cell: row => {
                    if (row.value > 0) {
                        return (<div>OK<span>{'  '}</span><ThumbUpTwoToneIcon style={{ color: green[500] }} /></div>)
                    } else {
                        return (<div>LOW<span>{'  '}</span><ThumbDownTwoToneIcon style={{ color: red[500] }} /></div>)
                    }
                }
            },
            {
                accessor: 'category',
                Header: 'Type',
                Cell: row => (
                    <div>{row.value === 1 ? 'Consumable' : 'Spare Part'}</div>
                )
            }
        ]         
    }      
    
    if (utils.IsDebugMode)
        console.log("PartColumns.js ->", columns);
    
        return columns;
}