import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import ExpireIndicator from '../components/ExpireIndicator';
import { authenticationService, userService } from '../_services';
import { Role } from '../_helpers';

const RegisterPage = (props) => {
    const [user, setUser] = useState({
        username: '',
        firstName: '',
        lastName: '',
        password: '',
        confirmPassword: '',
        role: 'Admin'
    });

    const [progress, setProgress] = useState(0);

    // get functions to build form with useForm() hook
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        mode: "onBlur",
        shouldUseNativeValidation: true
    });

    const handleTextFieldChanged = (e) => {
        let newUser = { ...user };
        newUser[e.target.name] = e.target.value;
        setUser(prevValue => prevValue = newUser);
    }
    const onSubmit = async data => {
        userService.register(data);
    };

    function expireCallback(value) {
        setProgress(prevValue => prevValue = value);
        //console.log("Progress", progress);
    }
    useEffect(() => {
        if (authenticationService.currentUserValue != null) {
            authenticationService.expireTimerCallback(expireCallback);
        }
    });

    return (
        <div className="card m-3">
            <h5 className="card-header">First Administrator Register</h5>
            <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-row">
                        <label className="form-custom-label col-5">Username</label>
                        <div className="form-group col-7">
                            <input name="username" type="text"
                                {...register('username', { required: true })}
                                className={`form-control form-field ${errors.username ? 'is-invalid' : ''}`}
                                onChange={handleTextFieldChanged}
                            />
                            {errors.username?.type === 'required' && <span className="form-field-error-message">Username is required</span>}
                        </div>
                    </div>
                    <div className="form-row">
                        <label className="form-custom-label col-5">First Name</label>
                        <div className="form-group col-7">
                            <input name="firstName" type="text" {...register('firstName', { required: true })} className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
                                onChange={handleTextFieldChanged}
                            />
                            {errors.firstName?.type === 'required' && <span className="form-field-error-message">First Name is required</span>}
                        </div>
                    </div>
                    <div className="form-row">
                        <label className="form-custom-label col-5">Last Name</label>
                        <div className="form-group col-7">
                            <input name="lastName" type="text" {...register('lastName')} className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
                                onChange={handleTextFieldChanged}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <label className="form-custom-label col-5">Email</label>
                        <div className="form-group col-7">
                            <input
                                type="email"
                                {...register("email", {
                                    required: "Email is required",
                                    validate: (value) =>
                                        value.includes("@") || "Email must include '@' symbol"
                                })}
                                onChange={handleTextFieldChanged}
                                style={{ width: '100%' }}
                            />
                            {errors.email && (<span className="form-field-error-message">{errors.email.message}</span>)}
                        </div>
                    </div>
                    <div className="form-row">
                        <label className="form-custom-label col-5">Password</label>
                        <div className="form-group col-7">
                            <input name="password" type="password"
                                {...register('password', { required: true })}
                                className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
                            {errors.password && (<span className="form-field-error-message">{errors.password.message}</span>)}
                        </div>
                    </div>
                    <div className="form-row">
                        <label className="form-custom-label col-5">Confirm Password</label>
                        <div className="form-group col-7">
                            <input name="confirmPassword" type="password"
                                {...register('confirmPassword',
                                    {
                                        required: "Please confirm password!",
                                        validate: {
                                            matchesPreviousPassword: (value) => {
                                                const { password } = getValues();
                                                return password === value || "Passwords should match!";
                                            }
                                        }
                                    })}
                                className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                            />
                            {errors.confirmPassword && (<span className="form-field-error-message">{errors.confirmPassword.message}</span>)}
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <button type="submit" className="btn btn-primary form-user-button">Add</button>
                            <div className="col">
                                <button type="button" onClick={() => reset()}
                                    className="btn btn-light form-user-button">Clear</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div >

            <ExpireIndicator value={progress} />
        </div >
    );
}

export default RegisterPage;