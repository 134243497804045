import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Card, Container, Grid, IconButton, InputBase, Paper, Stack, TextField, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import {
    DataGrid,
    GridToolbarDensitySelector,
    GridToolbarFilterButton
} from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import Form from '../components/Form';
import { useForm } from "react-hook-form";
import { AutoDeleteOutlined, FamilyRestroomOutlined, SettingsInputSvideoRounded } from '@mui/icons-material';

import { authenticationService, userService as User, userService } from '../_services';
import { TestAccountTable } from '../components/TestAccountTable';
import { Role } from '../_helpers';
import DialogYesNo from '../components/ModalYesNo';
import { utils } from '../_helpers';

var renderCount = 0;
function AccountForm(props) {
    const selectedUser = props.user;
    //console.log("selectedUser", selectedUser);
    const [user, setUser] = useState(() => {
        let newUser;
        if ((selectedUser == null) || (selectedUser.id == null)) {
            newUser = {
                id: 0,
                username: '',
                firstName: '',
                lastName: '',
                email: '',
                role: Role.Withdrawer
            }
        } else {
            newUser = JSON.parse(JSON.stringify(selectedUser));
        }
        console.log("init User", newUser);
        return newUser;
    });
    const [mode, setMode] = useState('view');
    const [isEditing, setIsEditing] = useState(false);
    const [beforeEditUser, setBeforeEditUser] = useState(null);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState("");

    const form = useRef(null)

    // get functions to build form with useForm() hook
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        mode: "onBlur"
    });

    function onSubmit(data) {
        if (mode == 'add') {
            console.log('add Data', data);
            const duplicatedUser = props.userList.filter(u => u.username.toLowerCase() == user.username.toLowerCase());
            if (duplicatedUser.length > 0) {
                alert('Duplicated username' + duplicatedUser.username);
            } else {
                let { profileFile, ...newUser } = data;
                newUser.imageData = profileFile[0];
                console.log("Adding User", newUser);
                console.log("Form.Current", form.current);
                userService.addUser(form.current)
                    .then((data) => {
                        props.formUpdated({ "Added": data });
                        setIsEditing(false);
                        setMode('view');
                        alert('Success');
                        doReset();
                    });
            }
        } else if (mode == 'edit') {
            let { profileFile, ...newUser } = data;
            newUser.imageData = profileFile[0];
            console.log("Updating User", newUser);
            userService.updateUser(form.current)
                .then((data) => {
                    props.formUpdated({ "Updated": data });
                    setIsEditing(false);
                    setMode('view');
                    alert('Success');
                    doReset();
                });
        }
    }


    const handleTextFieldChanged = e => {
        let newUser = { ...user };
        const { [e.target.name]: keyValue } = user;
        newUser[e.target.name] = e.target.value;
        setUser(newUser);
    }

    const handlePasswordFieldChange = e => {

    }

    const handleEditClicked = e => {
        props.editClicked();
        setIsEditing(true);
        setMode('edit');
        setBeforeEditUser(prevValue => prevValue = JSON.parse(JSON.stringify(user)));
    }
    const handleAddClicked = e => {

        props.editClicked();
        setIsEditing(true);
        setMode('add');
        let newUser = {
            id: 0,
            username: '',
            firstName: '',
            lastName: '',
            email: '',
            role: Role.Withdrawer
        }
        setUser(prevValue => prevValue = newUser);
        console.log("Add User", user);
        doReset();
    }
    const handleSaveClicked = e => {
        /*
        console.log(props.userList);
        const duplicatedUser = props.userList.filter(u => u.username.toLowerCase() == user.username.toLowerCase());
        if (duplicatedUser) {
            alert('Duplicated');
        } else {
            alert('OK');
            //props.viewClicked();
            //setIsEditing(false);
            //setMode('view');
        }
        */
    }
    const handleCancelClicked = e => {
        props.viewClicked();
        setIsEditing(false);
        if (mode === 'edit') {
            setUser(prevValue => prevValue = JSON.parse(JSON.stringify(beforeEditUser)));
        }
        setMode('view');
        if (!user.imageData || user.imageData === '') {
            document.getElementById("profile").src = '../default-avatar.png';
        } else {
            document.getElementById("profile").src = 'data:image/png;base64,' + user.imageData;
        }
    }
    const handleDeleteClicked = e => {
        setDialogContent(`Do you want to remove this account (${user.username})?`);
        setDialogOpen(true);
        console.log("Delete => " + selectedUser.username);
    }
    function confirmDeleteAccountCallback() {
        userService.deleteUser(form.current)
            .then((data) => {
                props.formUpdated({ "reset": data });
                setIsEditing(false);
                setMode('view');
                alert('Deleted!!');
                doReset();
            });
    }
    const handleResetPasswordClicked = e => {
        console.log("Reset Password for " + selectedUser.username);
        if (mode == "edit") {
            userService.resetPassword(form.current)
                .then((data) => {
                    props.formUpdated({ "reset": data });
                    setIsEditing(false);
                    setMode('view');
                    alert('Password Reset!!');
                    doReset();
                });
        }
    }

    if (selectedUser && (selectedUser.id != user.id) && (mode != 'add')) {
        console.log("Unmatched user", selectedUser.id, user.id);
        setUser(prevValue => prevValue = { ...selectedUser });
    }

    //console.log("User", user.id);
    useEffect(() => {
        //console.log('useEffect ===>', user);
        if (user && (user.id >= 0)) {
            // get user and set form fields
            const fields = ['id', 'username', 'firstName', 'lastName', 'email', 'role'];
            fields.forEach(field => setValue(field, user[field]));
        }
    }, [user]);

    const handleFileSelectionChanged = (e) => {
        var tgt = e.target || window.event.srcElement,
            files = tgt.files;

        // FileReader support
        if (FileReader && files && files.length) {
            var fr = new FileReader();
            fr.onload = function () {
                document.getElementById("profile").src = fr.result;
            }
            fr.readAsDataURL(files[0]);
        }

        //"../default-avatar.png"
        // Not supported        
        else {
            // fallback -- perhaps submit the input to an iframe and temporarily store
            // them on the server until the user's session ends.
        }
    }

    function doReset() {
        document.getElementById("profile").src = '../default-avatar.png';
        reset();
    }

    return (
        <>
            <div className="card m-3">
                <h5 className="card-header">User Information</h5>
                <div className="card-body">
                    <form ref={form} onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-row">
                            <input name="id" id="id" type="hidden" {...register('id')} />
                            <div className="col" style={{ alignItems: 'center' }}>
                                <Box style={{ height: '100px', width: '100px', margin: '0px auto 10px auto' }}>
                                    {user.imageData &&
                                        <img src={`data:image/png;base64,${user.imageData}`} alt="profile" id="profile" name="profile"
                                            style={{ border: '1px solid', borderRadius: '50%', objectFit: 'contain', width: '100px', height: '100px' }} />
                                    }
                                    {!user.imageData &&
                                        <img src="../default-avatar.png" alt="profile" id="profile" name="profile"
                                            style={{ border: '1px solid', borderRadius: '50%', objectFit: 'contain', width: '100px', height: '100px' }} />
                                    }
                                </Box>
                            </div>
                        </div>
                        {(mode == 'add' || mode == 'edit') && <div className="form-row">
                            <div className="col" style={{ margin: '5px' }}>
                                <div className="form-group">
                                    <input name="profileFile" type="file"
                                        {...register('profileFile', { required: false })}
                                        className="form-control" id="profileFile" onChange={handleFileSelectionChanged} />
                                </div>
                            </div>
                        </div>}
                        <div className="form-row">
                            <label className="form-custom-label col-5">Username</label>
                            <div className="form-group col-7">
                                <input name="username" type="text"
                                    {...register('username', { required: true })}
                                    className={`form-control form-field ${errors.username ? 'is-invalid' : ''}`}
                                    onChange={handleTextFieldChanged}
                                    readOnly={!isEditing || (mode != 'add')}
                                />
                                {errors.username?.type === 'required' && <span className="form-field-error-message">Username is required</span>}
                            </div>
                        </div>
                        <div className="form-row">
                            <label className="form-custom-label col-5">First Name</label>
                            <div className="form-group col-7">
                                <input name="firstName" type="text" {...register('firstName', { required: true })} className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
                                    onChange={handleTextFieldChanged}
                                    readOnly={!isEditing}
                                />
                                {errors.firstName?.type === 'required' && <span className="form-field-error-message">First Name is required</span>}
                            </div>
                        </div>
                        <div className="form-row">
                            <label className="form-custom-label col-5">Last Name</label>
                            <div className="form-group col-7">
                                <input name="lastName" type="text" {...register('lastName')} className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
                                    onChange={handleTextFieldChanged}
                                    readOnly={!isEditing}
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <label className="form-custom-label col-5">Email</label>
                            <div className="form-group col-7">
                                <input
                                    type="email"
                                    {...register("email", {
                                        required: "Email is required",
                                        validate: (value) =>
                                            value.includes("@") || "Email must include '@' symbol"
                                    })}
                                    onChange={handleTextFieldChanged}
                                    readOnly={!isEditing}
                                    style={{ width: '100%' }}
                                />
                                {errors.email && (<span className="form-field-error-message">{errors.email.message}</span>)}
                            </div>
                        </div>
                        {
                            (mode == 'add') &&
                            <>
                                <div className="form-row">
                                    <label className="form-custom-label col-5">Password</label>
                                    <div className="form-group col-7">
                                        <input name="password" type="password"
                                            {
                                            ...register('password',
                                                {
                                                    required: isEditing ? "Password is required!" : ""
                                                }
                                            )}

                                            onChange={handlePasswordFieldChange}
                                            readOnly={!isEditing}
                                            className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
                                        {isEditing && errors.password && (<span className="form-field-error-message">{errors.password.message}</span>)}
                                    </div>
                                </div>
                                <div className="form-row">
                                    <label className="form-custom-label col-5">Confirm Password</label>
                                    <div className="form-group col-7">
                                        <input name="confirmPassword" type="password"
                                            {...register('confirmPassword',
                                                {
                                                    required: isEditing ? "Please confirm password!" : "",
                                                    validate: {
                                                        matchesPreviousPassword: (value) => {
                                                            const { password } = getValues();
                                                            return !isEditing || password === value || "Passwords should match!";
                                                        }
                                                    }
                                                })}
                                            className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                                            readOnly={!isEditing}
                                        />
                                        {isEditing && errors.confirmPassword && (<span className="form-field-error-message">{errors.confirmPassword.message}</span>)}
                                    </div>
                                </div>
                            </>
                        }

                        {
                            (mode == 'edit') &&
                            <div className="form-row">
                                <div className="col-5" />
                                <div className="col-7" style={{ display: 'flex', position: 'relative', top: '-15px' }}>
                                    <button type="button" className="btn btn-warning mr-1"
                                        onClick={handleResetPasswordClicked}>Reset Password</button>
                                </div>
                            </div>
                        }
                        <div className="form-row">
                            <label className="form-custom-label col-5">Role</label>
                            <div className="form-group col-7">
                                <select {...register("role")}
                                    className={`form-control ${errors.role ? 'is-invalid' : ''}`}
                                    disabled={!isEditing}>
                                    <option value="Admin">Administrator</option>
                                    <option value="Controller">Controller</option>
                                    <option value="Withdrawer">Withdrawer</option>
                                </select>
                                <div className="invalid-feedback">{errors.role?.message}</div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col" style={{ display: 'flex' }}>
                                    {(mode == 'view') && <div className="btn-group" role="group">
                                        <button type="button" className="btn btn-primary form-user-button"
                                            onClick={handleAddClicked}>Add</button>
                                        <button type="button" className="btn btn-primary form-user-button"
                                            onClick={handleEditClicked}
                                            disabled={(user.id === undefined)}>Edit</button>
                                    </div>}
                                    {(mode == 'add' || mode == 'edit') &&
                                        <div className="btn-group" role="group">
                                            <button type="submit" className="btn btn-success form-user-button"
                                                onClick={handleSaveClicked}>Save</button>
                                            <button type="button" className="btn btn-secondary form-user-button"
                                                onClick={handleCancelClicked}>Cancel</button>
                                            {(mode == 'edit') &&
                                                <button type="button" className="btn btn-danger form-user-button"
                                                    onClick={handleDeleteClicked}>Delete</button>
                                            }
                                        </div>
                                    }
                                </div>
                                <div className="col">
                                    {(mode == 'add' || mode == 'edit') &&
                                        <button type="button" onClick={() => doReset()}
                                            className="btn btn-light form-user-button">Clear</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </form>
                </div >
            </div >
            <DialogYesNo
                title="Delete Account"
                open={dialogOpen}
                setOpen={setDialogOpen}
                onConfirm={confirmDeleteAccountCallback}
            >
                {dialogContent}
            </DialogYesNo>
        </>
    );
};

function TestTable(props) {
    let today = new Date();
    console.log(today.toString() + ': TestTable');
    const [tableData, setTableData] = useState([]);
    const columns = [
        {
            field: 'username',
            headerName: 'Username',
            width: 100
        },
        {
            field: 'firstName',
            headerName: 'First Name',
            width: 150
        },
        {
            field: 'lastName',
            headerName: 'Last Name',
            width: 200,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 250
        },
        {
            field: 'role',
            headerName: 'Role',
        }
    ]

    useEffect(() => {
        //const dataRows = userService.getAll2();
        //setTableData(dataRows);
        console.log('useEffect called');
        userService.getAll2().then((users) => {
            console.log('fetch done' + JSON.stringify(users))
            setTableData(users)
        });
    }, []);

    return (
        <div style={{ display: 'flex', height: '80vh', margin: '10px 4px' }}>
            <DataGrid
                columns={columns}
                rows={tableData}
                onRowClick={(params, e, details) => {
                    props.rowClicked(params.row);
                }}
                pageSize={10}
                disableSelectionOnClick={props.editing}
            />
        </div>
    )
};

function AccountTable(props) {
    let dataRows = null;
    const tableData = props.datasource;
    const [isEditing, setIsEditing] = useState(false);
    //const [tableData, setTableData] = useState([]);
    const [filterData, setFilterData] = useState(...props.datasource);

    if (utils.IsDebugMode) {
        console.log("tableData", tableData);
        console.log("filterData", filterData);
    }
    const columns = [
        {
            field: 'username',
            headerName: 'Username',
            width: 100
        },
        {
            field: 'firstName',
            headerName: 'First Name',
            width: 150
        },
        {
            field: 'lastName',
            headerName: 'Last Name',
            width: 200,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 250
        },
        {
            field: 'role',
            headerName: 'Role',
        }
    ]
    const [searchText, setSearchText] = useState('');
    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(searchValue, 'i');
        const filteredRows = tableData.filter((row) => {
            return Object.keys(row).some((field) => {
                return row[field] && searchRegex.test(row[field].toString());
            });
        });
        setFilterData(filteredRows);
    }

    if (isEditing != props.editing) {
        setIsEditing(props.editing);
    }

    useEffect(() => {
        setFilterData(props.datasource);
    }, [props.datasource])
    /*
    useEffect(() => {
        //const dataRows = userService.getAll2();
        //setTableData(dataRows);
        console.log('AccountTable:useEffect called');
        userService.getAll().then((users) => {
            console.log('fetch done' + JSON.stringify(users))
            setTableData(users)
            setFilterData(users)
            props.notify('get-data', users);
        }).catch((error) => {
            console.log(error);
            props.notify('get-error', error);
        });
    }, []);
    */

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h3 className="alight-text-left">User Accounts</h3>
                </div>
                <div className="col">
                    <Paper
                        component="form"
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                    >
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            inputProps={{ 'aria-label': 'search user account' }}
                            onChange={(e) => requestSearch(e.target.value)}
                            disabled={isEditing}
                        />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search" disabled={isEditing}>
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div style={{ display: 'flex', height: '80vh', margin: '10px 4px' }}>
                        <DataGrid
                            columns={columns}
                            rows={filterData ?? []}
                            onRowClick={(params, e, details) => {
                                props.rowClicked(params.row);
                            }}
                            pageSize={10}
                            disableSelectionOnClick={props.editing}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const AccountsPage = (props) => {
    const pageStatusCallback = props.pageStatusChanged;

    const [tableData, setTableData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [userDataRows, setUserDataRows] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);

    const selectedRowCallback = (row) => {
        if (!isEditing)
            setSelectedRow(row);
    }
    const editButtonClickCallback = () => {
        setIsEditing(true);
        pageStatusCallback("Account Page", "editing");
    }
    const viewButtonClickCallback = () => {
        setIsEditing(false);
        pageStatusCallback("Account Page", "");
    }

    const notifyCallback = (eventString, data) => {
        console.log('Event = ' + eventString);
        console.log(data);
        if (eventString == 'get-data') {
            setUserDataRows(data);
        } else if (eventString == 'get-error') {
            setUserDataRows([]);
            setIsUpdated(false);
        } else if (eventString == 'requery') {
            setIsUpdated(false);
        }
    }

    const accountFormCallback = (event) => {
        setIsEditing(false);
        setIsUpdated(true);
        pageStatusCallback("Account Page", "");
        queryData();
    }

    const queryData = () => {
        userService.getAll().then((users) => {
            console.log('fetch', users);
            setTableData(users)
        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        console.log('Account Page:useEffect called');
        queryData();
    }, []);

    return (
        <Grid container space={2}>
            <Grid item xs={6} md={4}>
                <AccountForm userList={userDataRows} user={selectedRow} editClicked={editButtonClickCallback} viewClicked={viewButtonClickCallback}
                    formUpdated={accountFormCallback} editing={isEditing}
                />
            </Grid>
            <Grid item xs={6} md={8}>
                <Box style={{ height: 450, margin: '10px' }}>
                    <AccountTable rowClicked={selectedRowCallback} editing={isEditing} notify={notifyCallback} datasource={tableData} />
                </Box>
            </Grid>
        </Grid>
    );
}

export default AccountsPage;