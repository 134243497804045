import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { format as DateFormat } from 'date-fns';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { Box, Typography } from '@mui/material';
import { green, red } from '@mui/material/colors';
import ThumbUpTwoToneIcon from '@mui/icons-material/ThumbUpTwoTone';
import ThumbDownTwoToneIcon from '@mui/icons-material/ThumbDownTwoTone';
import { utils } from '../../_helpers';

const columns = [
    { id: 'id', label: 'Part Number', minWidth: 170 },
    { id: 'title', label: 'Description', minWidth: 100 },
    {
        id: 'product',
        label: 'Product',
        minWidth: 170
    },
    {
        id: 'supplierName',
        label: 'Supplier',
        minWidth: 170
    },
    {
        id: 'leadTime',
        label: 'Lead Time (Weeks)',
        minWidth: 80,
        align: 'right',
        format: (value) => value.toFixed(0),
    },
    {
        id: 'onhand',
        label: 'Onhand',
        minWidth: 80,
        align: 'right',
        format: (value) => value.toFixed(0),
    },
    {
        id: 'safetyLevel',
        label: 'Safety Level',
        minWidth: 80,
        align: 'right',
        format: (value) => value.toFixed(0),
    },
    {
        id: 'status',
        label: 'Status',
        minWidth: 80,
        align: 'right',
        format: (value) => {
            if (value > 0) {
                return (<div>OK<span>{'  '}</span><ThumbUpTwoToneIcon style={{ color: green[500] }} /></div>)
            } else {
                return (<div>LOW<span>{'  '}</span><ThumbDownTwoToneIcon style={{ color: red[500] }} /></div>)
            }            
        }
    },
    {
        id: 'lastTransactionDate',
        label: 'Last Updated',
        minWidth: 100,
        align: 'left',
        isDate: true,
        format: (value) => {
            return DateFormat(new Date(value), 'dd-MMM-yyyy HH:mm')
        }
    }
];

const marginTableStyle = {
    marginLeft: '70px',
    marginTop: 1,
    marginRight: 1,
    marginBottom: 1
}

const transactionType = (category) => {
    if (category > 0) {
        return <><span>Consume</span>{'  '}<RemoveCircleOutlineIcon style={{ color: red[500] }} /></>
    } else {
        return <><span>Supply</span>{'  '}<AddCircleOutlineIcon style={{ color: green[500] }} /></>
    }
}

function Row(props) {
    const row = props.row;
    const columns = props.columns;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} hover>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                {columns.map((column) => {
                    const value = row[column.id];
                    return (
                        <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                                ? column.format(value)
                                : (column.isDate && column.format ?
                                    column.format(value)
                                    : value)}
                        </TableCell>
                    );
                })}
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={marginTableStyle}>
                            <Typography variant="h6" gutterBottom component="div">
                                Transaction
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>User</TableCell>
                                        <TableCell>Category</TableCell>
                                        <TableCell align="right">Onhand</TableCell>
                                        <TableCell align="right">Quantity</TableCell>
                                        <TableCell align="left"                                                                    
                                        style={{ width: "40%" }}>Remark</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {row.transactions.map((transRow) => (
                                    <TableRow key={row.id + ':' + transRow.updated}>
                                        <TableCell component="th" scope="row">
                                            {DateFormat(new Date(transRow.updated), "dd-MMM-yy HH:mm")}
                                        </TableCell>
                                        <TableCell>{transRow.creator && transRow.creator.firstName}</TableCell>
                                        <TableCell>{transactionType(transRow.category)}</TableCell>
                                        <TableCell align="right">{transRow.onhand}</TableCell>
                                        <TableCell align="right">{transRow.quantity}</TableCell>
                                        <TableCell align="left">{transRow.note}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function ActivePartsTable(props) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const rows = props.data;
    const [open, setOpen] = React.useState(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    if (utils.IsDebugMode) {
        console.log("Rows in Active Parts Table", rows);
        if (rows && (rows.length > 0)) {
            console.log("Row Data", rows[0].lastTransactionDate, new Date(rows[0].lastTransactionDate));
        }
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 540 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                            </TableCell>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <Row key={row.id} row={row} columns={columns} />
                                );
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );    
}