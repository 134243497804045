import { authHeader, fetchWrapper, handleResponse } from '../_helpers';
import { config } from '../_helpers';

export const reportConfigService = {
    getAll,
    getById,
    update
};

function getAll() {
    return fetchWrapper.get(`${config.apiUrl}/reportConfig`);
}

function getById(id) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${config.apiUrl}/reportConfig/${id}`, requestOptions).then(handleResponse);
}

function update(report) {
    let cloneReport = JSON.parse(JSON.stringify(report));
    delete cloneReport.conditions;
    cloneReport.jsonDisplayFields = JSON.stringify(cloneReport.displayFields);
    cloneReport.jsonSchedule = JSON.stringify(cloneReport.schedule);
    return fetchWrapper.postFormDataFromObjects(`${config.apiUrl}/reportconfig/${cloneReport.id}`, cloneReport);
}