import * as React from 'react';
import { Avatar, Button, Container, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { authenticationService } from '../_services';
import { useNavigate } from 'react-router-dom';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

const Profile = (props) => {
    const settings = ['Profile', 'Logout'];
    const loggedIn = props.login;

    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const navigate = useNavigate();

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = (event) => {
        setAnchorElUser(null);
        let menuName = event.target.innerText;
        if (menuName == "Profile") {
            navigate("/profile");
        } else if (menuName == "Logout") {
            authenticationService.logout().then(() => {
                console.log("LOGOUT!!!!!!!!!!!!!!");
                window.location.reload(true);
                navigate("/");
                window.location.reload(true);
            });
        }
    };

    const handleClickMenu = (event) => {
        let id = event.currentTarget.id;
        if (id === "loginButton") {
            props.itemHandler(id);

        }
    }

    let menu;
    if (loggedIn) {
        //console.log("Provide => user", authenticationService.currentUserValue);
        menu = (
            <React.Fragment>
                <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <Avatar alt={authenticationService.currentUserValue ? authenticationService.currentUserValue.firstName : ''}
                            src="../unnamed.jpg" />
                    </IconButton>
                </Tooltip>
                <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                >
                    {settings.map((setting) => (
                        (setting == 'Profile' ?
                            (<MenuItem key={setting} id={setting} onClick={handleCloseUserMenu} disabled={props.isEditing}>
                                <Typography textAlign="center">{setting}</Typography>
                            </MenuItem>)
                            :
                            (<MenuItem key={setting} id={setting} onClick={handleCloseUserMenu}>
                                <Typography textAlign="center">{setting}</Typography>
                            </MenuItem>)
                        )
                    ))}
                </Menu>
            </React.Fragment>
        );
    } else {
        menu = (
            <React.Fragment>
                <Button
                    id="loginButton"
                    onClick={handleClickMenu}
                    sx={{ my: 2, color: 'white', display: 'flex', marginRight: 2 }}
                >
                    Login
                </Button>
            </React.Fragment>
        );
    }

    return (
        <div>
            {menu}
        </div>
    );
}

export default Profile;