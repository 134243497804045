import React, { useState } from 'react';
import { Box, Button, Card, Modal, Stack, TextField, Typography, Grid } from '@mui/material';
import { partService } from '../../_services';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function PartConsumeForm(props) {
    const selectedPart = { ...props.part };
    const [consumeValue, setConsumeValue] = useState(() => { return 1 });
    const [remarkValue, setRemarkValue] = useState("");
    const [open, setOpen] = useState(() => { return props.show });
    console.log("Consume = ", selectedPart);
    const handleClose = () => {
        setOpen(false);
        props.notifyFormEvent({ name: 'close', value: 0, remark: '' });
    }
    const handleSubmitted = (value) => {
        consumePart(consumeValue, remarkValue);
        props.notifyFormEvent({ name: 'consume', value: value, remark: '' });
    };

    const handleTextFieldChanged = e => {
        //let textValue = e.target.value.replace(/[^\w\s]/gi, "")
        let textValue = e.target.value.replace(/[,\t]/gi, "")
        setRemarkValue(textValue);
    }

    const handleQuantityChanged = e => {
        let newValue = parseInt(e.target.value);
        setConsumeValue(prevValue => prevValue = newValue);
    }

    function consumePart(value, remark) {
        let record = {
            partId: selectedPart.data.id,
            category: 1,
            quantity: value,
            note: remark,
            status: 0
        }
        partService.addTransaction(record)
            .then((data) => {
                alert('Successfully add transaction');
                props.notifyFormEvent({ name: 'transaction', value: 0, remark: '', data: data });
            })
            .catch((error) => {
                alert('Fail to add transaction with this error:' + error);
                //closeAddForm();
            });
    }

    return (
        <Modal
            open={props.show}
        >
            <Box sx={{ ...style, width: 550, height: 440, borderRadius: 3 }}>
                <h5 id="child-modal-title"># {selectedPart.data.id}</h5>
                <div className='part-withdrawer-form-line' />
                <Stack direction="row">
                    <div className="part-withdrawer-form-panel-field">
                        <div className='row col-12'>
                            <p id="child-modal-description">
                                <Typography variant="h6">{selectedPart.data.title}</Typography>
                            </p>
                        </div>
                        <div className='row'>
                            <strong className='col-4'>Operation:</strong>
                            <div className='col-8'>{selectedPart.data.operationName}</div>
                        </div>
                        <div className='row'>
                            <strong className='col-4'>Product:</strong>
                            <div className='col-8'>{selectedPart.data.product}</div>
                        </div>
                        <div className='row'>
                            <strong className='col-4'>Type:</strong>
                            <div className='col-8'>{(selectedPart.data.category == 1 ? 'Consumable' : 'Spare Part')}</div>
                        </div>
                    </div>
                    <div className="part-withdrawer-form-image-field">
                        <Box style={{ height: '120px', width: '120px', margin: '0px auto 10px auto' }}>
                            {selectedPart.data.imageData &&
                                <img src={`data:${selectedPart.data.imageContentType};base64,${selectedPart.data.imageData}`} alt="part-image" id="partImage" name="partImage"
                                    style={{ border: '1px solid', borderRadius: '10px', objectFit: 'contain', width: '120px', height: '120px' }} />
                            }
                            {!selectedPart.data.imageData &&
                                <img src="../image-not-available.jpg" alt="partImage" id="partImage" name="partImage"
                                    style={{ border: '1px solid', borderRadius: '10px', objectFit: 'contain', width: '120px', height: '120px' }} />
                            }
                        </Box>
                    </div>
                </Stack>
                <div className='row'>
                    <div className="col">
                        <Card style={{ margin: '10px', padding: '5px', height: '150px', backgroundColor: '#bbcded' }}>
                            <Stack direction="row">
                                <div className='part-withdrawer-form-onhand-field'>
                                    <div className='row'>
                                        <strong className='col-6'>Safety Level:</strong>
                                        <div className='col-6'>{selectedPart.data.safetyLevel}</div>
                                    </div>
                                    <div className='row'>
                                        <strong className='col-6'>Onhand:</strong>
                                        <div className='col-6'>{selectedPart.data.onhand}</div>
                                    </div>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& > :not(style)': { m: 1, width: '25ch' },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <TextField id="outlined-basic" label="Remark" variant="outlined" type="text"
                                            rows="2"
                                            multiline={true}
                                            style={{ width: 250, height: 120 }}
                                            value={remarkValue}
                                            fullWidth
                                            inputProps={{
                                                shrink: true,
                                            }}
                                            onChange={handleTextFieldChanged}
                                        />
                                    </Box>
                                </div>
                                <div className='part-withdrawer-form-withdraw-buttons'>
                                    <Stack>
                                        <TextField
                                            id="outlined-number"
                                            label="Quantity"
                                            type="number"
                                            inputProps={{
                                                shrink: true,
                                                readOnly: false,
                                                min: 1,
                                                max: selectedPart.data.onhand
                                            }}
                                            value={consumeValue}
                                            onChange={handleQuantityChanged}
                                        />
                                        <Grid container display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            style={{ height: '80px' }}>
                                            <Button variant="contained" onClick={(e) => handleSubmitted(consumeValue)}
                                                style={{ width: 100, margin: 10 }}>Confirm</Button>
                                        </Grid>
                                    </Stack>
                                </div>
                            </Stack>
                        </Card>
                    </div>
                </div>
                <Stack direction="row" justifyContent="space-between">
                    <Button onClick={handleClose} align='center'>Close</Button>
                    <Button variant="outlined" >Barcode</Button>
                </Stack>
            </Box>
        </Modal>
    );
}