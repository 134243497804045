
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Backdrop, Button, CircularProgress, Menu, MenuItem, Select, Stack, TextField, InputLabel, FormControl, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { authenticationService } from '../_services/authentication';
import { eventLogService } from '../_services/eventlog';

import { CSVLink } from 'react-csv';

const columns = [
    { field: 'formattedDate', headerName: 'Time', width: 180 },
    {
      field: 'eventName',
      headerName: 'Event',
      width: 170,
      editable: false,
    },
    {
      field: 'username',
      headerName: 'Username',
      width: 150,
      editable: false,
    },
    {
      field: 'message',
      headerName: 'Action',
      description: 'This column provides the detail of action.',
      sortable: false,
      flex: 1,
      minWidth: 300,      
    }
  ];

export default function EventLogReportPage(props) {

    const eventLogList = [
            { label: "ALL", id: 0 }, 
            { label: "User Management", id: 10}, { label: "Report Settings", id: 20 }, { label: "Group", id: 30 }, { label: "Operation", id: 40 }, { label: "Supplier", id: 50 },
            { label: "Part", id: 60}
        ];

    let rightNow = new Date();
    let endDateValue = rightNow.toISOString().slice(0, 10);
    let beforeNow = new Date(rightNow.getTime() - 604800000);
    let startDateValue = beforeNow.toISOString().slice(0, 10);
    const [dataRows, setDataRows] = React.useState([]);
    const [selectedEndDate, setSelectedEndDate] = React.useState(endDateValue);
    const [selectedStartDate, setSelectedStartDate] = React.useState(startDateValue);
    const [selectedCategory, setSelectedCategory] = React.useState(0);
    
    let activeUser = authenticationService.currentUserValue;
    let roleName = "guest";
    if (activeUser) {
        roleName = "operator"
        if (activeUser.role != "Withdrawer") {
            roleName = "admin";
        }
    }
    
    const handleDateChange = (event) => {
        let source = event.target.id;
        let newDate = event.target.value;

        let dateValue;
        let refDateValue;
        if (source == "startDate") {
            dateValue = new Date(newDate).toISOString().slice(0, 10);
            refDateValue = new Date(selectedEndDate).toISOString().slice(0, 10);
            if (dateValue > refDateValue) {
                setSelectedStartDate(selectedEndDate);
            } else {
                setSelectedStartDate(newDate);
            }
        } else if (source == "endDate") {
            dateValue = new Date(newDate).toISOString().slice(0, 10);
            refDateValue = new Date(selectedStartDate).toISOString().slice(0, 10);
            if (dateValue < refDateValue) {
                setSelectedEndDate(selectedStartDate);
            } else {
                setSelectedEndDate(newDate);
            }
        }
    };

    const handleItemChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const handleQuery = () => {
        queryData();
    }

    let rows = [];
    function queryData() {    
        console.log(`Category=${selectedCategory}, Start=${selectedStartDate}, End=${selectedEndDate}`);
        eventLogService.getEventLogs(selectedCategory, selectedStartDate, selectedEndDate) 
            .then((data) => {
                rows = data;
                console.log(rows);
                setDataRows(rows);
            }).catch((error) => {
                console.log("error", error);
            });
    } 

    return (
        <Stack spacing={2} style={{ padding: '10px' }}>
            <h3>Event Logs</h3>
            <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', alignContent: 'center'}}>
                <div style={{ flexBasis: 'auto' }}>
                    <FormControl sx={{ m: 1, minWidth: 200 }}>
                        <InputLabel id="select-label">Category</InputLabel>
                        <Select
                            label="Category"
                            id="category"
                            value={selectedCategory}
                            onChange={handleItemChange}                            
                        >
                        {
                            eventLogList.map( (kvp) => 
                                <MenuItem key={kvp.id} value={kvp.id}>{kvp.label}</MenuItem>)
                        }
                        </Select>
                    </FormControl>
                </div>
                <div style={{ flexBasis: 'auto'}}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} md={6}>
                            <FormControl sx={{ m: 1, minWidth: 80 }}>
                                <TextField
                                    id="startDate"
                                    label="Start Date"
                                    type="date"
                                    value={selectedStartDate}
                                    onChange={handleDateChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl sx={{ m: 1, minWidth: 80 }}>
                                <TextField
                                    id="endDate"
                                    label="End Date"
                                    type="date"
                                    value={selectedEndDate}
                                    onChange={handleDateChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}                      
                                />
                            </FormControl>                
                        </Grid>
                    </Grid>
                </div>
                <div style={{ flexBasis: 'auto'}}>
                    <Grid container direction="row" spacing={2} justifyContent="center">
                        <Grid item xs={12} sm={6} md={6}>
                            <FormControl sx={{ m: 1, minWidth: 100 }}>
                                <Button variant='contained' onClick={handleQuery} align='center'>Query</Button>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <FormControl sx={{ m: 1, minWidth: 100 }}>
                                <Button variant='outlined' align='center'>
                                    <CSVLink data={dataRows} filename={Date.now() + "-eventlogs.csv"} size="medium">Export</CSVLink>                                
                                </Button>
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            </div>      
            <div style={{ height: 600, width: '100%' }}>
                <DataGrid
                rows={dataRows}
                columns={columns}
                initialState={{
                    pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                    },
                }}
                pageSizeOptions={[5, 10]}
                />
            </div>
        </Stack>                    
    );
}