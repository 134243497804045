import React from 'react'
import styled from 'styled-components';

const StyleInput = styled.input`
    border: 1px solid #ddd;
    margin: 0px 0px 0px 0px;     
`

export const TableColumnFilter = ({ column }) => {
    const { filterValue, setFilter } = column
    return (
        <span>
            <input value={filterValue || ''} onChange={(e) => setFilter(e.target.value)} />
        </span>
    )
}