import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function GenericAddItemDialog(props) {
    const { title, content, open, setOpen, onConfirm, item, itemList, validateFunc } = props;
    const [newItem, setNewItem] = React.useState(item);
    const [errMsg, setErrMsg] = React.useState("");

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose} fullWidth>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {content}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(e) => {
                            let newValue = e.target.value;
                            if (itemList) {
                                if (itemList.findIndex(i => i == newValue) > - 1) {
                                    setErrMsg("This is the duplicated item (" + newValue + ")");
                                } else {
                                    if (validateFunc(newValue)) {
                                        setErrMsg("");
                                        setNewItem(newValue);
                                    } else {
                                        setErrMsg("Must assign email address");
                                    }
                                }
                            }
                        }}
                    />
                    {errMsg && <span className="form-field-error-message">{errMsg}</span>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={
                        () => {
                            let index = itemList.findIndex(v => v == newItem);
                            if (index < 0) {
                                setOpen(false);
                                onConfirm(newItem);
                            } else {
                                alert(`This item (${newItem}) is duplicated with the items in the list. Cannot be added`);
                            }
                        }
                    }
                        disabled={errMsg != ""}>Add</Button>
                    <Button onClick={
                        () => {
                            setErrMsg("");
                            setOpen(false);
                        }
                    }>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}