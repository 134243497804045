import React, { useEffect, useState } from 'react';
import { Box, Container, FormControl, Grid, Stack, Typography, TextField, Modal } from '@mui/material';
import { useForm } from "react-hook-form";
import DialogYesNo from '../components/ModalYesNo';
import AddReportDialog from '../components/Report/AddReportDialog';
import GenericAddItemDialog from '../components/Report/GenericAddItemDialog';
import VisibleColumnDialog from '../components/Report/VisibleColumnDialog';

const ReportConfigurationPage = (props) => {
    const pageStatusCallback = props.pageStatusChanged;

    const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const hours = [...Array(23).keys()];

    const [data, setData] = useState({
        dataSets: [
            {
                table: 'Parts',
                columns: ['Id', 'Title', 'Category', 'Owner', 'GroupId', 'SafetyLevel', 'SupplierId', 'OperationId', 'Price', 'LeadTime', 'Product', 'StoreLocation', 'Updated', 'Created', 'Remark', 'Onhand', 'CreatorId', 'ImageData', 'ImageContentType', 'Status', 'Active']
            },
            {
                table: 'Transaction',
                columns: ['Id', 'Updated', 'PartId', 'Category', 'Onhand', 'ReferenceId', 'Quantity', 'Note', 'CreatorId', 'Status']
            }
        ],
        records: []
    });
    const [mode, setMode] = useState("view");
    const [reportId, setReportId] = useState(0);
    const [selectedReport, setSelectedReport] = useState(null);
    const [timeValue, setTimeValue] = useState(new Date());

    const [dialogTitle, setDialogTitle] = useState("Title");
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState("");
    const [dialogConfirmCallback, setDialogConfirmCallback] = useState();
    const [addReportOpen, setAddReportOpen] = useState(false);

    const [genericAddDialogTitle, setGenericAddDialogTitle] = useState("Title");
    const [genericAddDialogOpen, setGenericAddDialogOpen] = useState(false);
    const [genericAddDialogContent, setGenericAddDialogContent] = useState("");
    const [genericAddDialogConfirmCallback, setGenericAddDialogConfirmCallback] = useState();

    const [visibleColDlgOpen, setVisibleColDlgOpen] = useState(false);
    const [visibleColDlagMode, setVisibleColDlgMode] = useState('view');
    const [selVisibleCol, setSelVisibleCol] = useState({
        dataSet: '',
        column: '',
        header: ''
    });

    const [newReportName, setNewReportName] = useState("");
    const [addingEmail, setAddingEmail] = useState("");
    const [addingVisibleColumn, setAddingVisibleColumn] = useState("");


    if (data.records.length == 0) {
        console.log("First call");
        setData(
            prevValue => prevValue = {
                ...data,

                records: [
                    {
                        id: 1,
                        name: 'Report 1', subject: 'Attn: All buyers', content: 'Please review data',
                        email: '',
                        distributionList: ['buyer1@fab.com', 'buyer2@fab.com', 'buyer3@fab.com'],
                        visibleColumn: '',
                        visibleColumns: [
                            { header: 'Part No', table: 'Parts', column: 'Id', key: 1 },
                            { header: 'Description', table: 'Parts', column: 'Title', key: 2 },
                            { header: 'Supplier', table: 'Parts', column: 'SupplierId', key: 3 },
                            { header: 'Onhand', table: 'Parts', column: 'Onhand', key: 4 },
                            { header: 'Safety Level', table: 'Parts', column: 'SafetyLevel', key: 5 }
                        ],
                        schedule: {
                            days: ['Sun', 'Mon', 'Fri'],
                            time: 10
                        },
                        conditions:
                            `
                        {
                        "Parameters": [
                            {
                            "Id": "param1",
                            "Label": "Category",
                            "Options": [
                                {
                                "Label": "Consumable",
                                "Value": 1
                                },
                                {
                                "Label": "Spare Part",
                                "Value": 2
                                }
                            ],
                            "DataType": "int",
                            "Value": 1
                            },
                            {
                            "Id": "param2",
                            "Label": "Report Period",
                            "Options": [],
                            "DataType": "int",
                            "Value": 7
                            }    
                        ],
                        "Content": {
                            "Logical": "AND",
                            "Expressions": [],
                            "Evaluation": [
                            {
                                "Logic": "",
                                "Expressions": [
                                "[Parts].[Category]=@param1"
                                ],
                                "Evaluation": []
                            },
                            {
                                "Logic": "",
                                "Expressions": [
                                "[Parts].[OperationId]=''"
                                ],
                                "Evaluation": []
                            },
                            {
                                "Logic": "AND",
                                "Expressions": [
                                    "[PartTransactions].[Updated] >= {Today - @param2}",
                                    "[PartTransactions].[Updated] <= {Today}"
                                    ],
                                "Evaluation": []
                            }
                            ]
                        }
                        }                    
                    
                    `
                    },
                    {
                        id: 2,
                        name: 'Report 2', subject: 'Send information', content: 'Report under wrap',
                        email: '',
                        distributionList: ['buyer1@fab.com', 'buyer2@fab.com', 'buyer3@fab.com'],
                        visibleColumn: '',
                        visibleColumns: [
                            { header: 'Part No', table: 'Parts', column: 'Id', key: 1 },
                            { header: 'Description', table: 'Parts', column: 'Title', key: 2 },
                            { header: 'Supplier', table: 'Parts', column: 'SupplierId', key: 3 },
                            { header: 'Onhand', table: 'Parts', column: 'Onhand', key: 4 },
                            { header: 'Safety Level', table: 'Parts', column: 'SafetyLevel', key: 5 }
                        ],
                        schedule: {
                            days: ['Mon', 'Wed', 'Fri'],
                            time: 22
                        },
                        conditions:
                            `                        
                    `
                    }
                ]
            }
        )
    }
    if ((selectedReport == null) && (data && data.records.length > 0)) {
        console.log("selectedReport is NULL and data is", data.records[0]);
        setSelectedReport(prevValue => prevValue = data.records[0]);
    }

    const cardBodyPaddingStyle = {
        padding: 10
    }

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        mode: "onBlur",
        shouldUseNativeValidation: true
    });

    useEffect(() => {
        console.log("useEffect => data", data);
        if (selectedReport) {
            setDataFields(selectedReport);
        } else {
            setDataFields({
                id: '', name: '', subject: '', content: '', distributionList: [], visibleColumns: [],
                schedule: { days: [], time: 0 }
            })
        }
    }, [reportId]);

    function setDataFields(reportItem) {
        console.log("setDataField", reportItem)
        const fields = ['id', 'name', 'subject', 'content'];
        fields.forEach((field) => {
            console.log("FIELD", field);
            setValue(field, reportItem[field])
            console.log("Set Done");
        });
    }

    const handleReportSelectionChanged = (e) => {
        let id = parseInt(e.target.value);
        setReportId(prevValue => prevValue = id);
        let item = data.records.find((rec) => (rec.id == id));
        console.log(item);
        setSelectedReport(prevValue => prevValue = item);
        setDataFields(item);
    }
    const handleTextFieldChanged = (e) => {

    }

    const handleButtonClicked = (e) => {
        switch (e.target.name) {
            case "addReportButton":
                setAddReportOpen(true);
                break;
            case "removeReportButton":
                setDialogTitle("Remove Report");
                setDialogContent(`Do you want to remove this report (${selectedReport.name})?`);
                setDialogConfirmCallback(prevValue => prevValue = () => {
                    console.log("Confirm delete");
                });
                setDialogOpen(true);
                break;
            case "addEmailButton":
                setGenericAddDialogTitle("Add Email");
                setGenericAddDialogContent("Add an email to distribution list");
                setGenericAddDialogConfirmCallback(prevValue => prevValue = () => {
                    console.log("Confirm add");
                });
                setGenericAddDialogOpen(true);
                break;
            case "removeEmailButton":
                setDialogTitle("Remove Email");
                setDialogContent(`Do you want to remove this email () from the distribution list?`);
                setDialogConfirmCallback(prevValue => prevValue = () => {
                    console.log("Confirm delete");
                });
                setDialogOpen(true);
                break;
            case "viewVisibleColumnButton":
                setVisibleColDlgMode('view');
                setVisibleColDlgOpen(true);
                break;
            case "addVisibleColumnButton":
                setVisibleColDlgMode('add');
                setVisibleColDlgOpen(true);
                break;
            case "removeVisibleColumnButton":
                setDialogTitle("Remove Visible Column");
                setDialogContent(`Do you want to remove this visible column () from the column list?`);
                setDialogConfirmCallback(prevValue => prevValue = () => {
                    console.log("Confirm delete");
                });
                setDialogOpen(true);
                break;

        }
    }
    const handleEditClicked = (e) => {

    }
    const handleSaveClicked = (e) => {

    }
    const handleCancelClicked = (e) => {

    }
    const handleDayCheckChanged = (e) => {
        if (selectedReport != null) {
            const selDay = e.target.name;
            const selected = e.target.checked;
            let newSchedule = { days: [], time: '' };
            if (selected) {
                newSchedule.days = [...selectedReport.schedule.days, selDay];
            } else {
                newSchedule.days = selectedReport.schedule.days.filter(d => d != selDay);
            }
            setSelectedReport(prevValue => prevValue = { ...selectedReport, schedule: newSchedule })
        }
    }
    const handleHourSelectionChanged = (e) => {
        if (selectedReport != null) {
            const newHour = e.target.value;
            let newSchedule = { days: [], time: parseInt(newHour) };
            newSchedule.days = selectedReport.schedule.days;
            setSelectedReport(prevValue => prevValue = { ...selectedReport, schedule: newSchedule })
        }
    }
    const handleDialogClosed = (e) => {
        console.log("Dialog Closed: New Report Name", newReportName);
        setNewReportName("");
    }


    const handleAddReportConfirm = (newReportName) => {
        console.log(newReportName);
    }
    const handleAddReportClosed = () => {
        console.log("Close");
    }

    const handleVisibleColDlgConfirm = (columnItem) => {
        console.log(columnItem);
    }

    const onSubmit = async data => {
        console.log(data);
    };
    console.log("Report=", selectedReport, "Data.Length=", data.records.length);

    return (
        <Stack>
            <Typography variant="h6" style={{ margin: '10px' }}>
                Report Configuration
            </Typography>
            <Container style={{ maxWidth: 900, justifyContent: 'center' }}>
                <div className="card m-3" style={{ paddingTop: 10, paddingLeft: 5 }}>
                    <div className="form-row">
                        <label className="form-custom-label col-3">Reports:</label>
                        <div className="form-group col-5 mb-1">
                            <select name="report" value={selectedReport === null ? 0 : selectedReport.id}
                                className={`form-control`}
                                onChange={handleReportSelectionChanged}
                            >
                                {data.records.map((report) => {
                                    return (
                                        <option value={report.id} key={report.id}>{report.name}</option>
                                    )
                                })

                                }
                            </select>
                        </div>
                        <div className="form-group col-col">
                            <button className="btn btn-primary form-user-button" name="addReportButton" onClick={handleButtonClicked}>Add</button>
                            <button className="btn btn-secondary form-user-button" name="removeReportButton" onClick={handleButtonClicked}>Remove</button>
                        </div>
                    </div>
                </div>
                <Box>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="card m-3">
                            <div className="card-body" style={cardBodyPaddingStyle}>
                                <div className="form-row">
                                    <label className="form-custom-label col-3">Name:</label>
                                    <div className="form-group col-5">
                                        <input name="name" type="text"
                                            {...register('name', { required: true })}
                                            className={`form-control form-field ${errors.name ? 'is-invalid' : ''}`}
                                            onChange={handleTextFieldChanged}
                                            disabled={mode !== 'edit'}
                                        />
                                        {mode === 'edit' && errors.name?.type === 'required' && <span className="form-field-error-message">Name is required</span>}
                                    </div>
                                    <div className="form-group col-col">
                                    </div>
                                </div>
                                <div className="form-row">
                                    <label className="form-custom-label col-3">Subject:</label>
                                    <div className="form-group col-5">
                                        <input name="subject" type="text"
                                            className="form-control"
                                            onChange={handleTextFieldChanged}
                                            disabled={mode !== 'edit'}
                                        />
                                        {mode === 'edit' && errors.subject?.type === 'required' && <span className="form-field-error-message">Subject is required</span>}
                                    </div>
                                    <div className="form-group col-col">
                                    </div>

                                </div>
                                <div className="form-row">
                                    <label className="form-custom-label col-3">Content:</label>
                                    <div className="form-group col-5">
                                        <textarea name="content" rows="4" {...register('content')}
                                            className={`form-control ${errors.content ? 'is-invalid' : ''}`}
                                            onChange={handleTextFieldChanged}
                                            disabled={mode !== 'edit'}
                                        />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <label className="form-custom-label col-3">Distribution List:</label>
                                    <div className="form-group col-5">
                                        <select {...register("email")}
                                            className={`form-control`}
                                        >
                                            {selectedReport && selectedReport.distributionList.map((email) => (
                                                <option value={email} key={email}>{email}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group col-col">
                                        <button className="btn btn-primary form-user-button" name="addEmailButton" onClick={handleButtonClicked}>Add</button>
                                        <button className="btn btn-secondary form-user-button" name="removeEmailButton" onClick={handleButtonClicked}>Remove</button>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <label className="form-custom-label col-3">Visible Columns:</label>
                                    <div className="form-group col-5">
                                        <select {...register("visibleColumn")}
                                            className={`form-control`}
                                        >
                                            {selectedReport && selectedReport.visibleColumns.map((column) => (
                                                <option value={column.id} key={column.id}>{column.header}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group col-auto">
                                        <button className="btn btn-success form-user-button" name="viewVisibleColumnButton" onClick={handleButtonClicked}>View</button>
                                        <button className="btn btn-primary form-user-button" name="addVisibleColumnButton" onClick={handleButtonClicked}>Add</button>
                                        <button className="btn btn-secondary form-user-button" name="removeVisibleColumnButton" onClick={handleButtonClicked}>Remove</button>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <label className="form-custom-label col-3">Conditions:</label>
                                    <div className="form-group col-5 mb-1">
                                        <input name="content" type="hidden" {...register("content")} />
                                        <button
                                            className="btn btn-success form-user-button btn-block"
                                            disabled={mode !== 'edit'}
                                        >Edit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card m-3">
                            <div className="card-body" style={cardBodyPaddingStyle}>
                                <div className="form-row">
                                    <label className="form-custom-label col-3">Schedule:</label>
                                    <div className="form-group col-7 mb-1" style={{ justifyContent: 'center', alignItems: 'center' }}>
                                        {weekDays.map((day) => {
                                            let checkedValue = false;
                                            if (selectedReport && selectedReport.schedule.days.findIndex((d) => d === day) > -1) {
                                                checkedValue = true;
                                            }

                                            return (<>
                                                <input name={day} key={day} type="checkbox" onChange={handleDayCheckChanged} checked={checkedValue} />
                                                <label className='pl-1 pr-3' key={day + Date.now().toString()}>{day}</label>
                                            </>);
                                        })}
                                    </div>
                                    <div className="form-group col-2 mb-1">
                                        <label className="form-custom-label w-50">Hour:</label>
                                        <select className="w-50" value={selectedReport == null ? 0 : selectedReport.schedule.time}
                                            onChange={handleHourSelectionChanged}>
                                            {hours.map((hr) => (
                                                <option value={hr} key={hr}>{hr}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card m-3">
                            <div className="row">
                                <div className="col" style={{ display: 'flex' }}>
                                    {selectedReport && (mode == 'view') && <div className="btn-group" role="group">
                                        <button type="button" className="btn btn-primary form-user-button"
                                            onClick={handleEditClicked}>Edit</button>
                                    </div>}
                                    {(mode === 'edit') &&
                                        <div className="btn-group" role="group">
                                            <button type="submit" className="btn btn-primary form-user-button"
                                                onClick={handleSaveClicked}>Save</button>
                                            <button type="button" className="btn btn-secondary form-user-button"
                                                onClick={handleCancelClicked}>Cancel</button>
                                        </div>
                                    }
                                </div>
                                <div className="col">
                                    {(mode == 'add' || mode == 'edit') &&
                                        <button type="button" onClick={() => reset()}
                                            className="btn btn-light form-user-button">Clear</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </form>
                </Box>
            </Container>
            <AddReportDialog
                name={selectedReport?.id}
                open={addReportOpen}
                setOpen={setAddReportOpen}
                onConfirm={handleAddReportConfirm}
                onClose={handleAddReportClosed}
            />
            <GenericAddItemDialog
                title={genericAddDialogTitle}
                open={genericAddDialogOpen}
                setOpen={setGenericAddDialogOpen}
                onConfirm={genericAddDialogConfirmCallback}
                content={genericAddDialogContent}
                itemList={selectedReport.distributionList}
            />
            <VisibleColumnDialog
                title="Column in Report"
                content="Configure the following parameters to add the column being displayed in the report"
                open={visibleColDlgOpen}
                setOpen={setVisibleColDlgOpen}
                onConfirm={handleVisibleColDlgConfirm}
                mode={visibleColDlagMode}
            />
            <DialogYesNo
                title={dialogTitle}
                open={dialogOpen}
                setOpen={setDialogOpen}
                onConfirm={dialogConfirmCallback}
            >
                {dialogContent}
            </DialogYesNo>
        </Stack >
    );
};

export default ReportConfigurationPage;
