import React, { useState } from 'react';

const ParentPage = () => {
    const [value, setValue] = useState(0);
    console.log("Parent called", value);
    return (
        <div>
            <button onClick={() => setValue(value + 1)}>
                Increase
            </button>
            <ChildPage value={value} />
        </div>
    );
}

function ChildPage(props) {
    console.log("Child called", props.value);
    return (
        <div><h3>{props.value}</h3></div>
    );
}
export default ParentPage;