import * as React from 'react';
import { AppBar, Avatar, Box, Button, Container, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography } from "@mui/material"
import MenuIcon from '@mui/icons-material/Menu';
import { Inventory, Home as HomeIcon, AccountBox, FileUpload, Article, AppRegistration, Assessment, SupervisedUserCircle, Image, Info, EventNote } 
    from '@mui/icons-material';
//import { FileDownloadIcon } from '@mui/icons-material/FileDownload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Route, Link, Routes } from 'react-router-dom';
import Profile from './Profile';
import { authenticationService, seedService } from '../_services';
import { Role } from '../_helpers/mock-backend/role';

const pages = [
    { name: 'Home', icon: <HomeIcon />, goto: '/', show: true },
    { name: 'Parts', icon: <Inventory />, goto: '/parts', show: true },
    { name: 'Register', icon: <AppRegistration />, goto: '/register', show: false },
    { name: 'Report', icon: <Assessment />, goto: '/report', show: false },
    { name: 'Master Data', icon: <Article />, goto: '/master', show: false },
    { name: 'Data Imports', icon: <FileUpload />, goto: '/imports', show: false },
    { name: 'Accounts', icon: <AccountBox />, goto: '/accounts', show: false },
    { name: 'About', icon: <Info />, goto: '/about', show: true },
    { name: 'Event Logs', icon: <EventNote />, goto: '/eventlogs', show: false },    
    { name: 'Data Exports', icon: <FileDownloadIcon />, goto: 'exports', show: false },
    { name: 'Test', icon: <AccountBox />, goto: '/test', show: false }
];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const ResponsiveAppBar = (props) => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const handleItemCallback = (itemName) => {
        props.itemHandler(itemName);
    }

    const loggedIn = authenticationService.currentUserValue != null;
    //console.log('ResponsiveAppBar => User', authenticationService.currentUserValue);
    //const pageList = pages.filter(p => p.name !== 'Accounts' && p.name !== 'Data Imports');
    const pageList = [...pages];
    //let filterInPageList = pages.filter(p => p.name === 'Data Imports');
    //filterInPageList[0].show = props.role === Role.Admin;
    //pageList.push(filterInPageList[0]);
    //filterInPageList = pages.filter(p => p.name === 'Accounts');
    //filterInPageList[0].show = props.role === Role.Admin;
    //pageList.push(filterInPageList[0]);
    const SHOW_REPORT = true;
    if (authenticationService.currentUserValue != null) {
        const roleValue = authenticationService.currentUserValue.role;
        console.log('roleValue', roleValue);
        if (roleValue == Role.SuperSeiya) {
            const foundIndex = pageList.findIndex(p => {
                return p.name == 'Register'
            });
            if (foundIndex) {
                console.log('found page', foundIndex);
                pageList[foundIndex].show = true;
            }
        } else if (roleValue == Role.Admin) {
            const indexes = pageList
                .map((element, index) => {
                    if (element.name === 'Master Data' || element.name === 'Accounts' 
                        || element.name === 'Event Logs' || element.name == 'Data Exports') {
                        return index;
                    } else if (element.name === 'Report' && SHOW_REPORT) {
                        return index;
                    }
                })
                .filter(element => element >= 0);
            if (indexes.length > 0) {
                indexes.forEach(index => {
                    pageList[index].show = true;
                });
            }
        }
    }

    const [ver, setVer] = React.useState("");    
    React.useEffect(() => {
        seedService.getBackendVersion()
            .then((data) => {
                setVer(data);
            });
    }, []);

    return (
        <AppBar position="fixed">
            <Container maxWidth="x1">
                <Toolbar disableGutters>
                    <img src="FabrinetLogo-300x120.png" alt="LOGO" width="100" />
                    {/* 
                    <Typography
                        variant="h6" noWrap component="div"
                        sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}>
                        LOGO
                    </Typography>
                    */}
                    {ver != "" &&
                        <small style={{fontSize: "0.6em"}}>[{ver}]</small>
                    }
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                            disabled={props.isEditing}>
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                            disabled={props.isEditing}
                        >
                            {pages.map((page) => (
                                page.show ? (
                                    <Link to={page.goto} key={page.name}>
                                        <MenuItem key={page.name} onClick={handleCloseNavMenu} disabled={props.isEditing}>
                                            <Typography textAlign="center">{page.name}</Typography>
                                        </MenuItem>
                                    </Link>
                                ) : null
                            ))}
                        </Menu>
                    </Box>
                    <Typography variant="h6" noWrap component="span"
                        sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        LOGO
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pageList.map(page => (
                            page.show ?
                                (props.isEditing ?
                                    <Button key={page.name}
                                        sx={{ my: 2, color: 'white', display: 'flex', marginRight: 2 }}
                                        startIcon={page.icon}
                                        disabled={props.isEditing}>
                                        {page.name}
                                    </Button>

                                    :
                                    <Link to={page.goto} key={page.name}>
                                        <Button
                                            onClick={handleCloseNavMenu}
                                            sx={{ my: 2, color: 'white', display: 'flex', marginRight: 2 }}
                                            startIcon={page.icon}>
                                            {page.name}
                                        </Button>
                                    </Link>)
                                : null
                        ))}
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        {loggedIn
                            ? <Profile itemHandler={handleItemCallback} login={loggedIn} isEditing={props.isEditing} />
                            : <Link to={"/login"}><Profile itemHandler={handleItemCallback} login={loggedIn} /></Link>
                        }
                        {/*
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt="Remy Sharp" src="../unnamed.jpg" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                        */}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default ResponsiveAppBar;