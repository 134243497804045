import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { FormControl, ListItemText, Select, OutlinedInput, InputLabel, Checkbox } from '@mui/material';
import { utils } from '../../_helpers';
////<Checkbox checked={visibleColumnNames.indexOf(column) > -1} {...column.getToggleHiddenProps()}/>
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const DEBUG = false;

export const PartVisibleColumnsMultipleSelectCheckbox = (props) => {
    const illegibleColumns = props.columns.filter((col) => {
        return (col.Header != "Part Number") && (col.Header != "Description");
    });

    const [totalVisible, setTotalVisible] = React.useState(illegibleColumns.filter(col => col.isVisible).length);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        //console.log("***Value***", event);       
        //illegibleColumns.forEach((col) => {
        //   console.log('handleChange|ILLI| COLS => ' + col.Header + " = " + col.isVisible);
        //})
        //allColumns.forEach((col) => {
        //    console.log('handleChange|ALL |COLS => ' + col.Header + " = " + col.isVisible);
        //})
        //console.log("Value", value);
        //setVisibleColumnNames(prevValue => prevValue = tempNames);
        //setVisibleColumns(prevValue => prevValue = illegibleColumns.filter(col => col.isVisible).map(col => col.Header));
        //console.log("B/F Total=" + illegibleColumns.filter(col => col.isVisible).length);
        //setTotalVisible(illegibleColumns.filter(col => col.isVisible).length);
        //console.log("A/T Total=" + illegibleColumns.filter(col => col.isVisible).length);
        //console.log('Visible Columns', visibleColumnNames);
        //console.log('... Handle Changed..');
        
        //let newColumns = visibleColumns.filter((col) => visibleColumnNames.indexOf(col.Header) > -1);
        //visibleColumns.forEach((col) => {            
        //    console.log(col.Header + '=' + col.isVisible);
        //});
    
        //props.onColumnSelectionChanged(illegibleColumns);
    };

    const notifySelectionChanged = (selectedCols) => {
        if (utils.IsDebugMode) {
            console.log('Notify selected changed', selectedCols);
        }
        props.onColumnSelectionChanged(selectedCols);
    }

    const renderSelect = (selected) => {
        //console.log(selected);
        //console.log(selected.length);
        if (selected.length != totalVisible) {
            setTotalVisible(selected.length);
            notifySelectionChanged(selected);
        }
        let joinSelects = selected.join();        
        return joinSelects;
      };

    return (
        <FormControl sx={{ m: 1, width: 400 }}>
            <InputLabel id="multiple-checkbox-label">Visible Columns</InputLabel>
            <Select
                id="multiple-checkbox"
                size="small"
                multiple
                value={illegibleColumns.filter(item => item.isVisible).map(item => item.Header)}            
                input={<OutlinedInput label="Visible Columns" />}
                renderValue={renderSelect}
                MenuProps={MenuProps}
            >
                {illegibleColumns.map((column) => {
                    return (
                        <MenuItem key={column.id} value={column}>                            
                            <Checkbox {...column.getToggleHiddenProps()} />
                            <ListItemText primary={column.Header} />
                        </MenuItem>
                    )
                }
                )}
            </Select>            
        </FormControl>
    );
}