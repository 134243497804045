import React, { useState, useEffect } from 'react';
import { Box, Button, Card, Container, Grid, IconButton, InputBase, Paper, Stack, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { authenticationService } from '../_services/authentication';

const ProfilePage = () => {

    const selectedUser = authenticationService.currentUserValue;
    console.log('Profile Page: Current User', selectedUser);
    // get functions to build form with useForm() hook
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        mode: "onBlur"
    });

    const [mode, setMode] = useState('view');
    const [isEditing, setIsEditing] = useState(false);
    const [user, setUser] = useState(selectedUser);

    const handleTextFieldChanged = e => {
        let newUser = { ...user };
        const { [e.target.name]: keyValue } = user;
        newUser[e.target.name] = e.target.value;
        setUser(newUser);
    }

    const handlePasswordFieldChange = e => {

    }

    const handleEditClicked = e => {
        setIsEditing(true);
        setMode('edit');
    }
    const handleAddClicked = e => {
        setIsEditing(true);
        setMode('add');
        reset();
    }
    const handleSaveClicked = e => {
        /*
        console.log(props.userList);
        const duplicatedUser = props.userList.filter(u => u.username.toLowerCase() == user.username.toLowerCase());
        if (duplicatedUser) {
            alert('Duplicated');
        } else {
            alert('OK');
            //props.viewClicked();
            //setIsEditing(false);
            //setMode('view');
        }
        */
    }
    const handleCancelClicked = e => {
        setIsEditing(false);
        setMode('view');
    }
    const handleDeleteClicked = e => {
        console.log("Delete => " + selectedUser.username);
    }
    const handleFileSelectionChanged = (e) => {
        var tgt = e.target || window.event.srcElement,
            files = tgt.files;

        // FileReader support
        if (FileReader && files && files.length) {
            var fr = new FileReader();
            fr.onload = function () {
                document.getElementById("profile").src = fr.result;
            }
            fr.readAsDataURL(files[0]);
        }

        // Not supported
        else {
            // fallback -- perhaps submit the input to an iframe and temporarily store
            // them on the server until the user's session ends.
        }
    }

    function onSubmit(data) {

    }
    console.log("Profile: User", selectedUser);

    useEffect(() => {
        // get user and set form fields
        const fields = ['username', 'firstName', 'lastName', 'email', 'role'];
        fields.forEach(field => setValue(field, selectedUser[field]));

    }, [user]);

    return (
        <div className="card m-3">
            <h5 className="card-header">My Profile</h5>
            <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-row">
                        <div className="col" style={{ alignItems: 'center' }}>
                            <Box style={{ height: '100px', width: '100px', margin: '0px auto 10px auto' }}>
                                {user.imageData &&
                                    <img src={`data:image/png;base64,${user.imageData}`} alt="profile" id="profile" name="profile"
                                        style={{ border: '1px solid', borderRadius: '50%', objectFit: 'contain', width: '100px', height: '100px' }} />
                                }
                                {!user.imageData &&
                                    <img src="../default-avatar.png" alt="profile" id="profile" name="profile"
                                        style={{ border: '1px solid', borderRadius: '50%', objectFit: 'contain', width: '100px', height: '100px' }} />
                                }
                            </Box>
                        </div>
                    </div>
                    {(mode == 'add' || mode == 'edit') && <div className="form-row">
                        <div className="col" style={{ margin: '5px' }}>
                            <div className="form-group">
                                <input name="profileFile" type="file"
                                    {...register('profileFile', { required: false })}
                                    className="form-control" id="profileFile" onChange={handleFileSelectionChanged} />
                            </div>
                        </div>
                    </div>}
                    <div className="form-row">
                        <label className="form-custom-label col-5">Username</label>
                        <div className="form-group col-7">
                            <input name="username" type="text"
                                {...register('username', { required: true })}
                                className={`form-control form-field ${errors.username ? 'is-invalid' : ''}`}
                                onChange={handleTextFieldChanged}
                                readOnly={!isEditing}
                            />
                            {errors.username?.type === 'required' && <span className="form-field-error-message">Username is required</span>}
                        </div>
                    </div>
                    <div className="form-row">
                        <label className="form-custom-label col-5">First Name</label>
                        <div className="form-group col-7">
                            <input name="firstName" type="text" {...register('firstName', { required: true })} className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
                                onChange={handleTextFieldChanged}
                                readOnly={!isEditing}
                            />
                            {errors.firstName?.type === 'required' && <span className="form-field-error-message">First Name is required</span>}
                        </div>
                    </div>
                    <div className="form-row">
                        <label className="form-custom-label col-5">Last Name</label>
                        <div className="form-group col-7">
                            <input name="lastName" type="text" {...register('lastName')} className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
                                onChange={handleTextFieldChanged}
                                readOnly={!isEditing}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <label className="form-custom-label col-5">Email</label>
                        <div className="form-group col-7">
                            <input
                                type="email"
                                {...register("email", {
                                    required: "Email is required",
                                    validate: (value) =>
                                        value.includes("@") || "Email must include '@' symbol"
                                })}
                                onChange={handleTextFieldChanged}
                                readOnly={!isEditing}
                                style={{ width: '100%' }}
                            />
                            {errors.email && (<span className="form-field-error-message">{errors.email.message}</span>)}
                        </div>
                    </div>
                    <div className="form-row">
                        <label className="form-custom-label col-5">Password</label>
                        <div className="form-group col-7">
                            <input name="password" type="password"
                                {
                                ...register('password',
                                    {
                                        required: isEditing ? "Password is required!" : ""
                                    }
                                )}

                                onChange={handlePasswordFieldChange}
                                readOnly={!isEditing}
                                className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
                            {isEditing && errors.password && (<span className="form-field-error-message">{errors.password.message}</span>)}
                        </div>
                    </div>
                    <div className="form-row">
                        <label className="form-custom-label col-5">Confirm Password</label>
                        <div className="form-group col-7">
                            <input name="confirmPassword" type="password"
                                {...register('confirmPassword',
                                    {
                                        required: isEditing ? "Please confirm password!" : "",
                                        validate: {
                                            matchesPreviousPassword: (value) => {
                                                const { password } = getValues();
                                                return !isEditing || password === value || "Passwords should match!";
                                            }
                                        }
                                    })}
                                className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                                readOnly={!isEditing}
                            />
                            {isEditing && errors.confirmPassword && (<span className="form-field-error-message">{errors.confirmPassword.message}</span>)}
                        </div>
                    </div>
                    <div className="form-row">
                        <label className="form-custom-label col-5">Role</label>
                        <div className="form-group col-7">
                            <select {...register("role")}
                                className={`form-control ${errors.role ? 'is-invalid' : ''}`}
                                disabled={!isEditing || (selectedUser == null) || (selectedUser.role != "Administrator")}>
                                <option value="SuperSeiya">Super Seiya</option>
                                <option value="Admin">Administrator</option>
                                <option value="Controller">Controller</option>
                                <option value="Withdrawer">Withdrawer</option>
                            </select>
                            <div className="invalid-feedback">{errors.role?.message}</div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col" style={{ display: 'flex' }}>
                                {(mode == 'view') && <div className="btn-group" role="group">
                                    <button type="button" className="btn btn-primary form-user-button"
                                        onClick={handleEditClicked}>Edit</button>
                                </div>}
                                {(mode == 'add' || mode == 'edit') &&
                                    <div className="btn-group" role="group">
                                        <button type="submit" className="btn btn-success form-user-button"
                                            onClick={handleSaveClicked}>Save</button>
                                        <button type="button" className="btn btn-secondary form-user-button"
                                            onClick={handleCancelClicked}>Cancel</button>
                                    </div>
                                }
                            </div>
                            <div className="col">
                                {(mode == 'edit') &&
                                    <button type="button" onClick={() => reset()}
                                        className="btn btn-light form-user-button">Clear</button>
                                }
                            </div>
                        </div>
                    </div>
                </form>
            </div >
        </div >
    );
}

export default ProfilePage;