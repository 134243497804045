import { authHeader, fetchWrapper, handleResponse } from '../_helpers';
import { config, utils } from '../_helpers';

export const partService = {
    getAll,
    getById,
    addTransaction,
    getActiveParts,
    createNewPart,
    createNewPartFormData,
    updatePart,
    updatePartWithoutImage,
    deletePart,
    getTransactions,
    getBarcodes,
    getAllWithLastTransaction,
};

function getAll() {
    return fetchWrapper.get(`${config.apiUrl}/parts`);
}
function getAllWithLastTransaction() {
    return fetchWrapper.get(`${config.apiUrl}/parts/lasttrans`);
}

function getById(id) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${config.apiUrl}/parts/${id}`, requestOptions).then(handleResponse);
}

function addTransaction(data) {
    let url = `${config.apiUrl}/parts/transactions`;
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader(url)
        },
        body: JSON.stringify(data)
    };
    return fetch(url, requestOptions).then(handleResponse);
}

function getActiveParts(startDate, endDate) {
    return fetchWrapper.post(`${config.apiUrl}/parts/active-parts`, { "startDate": startDate, "endDate": endDate });
}

function createNewPart(part) {
    return fetchWrapper.postFormDataFromObjects(`${config.apiUrl}/parts`, part);
}
function createNewPartFormData(part) {
    return fetchWrapper.postFormData(`${config.apiUrl}/parts`, part);
}

function updatePart(part) {
    //return fetchWrapper.putFormDataFromObjects(`${config.apiUrl}/parts/${part.id}`, part);
    return fetchWrapper.postFormDataFromObjects(`${config.apiUrl}/parts/${part.id}`, part);
}
function updatePartWithoutImage(part) {
    //return fetchWrapper.putFormDataFromObjects(`${config.apiUrl}/parts/noimage/${part.id}`, part);
    return fetchWrapper.postFormDataFromObjects(`${config.apiUrl}/parts/noimage/${part.id}`, part);
}

function deletePart(part) {
    if (utils.IsDebugMode) {
        console.log("Delete Part", part);
    }
    //return fetchWrapper.put(`${config.apiUrl}/parts/deactivate`, { id: part.id });
    return fetchWrapper.post(`${config.apiUrl}/parts/deactivate`, { id: part.id });
}

function getTransactions(partId) {
    return fetchWrapper.get(`${config.apiUrl}/parts/${partId}/transactions`);
}

function getBarcodes(partId, totalBarcodes) {
    return fetchWrapper.getDownloadFile(`${config.apiUrl}/BarcodeGenerator/${partId},${totalBarcodes}`);
}