import React, { useState } from 'react'
import { useAsyncDebounce } from 'react-table'

import { InputBase, Paper, IconButton, Box } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';

export const TableGlobalFilter = ({ filter, setFilter }) => {
    const [value, setValue] = useState(filter)

    const onChange = useAsyncDebounce(value => {
        setFilter(value || undefined)
    }, 300)
    return (
        <Box direction="row">
            <Paper
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
            >
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    inputProps={{ 'aria-label': 'search user account' }}
                    onChange={(e) => {
                        setFilter(e.target.value);
                        onChange(e.target.value);
                    }}
                />
                <SearchIcon />
            </Paper>
        </Box>
    )
}