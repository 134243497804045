import React, {useState, useEffect, useRef} from 'react';
import { useForm } from "react-hook-form";
import { Backdrop, CircularProgress, Container } from "@mui/material";
import { authenticationService, seedService } from '../_services';

const DBInitPage = (props) => {
    const [executing, setExecuting] = useState(false);

    const form = useRef(null)
    // get functions to build form with useForm() hook
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        mode: "onBlur"
    });

    function onSubmit(data) {
        console.log(data);
        console.log(form.current);
        setExecuting(true);
        const startExec = +new Date();        
        
        seedService.initializeStorage(form.current).then(async (respData) => {
            setExecuting(false);
            var endExec = +new Date();
            console.log(`Server responds Seed (Status=${respData}) within ${endExec - startExec}`);           
        }).catch((error) => {
            setExecuting(false);
            var endExec = +new Date();
            console.log(`Server responds Seed [Error: ${error}] within ${endExec - startExec}`);
        });       
        
    }

    return (
        <Container fixed>
            <div className="card m-3">
                <h5 className="card-header">Set DB</h5>
                <div className="card-body">
                    <form ref={form} onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-row">
                            <input name="command" id="command" type="text" {...register('command', { required: true })} />
                            <button type="submit" className="btn btn-success form-user-button">Execute</button>
                        </div>
                    </form>
                </div>
            </div>
            {
                executing && <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={executing}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
        </Container>
    );    
}

export default DBInitPage;