import * as React from 'react';

import { Alert, Button, MenuItem, Select, Stack, TextField, InputLabel, FormControl, Grid } from '@mui/material';
import { exportService } from '../_services/dataExport';

export default function DataExportPage(props) {

    const categoryList = [
        { label: "ALL", id: 0 }, 
        { label: "Parts", id: 1}, { label: "Parts' Transactions", id: 2 }, { label: "Groups", id: 3 }, { label: "Operations", id: 4 }, { label: "Suppliers", id: 5 },
        { label: "Report Settings", id: 6}, { label: "Accounts", id: 7}
    ];

    const [selectedData, setSelectedData] = React.useState(0);
    const [emailAddress, setEmailAddress] = React.useState("");
    const [isDirty, setIsDirty] = React.useState(false);
    const [isValid, setIsValid] = React.useState(false);
    const [status, setStatus] = React.useState(0);
    const [message, setMessage] = React.useState("");

    const handleItemChange = (event) => {
        console.log(event.target.value);
        setSelectedData(event.target.value);   
        setMessage("");     
        setStatus(0);
    }
    const handleEmailChange = (event) => {
        console.log(event.target.value);
        setEmailAddress(event.target.value);
        const val = event.target.value;                
        
        if(ValidateEmail(val)) {
            setIsValid(true);              
        } else {
            setIsValid(false);              
        }        

        setMessage("");
        setStatus(0);
    }
    function ValidateEmail(inputEmail) {
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;      
        if (inputEmail.match(validRegex)) {
          return true;      
        } else {    
          return false;      
        }      
    }

    const handleClick = (event) => {
        setStatus(0);
        setMessage("");
        requestDataExport();
    }

    function requestDataExport() {    
        console.log(`Category=${selectedData}, Email=${emailAddress}`);
        exportService.requestDataExport(selectedData, emailAddress) 
            .then((respText) => {
                console.log(respText);
                if (respText.indexOf("FAIL:") > -1) {
                    setStatus(-1);                  
                } else {
                    setStatus(1);    
                }     
                setMessage(respText);         
                console.log(respText);   
            }).catch((error) => {
                console.log("error", error);
            });
    } 

    return (
        <Stack spacing={2} style={{ padding: '10px' }}>
            <h3 style={{margin: 5}}>Data Exports</h3>
            <div>
                <FormControl sx={{ m: 1, width: 300, marginTop: 3 }}>
                    <InputLabel id="select-label">Data</InputLabel>
                    <Select
                        label="Data"
                        id="data"
                        value={selectedData}   
                        onChange={handleItemChange}                             
                    >
                    {
                        categoryList.map( (kvp) => 
                            <MenuItem key={kvp.id} value={kvp.id}>{kvp.label}</MenuItem>)
                    }
                    </Select>
                </FormControl>  
            </div>
            <div>
                <FormControl sx={{ m: 1, width: 300, marginTop: 3 }}>
                    <TextField label="Email Address" variant="standard" type="email"
                    helperText="(Required)"
                    inputProps={{style: {textTransform: 'lowercase'}}}
                    error={isDirty && isValid === false}
                    value={emailAddress} 
                    onChange={handleEmailChange}
                    onBlur={() => setIsDirty(true)} />
                </FormControl>      
            </div>                
            <div>
                <FormControl sx={{ m: 1, width: 100, marginTop: 3 }}>
                    <Button variant='contained' onClick={handleClick} align='center'
                        disabled={!isValid}>Export</Button>
                </FormControl>
            </div>                
            {
                status === 1 && 
                <div style={{width: '50%', margin: 'auto', padding: 10}} >
                    <Alert severity="info">{message}</Alert>
                </div>
            }
            {
                status === -1 && 
                <div style={{width: '50%', margin: 'auto', padding: 10}} >
                    <Alert severity="error">{message}</Alert>
                </div>
            }
        </Stack>
    );
}