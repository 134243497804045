import { config } from './config';
import { authenticationService } from '../_services/authentication';
import { handleBreakpoints } from '@mui/system';
import { utils } from './utils';
export const fetchWrapper = {
    get,
    post,
    put,
    postFormData,
    postFormDataFromObjects,
    putFormDataFromObjects,
    delete: _delete,
    getDownloadFile
}

function authHeader(url) {
    // return auth header with jwt if user is logged in and request is to the api url
    const user = authenticationService.currentUserValue;
    const isLoggedIn = user && user.jwtToken;
    // if (user == null)
    //     console.log(`authHeader(${url}) => user=${user}, token=null`);
    // else
    //     console.log(`authHeader(${url}) => user=${user}, token=${user.jwtToken}`);
    const isApiUrl = url.startsWith(config.apiUrl);
    if (isLoggedIn && isApiUrl) {
        console.log('Bearer Token added in Authorization');
        return { Authorization: `Bearer ${user.jwtToken}` };
    } else {
        console.log(url);
        console.log('Is not Login or No API url');
        return {};
    }
}

function get(url) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(url)
    };
    if (utils.IsFetchDebugMode) {
        console.log("****FETCH: GET", "url", url);
    }
    return fetch(url, requestOptions).then(handleResponse);
}

function post(url, body) {
    const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json', ...authHeader(url) },
        body: JSON.stringify(body)
    };    
    if (utils.IsFetchDebugMode) {
        console.log("****FETCH: POST", "url", url, "requestOptions", requestOptions);
    }
    return fetch(url, requestOptions).then(handleResponse);
}

function put(url, body) {
    const requestOptions = {
        method: 'PUT',        
        headers: { 'Content-Type': 'application/json', ...authHeader(url) },
        body: JSON.stringify(body)
    };
    if (utils.IsFetchDebugMode) {
        console.log("****FETCH: PUT", "url", url, "requestOptions", requestOptions);
    }
    return fetch(url, requestOptions).then(handleResponse);
}
function putFormDataFromObjects(url, data) {
    var form_data = new FormData();
    var result = Object.keys(data).map((key) => {
        form_data.append(key, data[key]);
        console.log(`key:${key}, data:${data[key]}`);
    });
    const auth = authHeader(url);
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(url) },
        body: form_data
    };
    if (utils.IsFetchDebugMode) {
        console.log("****FETCH: PUT-FORM-DATA-FROM-OBJECTS", "url", url, "requestOptions", requestOptions);
    }
    return fetch(url, requestOptions).then(handleResponse);
}

function _delete(url) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(url)
    };
    if (utils.IsFetchDebugMode) {
        console.log("****FETCH: DELETE", "url", url, "requestOptions", requestOptions);
    }
    return fetch(url, requestOptions).then(handleResponse);
}

function postFormData(url, data) {
    let form_data = new FormData(data);
    const auth = authHeader(url);
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(url) },
        body: form_data
    };
    if (utils.IsFetchDebugMode) {
        console.log("****FETCH: POST-FORM-DATA", "url", url, "requestOptions", requestOptions);
    }
    return fetch(url, requestOptions).then(handleResponse);
}

function postFormDataFromObjects(url, data) {
    var form_data = new FormData();
    var result = Object.keys(data).map((key) => {
        form_data.append(key, data[key]);
    });
    const auth = authHeader(url);
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(url) },
        body: form_data
    };
    if (utils.IsFetchDebugMode) {
        console.log("****FETCH: POST-FORM-DATA-FROM-OBJECTS", "url", url, "requestOptions", requestOptions);
    }
    return fetch(url, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        if (utils.IsHttpResponseDebugMode) {
            console.log('handleResponse', text);
        }
        let data = "";
        if (isJsonString(text)) {  
            var jsonObj = JSON.parse(text);
            if (jsonObj && jsonObj.hasOwnProperty('errorCode')) {
                return Promise.reject(jsonObj.message);
            } else {
                data = text && JSON.parse(text);
            }
        }
        else {
            data = text;
            //alert('issue');
        }
        if (utils.IsHttpResponseDebugMode) {
            console.log("[[handleResponse]]: response.ok", response);
        }
        if (!response.ok) {
            if ([401, 403].includes(response.status) && authenticationService.currentUserValue) {
                //if ([401, 403].includes(response.status)) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                authenticationService.logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

function isJsonString(str) {
    try {
        JSON.parse(str);
        if (utils.IsFetchDebugMode) {
            console.log('Parse is OK');
        }
    } catch (e) {
        console.log('isJsonString', e);
        return false;
    }
    return true;
}

function getDownloadFile(url) {    
    const requestOptions = {
        method: 'GET',
        headers: authHeader(url)
    };
    return fetch(url, requestOptions);
}