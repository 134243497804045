import React, { useRef, useState } from "react";
import { FileUpload as FileUploadIcon, DeleteForever as DeleteFileIcon } from "@mui/icons-material";
import { Box, Button } from '@mui/material';
import PropTypes from 'prop-types';

import {
    FileUploadContainer,
    FormField,
    DragDropText,
    UploadFileBtn,
    FilePreviewContainer,
    ImagePreview,
    PreviewContainer,
    PreviewList,
    FileMetaData,
    RemoveFileIcon,
    InputLabel,
    FileSimpleContainer,
    PreviewCSVInfo,
    FileInfo,
    HeaderList,
    HeaderTitle
} from "./file-upload.styles";


const KILO_BYTES_PER_BYTE = 1000;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 500000;

const convertNestedObjectToArray = (nestedObj) =>
    Object.keys(nestedObj).map((key) => nestedObj[key]);

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

const FileUpload = ({
    label,
    updateFilesCb,
    maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
    ...otherProps
}) => {
    const fileInputField = useRef(null);
    const [files, setFiles] = useState({});
    const [columnHeaders, setColumnHeaders] = useState([]);

    const handleUploadBtnClick = () => {
        fileInputField.current.click();
    };

    const addNewFiles = (newFiles) => {
        for (let file of newFiles) {
            if (file.size < maxFileSizeInBytes) {
                if (!otherProps.multiple) {
                    return { file };
                }
                files[file.name] = file;
            }
        }
        return { ...files };
    };

    const callUpdateFilesCb = (files) => {
        const filesAsArray = convertNestedObjectToArray(files);
        updateFilesCb(filesAsArray);
    };

    const handleNewFileUpload = (e) => {
        const { files: newFiles } = e.target;
        if (newFiles.length) {
            let updatedFiles = addNewFiles(newFiles);
            setFiles(updatedFiles);
            callUpdateFilesCb(updatedFiles);
            Object.keys(files).map((fileName, index) => {
                console.log('handleNewFileUpload', fileName);
                console.log('--File', files[fileName]);
            });
            loadFileContent(updatedFiles.file);
        }
    };

    const removeFile = (fileName) => {
        console.log('removeFile', Object.keys(files));
        console.log(files[fileName]);
        delete files[fileName];
        setFiles({ ...files });
        callUpdateFilesCb({ ...files });
        loadFileContent(files.file);
    };

    function csvToArray(str, delimiter = ",") {
        // slice from start of text to the first \n index
        // use split to create an array from string by delimiter
        const headers = str.slice(0, str.indexOf("\r\n")).split(delimiter);

        // slice from \n index + 1 to the end of the text
        // use split to create an array of each csv value row
        //const rows = str.slice(str.indexOf("\n") + 1).split("\n");
        return headers;
    }
    const loadFileContent = (file) => {
        console.log('loadFileContent', file);
        if (FileReader && file) {
            var fr = new FileReader();
            fr.onload = function (event) {
                const text = event.target.result; // the CSV content as string
                const headers = csvToArray(text);
                const columns = [];
                let i = 0;
                headers.map(h => {
                    i++;
                    columns.push({ id: i, text: h });
                });
                setColumnHeaders(columns);
                console.log(columnHeaders);
            }
            fr.readAsText(file);
            //fr.readAsDataURL(files[0]);
        }
    }

    function Item(props) {
        const { sx, ...other } = props;
        return (
            <Box
                sx={{
                    p: 1,
                    m: 1,
                    bgcolor: 'grey.100',
                    color: 'grey.800',
                    border: '1px solid',
                    borderColor: 'grey.300',
                    borderRadius: 2,
                    fontSize: '0.875rem',
                    fontWeight: '700',
                    ...sx,
                }}
                {...other}
            />
        );
    }
    Item.propTypes = {
        sx: PropTypes.oneOfType([
            PropTypes.arrayOf(
                PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
            ),
            PropTypes.func,
            PropTypes.object,
        ]),
    };

    let csvFilePreview;
    let csvHeaderPreview;
    console.log('files && (files.length > 0)', files);
    if (files && files.file) {
        const uploadingFile = files.file;
        console.log('Uploading File: ', uploadingFile);
        csvFilePreview = (<FileInfo>
            <span>{uploadingFile.name}</span>
            <aside>
                <span>{convertBytesToKB(uploadingFile.size)} kb</span>
                <Button onClick={() => removeFile('file')}>
                    <DeleteFileIcon />
                </Button>
            </aside>
        </FileInfo>);
        csvHeaderPreview = (
            <HeaderList>
                <div style={{ width: '100%' }}>
                    <HeaderTitle>Columns</HeaderTitle>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            p: 1,
                            m: 1,
                            bgcolor: 'background.paper',
                            maxWidth: '100%',
                            borderRadius: 1,
                        }}
                    >
                        {columnHeaders.map(item => (
                            <Item key={item.id}>{item.text}</Item>
                        ))}
                    </Box>
                </div>
            </HeaderList>
        );
    }

    return (
        <>
            <FileUploadContainer>
                <InputLabel>{label}</InputLabel>
                <DragDropText>Please click below button to upload a file</DragDropText>
                <UploadFileBtn type="button" onClick={handleUploadBtnClick}>
                    <FileUploadIcon />
                    <span> Upload {otherProps.multiple ? "files" : "a file"}</span>
                </UploadFileBtn>
                <FormField
                    type="file"
                    ref={fileInputField}
                    onChange={handleNewFileUpload}
                    title=""
                    value=""
                    {...otherProps}
                />
            </FileUploadContainer>
            {(otherProps.accept !== '.csv')
                ?
                (<FilePreviewContainer>
                    <span>To Upload</span>
                    <PreviewList>
                        {Object.keys(files).map((fileName, index) => {
                            let file = files[fileName];
                            let isImageFile = file.type.split("/")[0] === "image";
                            return (
                                <PreviewContainer key={fileName}>
                                    <div>
                                        {isImageFile && (
                                            <ImagePreview
                                                src={URL.createObjectURL(file)}
                                                alt={`file preview ${index}`}
                                            />
                                        )}
                                        <FileMetaData isImageFile={isImageFile}>
                                            <span>{file.name}</span>
                                            <aside>
                                                <span>{convertBytesToKB(file.size)} kb</span>
                                                <DeleteFileIcon onClick={() => removeFile(fileName)} />
                                                {/*<RemoveFileIcon
                                                className="fas fa-trash-alt"
                                                onClick={() => removeFile(fileName)}
                                            />*/}
                                            </aside>
                                        </FileMetaData>
                                    </div>
                                </PreviewContainer>
                            );
                        })}
                    </PreviewList>
                </FilePreviewContainer>)
                :
                (<FileSimpleContainer>
                    <span>To Upload</span>
                    <PreviewCSVInfo>
                        {csvFilePreview}
                        {csvHeaderPreview}
                    </PreviewCSVInfo>
                </FileSimpleContainer>)
            }
        </>
    );
};

export default FileUpload;
/*https://dev.to/chandrapantachhetri/responsive-react-file-upload-component-with-drag-and-drop-4ef8*/