import { authenticationService } from '../_services';

export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        console.log('handleResponse=>Response', response);
        console.log('handleResponse: response.ok=' + response.ok + ', ' + text);
        if (!response.ok) {
            console.log('response is not OK');
            if ([401, 403].indexOf(response.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                console.log('Auto logout');
                //authenticationService.logout();
                //window.location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}