import React, { useState } from 'react';
import FileUpload from '../components/FileUpload';
import { Alert, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Button } from '@mui/material';

const DataImportPage = () => {
    const [uploadFile, setUploadFile] = useState(null);
    const [open, setOpen] = useState(false);
    const [result, setResult] = useState('CANCEL');
    const handleSubmit = (event) => {
        event.preventDefault();
        //logic to create a new user...

        // Create an object of formData
        const formData = new FormData();

        alert('X');
        // Update the formData object
        formData.append('File', uploadFile);

        fetch(
            'http://localhost:5000/api/Data',
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                console.log('Success:', result);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };
    const handleFileUpdated = (files) => {
        if (files.length > 0) {
            setUploadFile(files[0]);
        } else {
            setUploadFile(null);
        }
    };
    const handleConfirm = () => {
        setOpen(false);
        setResult('CONFIRM');
        console.log('Close with Confirm');
    };
    const handleCancel = () => {
        setOpen(false);
        setResult('CANCEL');
        console.log('Close with CANCEL');
    };
    return (
        <div>
            <form onSubmit={handleSubmit} enctype="multipart/form-data">
                <FileUpload
                    accept=".csv"
                    label="CSV File"
                    updateFilesCb={handleFileUpdated}
                />
                <button type="submit">Upload Data</button>
            </form>
            <div>
                <Dialog
                    open={open}
                    onClose={handleCancel}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Import Data to Server
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Please ensure that you SAVED your data in Database. Your uploading CSV data will replace all data in Database!
                        </DialogContentText>
                        <Alert severity="warning">"Once data is replaced, you cannot rollback"</Alert>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleConfirm}>Continue</Button>
                        <Button onClick={handleCancel} autoFocus>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
}

export default DataImportPage;