import React, { useMemo, useState, useEffect } from 'react';
import { useTable, useSortBy, usePagination, useGlobalFilter, useFilters, useBlockLayout, useResizeColumns } from 'react-table';
import { authenticationService } from '../../_services';

import getColumnsInfo from './PartColumns'
import { TableColumnFilter } from './TableColumnFilter'

import { Select, MenuItem, Stack, Button, Grid, FormControl, InputLabel, FormLabel } from '@mui/material'
import { AddCircle, FileDownload } from '@mui/icons-material';
import styled from 'styled-components'
import { FoundationOutlined } from '@mui/icons-material';
import { useSticky } from 'react-table-sticky';
import { TableGlobalFilter } from './TableGlobalFilter';
import { PartVisibleColumnsMultipleSelectCheckbox } from './PartVisibleColumnsMultipleSelectCheckbox';

import { TableStyles } from './TableStyles';

import { createTheme } from '@mui/material/styles';
import { Role } from '../../_helpers';

import { CSVLink, CSVDownload } from 'react-csv';
import { utils } from '../../_helpers';

function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}
const theme = createTheme();
const mainColor = hexToRgb(theme.palette.primary.main);

const Styles = styled.div`
    padding: 1rem;
    display: block;
    max-width: 100%;
    overflow: auto;

    .tableWrap {
        display: block;
        max-width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        border-bottom: 1px solid black;
    }

    table {
        width: 100%;
        border-spacing: 0;
        border: 1px solid black;

        tr {
            :last-child {
                td {
                    border-bottom: 0;
                }
            }
        }

        th {
            backgroundColor: red;
        }

        th,
        td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid #888888;
            border-right: 1px solid #888888;            
            white-space: nowrap;
            backgroundColor: red;
            /* The secret sauce */
            /* Each cell should grow equally */
            width: 1%;
            /* But "collapsed" cells should be as small as possible */
            &.collapse {
                width: 0.0000000001%;
            }
            
            :last-child {
                border-right: 0;
            }
        }
    }
`

const Styles2 = styled.div`
    display: block;
    max-width: 100%;

/*
    .tableWrap {
        display: block;
        max-width: 100%;
        overflow-x: scroll;
        overflow-y: scroll;
        border-bottom: 1px solid black;
    }
*/
    .table {
        display: block;
        width: 100%;
        height: 600px;
        border-spacing: 0;
        border: 1px solid #ddd;

        .tr {
            :last-child {
                .td {
                    border-bottom: 0;
                }
            }
            :nth-child(even) {
                background-color: #f2f2f2;
            }
        },
        .tr {
            &.header {
                background-color: #1976d2;
                color: white;
            }
        }

        .th,
        .td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid #888888;
            border-right: 1px solid #888888;
            overflow: hidden;
            white-space: nowrap;
            /* The secret sauce */
            /* Each cell should grow equally */
            width: 1%;
            /* But "collapsed" cells should be as small as possible */
            &.collapse {
                width: 0.0000000001%;
            }
            
            :last-child {
                border-right: 0;
            }

            ${'' /* In this example we use an absolutely position resizer,
            so this is required. */}
            position: relative;
            .resizer {
                display: inline-block;
                background: #325ca8;
                width: 5px;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                transform: translateX(50%);
                z-index: 1;
                ${'' /* prevents from scrolling while dragging on touch devices */}
                touch-action:none;

                &.isResizing {
                    background: #22f;
                }
            }            
        }

        &.sticky {
            overflow: scroll;
            .header,
            .footer {
                position: sticky;
                z-index: 1;
                width: fit-content;
            }

            .header {
                top: 0;
                box-shadow: 0px 3px 3px #ccc;
            }

            .footer {
                bottom: 0;
                box-shadow: 0px -3px 3px #ccc;
            }

            .body {
                position: relative;
                z-index: 0;
            }

            [data-sticky-td] {
                position: sticky;
            }
            [data-sticky-last-left-td] {
                box-shadow: 2px 0px 3px #ccc;
            }
            [data-sticky-first-right-td] {
                box-shadow: -2px 0xp 3px #ccc;
            }
        }
    }
`
let rowClickReference;

const rowClickedHandler = (rowData) => {
    //console.log("ROW", rowData);
    rowClickReference(rowData);
}

function PartsTable(props) {
    let activeUser = authenticationService.currentUserValue;
    let roleName = "guest";
    if (activeUser) {
        roleName = "operator"
        if (activeUser.role != "Withdrawer") {
            roleName = "admin";
        }
    }
    let partData = props.data.records === undefined ? [] : props.data.records;
    if (utils.IsDebugMode) {
        if (partData.length > 0)
            console.log('Sample Part', partData[0]);
    }

    const [updateTime, setUpdateTime] = useState(props.data.date);
    if (props.data.date > updateTime) {
        setUpdateTime(props.data.date);
    }

    const partColumns = getColumnsInfo(rowClickedHandler);
    
    /* Filter data based on selected Part Type  */
    const [partTypeFilter, setPartTypeFilter] = useState(() => {
        return 0;
    })

    const filterPartDataByType = (typeValue, sourceData) => {
        let filteredData = [];
        if (typeValue === 1) {
            filteredData = sourceData.filter((part) => {
                return part['category'] === 1;
            });
        } else if (typeValue === 2) {
            filteredData = sourceData.filter((part) => {
                return part['category'] === 2;
            });
        } else {
            filteredData = [...sourceData];
        }
        return filteredData;
    }
    const partFilteredData = filterPartDataByType(partTypeFilter, partData);
    const handlePartTypeFilterChanged = (event) => {
        setPartTypeFilter(prevValue => prevValue = event.target.value);
        setUpdateTime(Date.now());
    }

    const columns = useMemo(() => partColumns, []);
    const defaultColumn = useMemo(() => {
        return {
            Filter: TableColumnFilter
        }
    }, [])

    const rows = useMemo(() => partFilteredData, [updateTime]);
    const tableInstance = useTable({
        columns: columns,
        data: rows,
        initialState: { pageIndex: 0, pageSize: 10 },
    }, useFilters, useGlobalFilter, useResizeColumns, useSortBy, usePagination, useBlockLayout, useSticky);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        setGlobalFilter,
        allColumns        
    } = tableInstance;

    const { globalFilter, pageIndex, pageSize } = state

    if (utils.IsDebugMode) {
        console.log("Parts Table, Role*********************", roleName);
    }

    let mappedHeaders = {};
    if (roleName == "admin") {
        mappedHeaders = {
            "Part Number" : "id",
            "Description" : "title",
            "Group" : "groupName",
            "Operation" : "operationName",
            "Product" : "product",
            "Supplier"  : "supplierName",
            "Lead Time (Weeks)"  : "leadTime",
            "Safety Level" : "safetyLevel",
            "Onhand" : "onhand",
            "Status" : "status",
            "Type" : "category",
            "Last Transaction" : "formattedTransactionDate"
        };
    } else {
        mappedHeaders = {
            "Part Number" : "id",
            "Description" : "title",
            "Group" : "groupName",
            "Operation" : "operationName",
            "Product" : "product",
            "Supplier"  : "supplierName",
            "Lead Time (Weeks)"  : "leadTime",
            "Safety Level" : "safetyLevel",
            "Onhand" : "onhand",
            "Status" : "status",
            "Type" : "category"
        };        
    }

    if (utils.IsDebugMode) {
        console.log("Mapped Headers", mappedHeaders);
    }
 
    const [displayedColumns, setDisplayedColumns] = useState([        
        { label: "Part Number", key: "id" },
        { label: "Description", key: "title" },
        { label: "Group", key: "groupName" },
        { label: "Operation", key: "operationName" },
        { label: "Product", key: "product" },
        { label: "Supplier", key: "supplierName" },
        { label: "Lead Time (Weeks)", key: "leadTime" },
        { label: "Safety Level", key: "safetyLevel" },
        { label: "Onhand", key: "onhand" },
        { label: "Status", key: "status" },
        { label: "Type", key: "category" },
        { label: "Last Transaction", key: "formattedTransactionDate" }                   
    ]);

    const partTableColumnSelectChanged = (visibleColumnNames) => { 
        let csvHeaders = [
            { label: "Part Number", key: "id" },
            { label: "Description", key: "title" }
        ];
        if (utils.IsDeBugMode) {
            console.log("Table Column Selection Changed");  
            console.log("-- Visible Columns", visibleColumnNames);
        }
        visibleColumnNames.forEach((col) => {
            if (utils.IsDeBugMode) {
                console.log("-", col);  
            }          
            if (mappedHeaders.hasOwnProperty(col)){
                csvHeaders.push({'label': col, 'key':mappedHeaders[col]});                    
            }
        });
  
      
        if (utils.IsDeBugMode) {
            console.log('Final CSV Header Map', csvHeaders);
        }
        setDisplayedColumns(csvHeaders);
        
    }

    const getCSVMappedHeaders = () => {
        if (utils.IsDeBugMode) {
            console.log('Get CSV Headers', displayedColumns);        
        }
        return displayedColumns;
    }
    //console.log("RECORDS", rows);

    const buttonClicked = (row) => {
        //console.log(row);
        props.partNoClicked(row);
    }

    const rowStyle = {
        padding: "0px",
        border: "3px dotted gray"
    };

    const panelMarginStyle = {
        marginTop: "15px",
        marginBottom: "15px"
    }

    useEffect(() => {
        //console.log("PartsTable.useEffect", props.rowClicked);
        rowClickReference = props.rowClicked;
        return function cleanUp() {
            rowClickReference = null;
        };
    });

    return (
        <Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 3 }}
                justifyContent="center"
                alignItems="center"
                style={panelMarginStyle}>
                {(activeUser && activeUser.role !== Role.Withdrawer) &&
                    <Button variant="contained" startIcon={<AddCircle />} size="medium" onClick={() => props.addNewPartClicked()}
                    >New Part</Button>
                }
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="part-type-select-label">Part Type</InputLabel>
                    <Select
                        size="small"
                        labelId="part-type-select-label"
                        id="part-type-select"
                        autoWidth
                        value={partTypeFilter}
                        onChange={handlePartTypeFilterChanged}
                    >
                        <MenuItem value={0}>All</MenuItem>
                        <MenuItem value={1}>Comsumable</MenuItem>
                        <MenuItem value={2}>Spare Part</MenuItem>
                    </Select>
                </FormControl>
                <PartVisibleColumnsMultipleSelectCheckbox columns={allColumns}  onColumnSelectionChanged={partTableColumnSelectChanged} />
                <TableGlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            </Stack>
            <Stack>
                <TableStyles style={{width: '100%'}}>
                    <div {...getTableProps()} className="table sticky">
                        <div className="header">
                            {// Loop over the header rows
                                headerGroups.map(headerGroup => (
                                    // Apply the header row props
                                    <div {...headerGroup.getHeaderGroupProps()} className="tr header">
                                        {// Loop over the headers in each row
                                            headerGroup.headers.map(column => (
                                                // Apply the header cell props
                                                <div {...column.getHeaderProps()} className="th">
                                                    <div {...column.getSortByToggleProps()}>
                                                        {// Render the header
                                                            column.render('Header')
                                                        }
                                                        <span>
                                                            {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                                                        </span>{' '}
                                                    </div>
                                                    {/* Use column.getResizerProps to hook up the events correctly */}
                                                    <div
                                                        {...column.getResizerProps()}
                                                        className={`resizer ${column.isResizing ? 'isResizing' : ''
                                                            }`}
                                                    />
                                                </div>
                                            ))}
                                    </div>
                                ))}
                        </div>
                        {/* Apply the table body props */}
                        <div {...getTableBodyProps()} className="body">
                            {// Loop over the table rows
                                page.map((row, i) => {
                                    // Prepare the row for display
                                    prepareRow(row)
                                    //console.log(row[0])
                                    return (
                                        // Apply the row props
                                        <div {...row.getRowProps()} className="tr">
                                            {// Loop over the rows cells
                                                row.cells.map(cell => {
                                                    // Apply the cell props
                                                    return (
                                                        <div {...cell.getCellProps()} className="td cell">
                                                            {// Render the cell contents
                                                                cell.render('Cell')}
                                                        </div>
                                                    )
                                                })}
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                </TableStyles>

                {activeUser === null ?
                    (<Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={4} md={4}>
                            <Stack direction="row">
                                <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                    {'<<'}
                                </Button>{' '}
                                <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                                    {'<'}
                                </Button>{' '}
                                <Button onClick={() => nextPage()} disabled={!canNextPage}>
                                    {'>'}
                                </Button>{' '}
                                <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                    {'>>'}
                                </Button>{' '}
                            </Stack>
                        </Grid>
                        <Grid item xs={4} md={4}>
                            Page{' '}
                            <strong>
                                {pageIndex + 1}
                            </strong>{' '}
                            of{' '}
                            <strong>
                                {pageOptions.length}
                            </strong>{' '}
                        </Grid>
                        <Grid item xs={4} md={4} alignContent="flex-end">
                            <Stack justifyContent="flex-end" direction="row">
                                <span>
                                    Go to page:{' '}
                                    <input
                                        type="number"
                                        defaultValue={pageIndex + 1}
                                        onChange={e => {
                                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                                            gotoPage(page)
                                        }}
                                        style={{ width: '80px' }}
                                    />
                                </span>
                            </Stack>{' '}
                        </Grid>
                    </Grid>)
                    :
                    (<Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={3} md={3}>
                            <Stack direction="row">
                                <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                    {'<<'}
                                </Button>{' '}
                                <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                                    {'<'}
                                </Button>{' '}
                                <Button onClick={() => nextPage()} disabled={!canNextPage}>
                                    {'>'}
                                </Button>{' '}
                                <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                    {'>>'}
                                </Button>{' '}
                            </Stack>
                        </Grid>
                        <Grid item xs={4} md={4}>
                            Page{' '}
                            <strong>
                                {pageIndex + 1}
                            </strong>{' '}
                            of{' '}
                            <strong>
                                {pageOptions.length}
                            </strong>{' '}
                        </Grid>
                        <Grid item xs={3} md={3} alignContent="flex-end">
                            <Stack justifyContent="flex-end" direction="row">
                                <span>
                                    Go to page:{' '}
                                    <input
                                        type="number"
                                        defaultValue={pageIndex + 1}
                                        onChange={e => {
                                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                                            gotoPage(page)
                                        }}
                                        style={{ width: '80px' }}
                                    />
                                </span>
                            </Stack>{' '}
                        </Grid>

                        <Grid item xs={2} md={2}>
                            <CSVLink data={rows} headers={displayedColumns} size="medium" className="btn btn-primary" 
                                filename={'export-parts-' + Date.now()} ><FileDownload />Export</CSVLink>
                        </Grid>
                    </Grid>)
                }
            </Stack>
        </Stack >
    );
}

export default PartsTable;