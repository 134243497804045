import { authHeader, handleResponse } from '../_helpers';
import { config, fetchWrapper } from '../_helpers';

export const userService = {
    getAll,
    getById,
    addUser,
    updateUser,
    deleteUser,
    resetPassword,
    register
};

const dataRows = [
    {
        id: 1,
        username: "XXXXXX",
        firstName: "Siusan",
        lastName: "Switland",
        email: "sswitland0@twitter.com",
        role: "admin"
    }, {
        id: 2,
        username: "EN0002",
        firstName: "Urbain",
        lastName: null,
        email: "ucapron1@deliciousdays.com",
        role: "withdrawer"
    }, {
        id: 3,
        username: "EN0003",
        firstName: "Shelley",
        lastName: "Wissby",
        email: "swissby2@diigo.com",
        role: "withdrawer"
    }, {
        id: 4,
        username: "EN0004",
        firstName: "Jandy",
        lastName: "Castiello",
        email: "jcastiello3@irs.gov",
        role: "controller"
    }, {
        id: 5,
        username: "EN0005",
        firstName: "Bartel",
        lastName: "Vanini",
        email: "bvanini4@yellowpages.com",
        role: "withdrawer"
    }, {
        id: 6,
        username: "EN0006",
        firstName: "Bendix",
        lastName: "Klamman",
        email: "bklamman5@arizona.edu",
        role: "withdrawer"
    }, {
        id: 7,
        username: "EN0007",
        firstName: "Tamra",
        lastName: "Ponte",
        email: "tponte6@usatoday.com",
        role: "withdrawer"
    }, {
        id: 8,
        username: "EN0008",
        firstName: "Farrell",
        lastName: "Coney",
        email: "fconey7@123-reg.co.uk",
        role: "controller"
    }, {
        id: 9,
        username: "EN0009",
        firstName: "Carlee",
        lastName: "Conlon",
        email: "cconlon8@sohu.com",
        role: "controller"
    }, {
        id: 10,
        username: "EN0010",
        firstName: "Brena",
        lastName: "Whates",
        email: "bwhates9@phoca.cz",
        role: "admin"
    }
]

function getAll() {
    return fetchWrapper.get(`${config.apiUrl}/account`);
}

function getById(id) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

function addUser(user) {
    return fetchWrapper.postFormData(`${config.apiUrl}/account`, user);
}
function updateUser(user) {
    return fetchWrapper.postFormData(`${config.apiUrl}/account/update`, user);
}
function deleteUser(user) {
    return fetchWrapper.postFormData(`${config.apiUrl}/account/delete`, user);
}
function resetPassword(user) {
    return fetchWrapper.postFormData(`${config.apiUrl}/account/reset-password`, user);
}
function register(user) {
    return fetchWrapper.post(`${config.apiUrl}/api/Account/register`, user);
}