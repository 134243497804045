import * as React from 'react';
import { Backdrop, Button, CircularProgress, Menu, MenuItem, Select, Stack, TextField } from '@mui/material';
import { FileDownload } from '@mui/icons-material';
import { useTable } from 'react-table';
import { fetchWrapper } from '../_helpers';
import { partService } from '../_services';
import 'date-fns';
import { ThemeConsumer } from 'styled-components';
import HomeTable from '../components/Home/HomeTable';
import ActivePartsTable from '../components/Home/ActivePartsTable';
import { authenticationService } from '../_services';
import { CSVLink } from 'react-csv';
import { utils } from '../_helpers';
/*
const useFetch = (query) => {
    const [status, setStatus] = useState('idle');
    const [data, setData] = useState([]);

    useEffect(() => {
        if (!query) return;

        const fetchData = async () => {
            setStatus('fetching');
            fetchWrapper()
            const response = await fetch(
                `https://hn.algolia.com/api/v1/search?query=${query}`
            );
            const data = await response.json();
            setData(data.hits);
            setStatus('fetched');
        };

        fetchData();
    }, [query]);

    return { status, data };
};

*/

const HomePage = (props) => {
    let rightNow = new Date();
    let endDateValue = rightNow.toISOString().slice(0, 10);
    let beforeNow = new Date(rightNow.getTime() - 604800000);
    let startDateValue = beforeNow.toISOString().slice(0, 10);
    const [selectedEndDate, setSelectedEndDate] = React.useState(endDateValue);
    const [selectedStartDate, setSelectedStartDate] = React.useState(startDateValue);
    const [dataSource, setDataSource] = React.useState([]);
    const [firstQuery, setFirstQuery] = React.useState(false);

    const [beingQuery, setBeingQuery] = React.useState(false);

    const [downloadFileName, setDownloadFileName] = React.useState(0);

    const eventCallbackHandler = props.eventCallback;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);

    let activeUser = authenticationService.currentUserValue;
    let roleName = "guest";
    if (activeUser) {
        roleName = "operator"
        if (activeUser.role != "Withdrawer") {
            roleName = "admin";
        }
    }

    const handleDateChange = (event) => {
        let source = event.target.id;
        let newDate = event.target.value;

        let dateValue;
        let refDateValue;
        if (source == "startDate") {
            dateValue = new Date(newDate).toISOString().slice(0, 10);
            refDateValue = new Date(selectedEndDate).toISOString().slice(0, 10);
            if (dateValue > refDateValue) {
                setSelectedStartDate(selectedEndDate);
            } else {
                setSelectedStartDate(newDate);
            }
            queryData(dateValue, refDateValue);
        } else if (source == "endDate") {
            dateValue = new Date(newDate).toISOString().slice(0, 10);
            refDateValue = new Date(selectedStartDate).toISOString().slice(0, 10);
            if (dateValue < refDateValue) {
                setSelectedEndDate(selectedStartDate);
            } else {
                setSelectedEndDate(newDate);
            }
            queryData(refDateValue, dateValue);
        }
    };

    // const memorizedResult = React.useMemo(() => {
    //     partService.getActiveParts(selectedStartDate, selectedEndDate)
    //         .then((data) => {
    //             console.log(data);
    //         });
    // }, [selectedStartDate, selectedEndDate]);

    // console.log("Start Date", startDateValue, selectedStartDate);
    // console.log("End Date", endDateValue, selectedEndDate);

    // if (!firstQuery) {
    //     console.log("First Query");
    //     setFirstQuery(true);
    //     queryData(selectedStartDate, selectedEndDate);
    // }

    function queryData(startDate, endDate) {
        if (utils.IsDebugMode) {
            console.log("HomePage, Do Query Data");
        }
        setBeingQuery(true);
        eventCallbackHandler({ name: 'beforeQuery', params: [startDate, endDate] })
        partService.getActiveParts(startDate, endDate)
            .then((data) => {
                setBeingQuery(false);
                setDataSource(prevValue => prevValue = data);
                if (utils.IsDebugMode) {
                    console.log("Query Data", data);
                }
                prepareDataSource(data);
                setDownloadFileName(Date.now());
                eventCallbackHandler({ name: 'afterQuery', params: [startDate, endDate] })
            }).catch((error) => {
                setBeingQuery(false);
                console.log("error", error);
            });
    }

    const [partsData, setPartsData] = React.useState([]);
    const [transData, setTransData] = React.useState([]);
    function prepareDataSource(data) {
        if (utils.IsDebugMode) {
            console.log("Prepare data source", data);
        }
        const parts = data.map((item) => {
            return {
                'Part Number': item.id,
                'Description': item.title,
                'Product': item.product,
                'Group': item.groupName,
                'Operation': item.operationName,
                'Supplier': item.supplierName,
                'Store Location': item.storeLocation,
                'Price': item.price,
                'Lead Time (Weeks)': item.leadTime,
                'Onhand': item.onhand,
                'Safety Level': item.safetyLevel,
                'Last Transaction': new Date(item.lastTransactionDate).toLocaleString().replace(',', '')
            }
        });
        const trans = [];
        data.map((p) => {
            p.transactions.map((t) => {
                utils.IsDebugMode && console.log("T", t);
                trans.push({
                    'Part Number': p.id,
                    'Description': p.title,
                    'Product': p.product,
                    'Group': p.groupName,
                    'Operation': p.operationName,
                    'Supplier': p.supplierName,
                    'Store Location': p.storeLocation,
                    'Price': p.price,
                    'Lead Time (Weeks)': p.leadTime,
                    'Safety Level': p.safetyLevel,
                    'Transaction': t.category === 0 ? 'Supply' : 'Consume',
                    'Transaction Date': new Date(t.updated).toLocaleString().replace(',', ''),
                    'Onhand': t.onhand,
                    'Quantity': t.quantity,
                    'Note': t.note,
                    'Creator': t.creator === null ? '' : t.creator.firstName
                });
            })
        });
        setPartsData(prevValue => prevValue = parts);
        setTransData(prevValue => prevValue = trans);
        if (utils.IsDebugMode) {
            console.log("Parts", parts);
            console.log("Transactions", trans);
        }
    }

    React.useEffect(() => {
        queryData(selectedStartDate, selectedEndDate);
        //authenticationService.refreshToken();
    }, []);

    function handleMenuOpenClick(e) {
        setAnchorEl(e.currentTarget);
    }
    function handleMenuCloseClick(e) {
        setAnchorEl(e.null);
    }
    function handleMenuItemClick(e) {
        setAnchorEl(e.null);
    }

    return (
        <>
            <Stack spacing={2} style={{ padding: '10px' }}>
                <h3>List of Active Parts</h3>
                <Stack direction="row" spacing={2} justifyContent="center">
                    <TextField
                        id="startDate"
                        label="Start Date"
                        type="date"
                        value={selectedStartDate}
                        onChange={handleDateChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        id="endDate"
                        label="End Date"
                        type="date"
                        value={selectedEndDate}
                        onChange={handleDateChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    {
                        roleName != 'guest' &&
                        (<>
                            <Button
                                id="basic-button"
                                onClick={handleMenuOpenClick}
                                variant="outlined"
                                startIcon={<FileDownload />}
                            >
                                Export
                            </Button>
                            <Menu
                                id="export-menu"
                                anchorEl={anchorEl}
                                open={openMenu}
                                onClose={handleMenuCloseClick}>
                                <MenuItem onClick={handleMenuItemClick}>
                                    <CSVLink data={partsData} filename={downloadFileName + "-parts.csv"} size="medium">Export Parts</CSVLink>
                                </MenuItem>
                                <MenuItem onClick={handleMenuItemClick}>
                                    <CSVLink data={transData} filename={downloadFileName + "-trans.csv"} size="medium">Export Transactions</CSVLink>
                                </MenuItem>
                            </Menu>
                        </>)
                    }
                </Stack>
            </Stack>
            {false && (dataSource.length > 0) && <HomeTable data={dataSource} />}
            {false && (dataSource.length === 0) && <HomeTable data={[]} />}
            {(dataSource.length > 0) && <ActivePartsTable data={dataSource} />}
            {(dataSource.length === 0) && <ActivePartsTable data={[]} />}
            {
                beingQuery && <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={beingQuery}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
        </>
    )
}

export default HomePage;