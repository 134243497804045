import { authHeader, handleResponse } from '../_helpers';
import { config, fetchWrapper } from '../_helpers';

export const seedService = {
    initializeStorage,
    initializeData,
    getBackendVersion,
};

function initializeStorage(cmd) {
    console.log("initializeStorage = " + cmd);
    return fetchWrapper.postFormData(`${config.apiUrl}/seed`, cmd);
}
function initializeData() {

}

function getBackendVersion() {
    return fetchWrapper.get(`${config.apiUrl}/info`);
}
