import { authenticationService } from '../_services/authentication';

export function authHeader() {
    // return authorization header with jwt token
    const currentUser = authenticationService.currentUserValue;
    console.log("auth-header: currentUser", currentUser);

    if (currentUser && currentUser.jwtToken) {
        console.log('auth');
        return { Authorization: `Bearer ${currentUser.jwtToken}` };
    } else {
        return {};
    }
}