import React, { useState, useEffect, useReducer, useRef } from 'react';
import {
    Modal, Tab, Tabs, Typography, Autocomplete, TextField, Box,
    Stack, Card, Switch, Grid, Radio, RadioGroup, FormControlLabel, Button
} from '@mui/material';
import Barcode from 'react-barcode';
import { CSVLink, CSVDownload } from 'react-csv';
import { AddCircle, FileDownload } from '@mui/icons-material';
import BarcodeWindow from '../Parts/BarcodeWindow';
import { partService } from '../../_services';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function BarcodeForm(props) {

    const [modalShow, setModalShow] = useState(false);
    const [showBarcodeWindow, setShowBarcodeWindow] = useState(false);
    const [totalBarcodes, setTotalBarcodes] = useState(1);
    const [downloading, setDownloading] = useState(false);

    const handleClose = () => {
        setModalShow(prevValue => prevValue = false);
        console.log('Close');
        props.closed();
    }
    const handleNumberFieldChanged = e => {
        setTotalBarcodes(prevValue => prevValue = e.target.value);
    }

    const handleToggleBarcodePage = e => {
        console.log('Show Barcode = ' + showBarcodeWindow);
        setShowBarcodeWindow(prevValue => prevValue = !showBarcodeWindow);
    }
    const handleCloseWindowPortal = e =>{
        setShowBarcodeWindow(prevValue => prevValue = false);
    }

    const handleGetBarcodeExcel = e =>{
        e.preventDefault();

        setDownloading(prevValue => prevValue = true);
        let id = props.data;
        let num = e.target.elements.barcodes.value;
        let fileName = id;
        partService.getBarcodes(id, num)
            .then((response) => {
                response.headers.forEach((s, k) =>{
                    console.log(s, k);
                });
                let disposition = response.headers.get('content-disposition');
                console.log(disposition);
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    var attr = disposition.split(';');
                    if (attr.length > 1) {
                        var equalInx = attr[1].indexOf('=');
                        if (equalInx > -1) {
                            fileName = attr[1].substring(equalInx + 1);
                            console.log('File name', fileName);
                        }
                    }
                }
                return response.blob();
            })
            .then((blob) => {
                setDownloading(prevValue => prevValue = false);
                // 2. Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                console.log('download file', fileName);

                link.href = url;
                link.download = fileName;
                //link.setAttribute('download', `sample.${this.state.file}`);
                // 3. Append to html page
                document.body.appendChild(link);
                // 4. Force download
                link.click();
                // 5. Clean up and remove the link
                link.parentNode.removeChild(link);            
            })
            .catch((error) => {
                setDownloading(prevValue => prevValue = false);
                alert('Fail to download with this error:' + error);
            });
    }

    if ((modalShow != props.show) && props.show) {
        setModalShow(prevValue => prevValue = true);
    }
    
    return (
        <Modal
            open={modalShow}
            >
            <Box sx={{ ...style, width: 480, height: 330, borderRadius: 3 }}>  
                <Box sx={{ width: '100%', margin: '10px' }}>
                    <form onSubmit={handleGetBarcodeExcel}>
                        <div className="form-row" style={{margin: '5px'}}>
                            <Barcode value={props.data} />;
                        </div>
                        <div className="form-row">
                            <label className="form-custom-label col-5 align-self-center">Number of Barcodes</label>
                            <input name="barcodes" type="number" style={{width: "80px"}}
                            className="col-3 align-self-center"
                                min="1"
                                max="48"
                                value={totalBarcodes}
                                onChange={handleNumberFieldChanged}                                
                            />
                            <button type="submit" disabled={downloading} className="btn btn-primary col-4 align-self-center"
                                style={{margin: "5px"}}>
                            {(downloading) ? 'Downloading...' : 'Download'}</button>  
                        </div>     
                    </form>               
                </Box>  
                <div className='part-withdrawer-form-line' />
                <Stack direction="row" justifyContent="space-between" style={{ marginTop: 10 }}>
                    <Button onClick={handleClose} align='center'>Close</Button>
                </Stack>
            </Box>
        </Modal>   
    );         
}