import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import ResponsiveAppBar from './components/ResponsiveAppBar';
import {
  HomePage, AccountPage, LoginPage, DataImportPage, PartsPage, MasterDataPage, ProfilePage,
  PartsPage2, ParentPage, RegisterPage, ReportConfigurationPage, ReportSettingPage, DBInitPage, AboutPage,
  EventLogReportPage, DataExportPage
} from './pages';
import { authenticationService } from '../src/_services';
import { Role } from './_helpers/mock-backend/role';

function App() {
  const handleItemCallback = (itemName) => {
    console.log(itemName);
    //let comp = (<SignInPage />);
    //setActivePage(comp);
  }

  const [currentUser, setCurrentUser] = useState(null);
  const [userRole, setUserRole] = useState("guest");
  const [subscriber, setSubscriber] = useState(null);

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    //console.log("App => User subscribed: " + (subscriber ? 'yes' : 'no'));
    if (subscriber != null) {
      subscriber.unsubscribe();
      setSubscriber(null);
    }

    const newSubscriber = authenticationService.currentUser.subscribe(x => {
      setCurrentUser(x);
      if (x == null) {              
        authenticationService.fetchToken()
          .then(data => {            
            //console.log("App.js Data=", data);
          })
          .catch(error => {
            //console.log("App.js Error=", error);
            //console.log('App => User role is Guest');
            setUserRole("guest");
          });
          
          //setUserRole("guest");
      } else {
        //console.log('App => User role is ' + x.role);
        setUserRole(x.role);
      }
    });
    setSubscriber(newSubscriber);
  }, []);

  function homeEventCallback(e) {
    console.log(e);
  }

  function pageStatusHandler(pageName, pageMode) {
    if (pageMode == "editing") {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }
  const SHOW_REPORT = true;

  return (
    <div className="App">
      <BrowserRouter>
        <ResponsiveAppBar itemHandler={handleItemCallback} role={userRole} isEditing={isEditing} />
        <div style={{ marginTop: 60 }}>
          <Routes>
            <Route exact path="/" element={<HomePage eventCallback={homeEventCallback} />} />
            {SHOW_REPORT && <Route exact path="/report" element={<ReportSettingPage pageStatusChanged={pageStatusHandler} />} />}
            <Route path="/parts" element={<PartsPage2 pageStatusChanged={pageStatusHandler} />} />
            <Route path="/master"
              element={
                <RequireAuth roles={[Role.Admin, Role.Controller]}>
                  <MasterDataPage pageStatusChanged={pageStatusHandler} />
                </RequireAuth>
              }
            />
            <Route path="/accounts"
              element={
                <RequireAuth roles={[Role.Admin]}>
                  <AccountPage pageStatusChanged={pageStatusHandler} />
                </RequireAuth>
              }
            />
            <Route path="/register"
              element={
                <RequireAuth roles={[Role.SuperSeiya]}>
                  <RegisterPage />
                </RequireAuth>
              }
            />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/imports" element={<DataImportPage />} />
            {
              (userRole === "guest")
                ? <Route path="/login" element={<LoginPage />} />
                : null
            }
            <Route path="/test" element={<ParentPage />} />
            <Route path="/dbinit" element={<DBInitPage />} />
            <Route path="/eventlogs" 
              element={
                <RequireAuth roles={[Role.Admin]}>
                  <EventLogReportPage />
                </RequireAuth>
              }
            />
            <Route path="/exports" 
              element={
                <RequireAuth roles={[Role.Admin]}>
                  <DataExportPage />
                </RequireAuth>
              }
            />
            <Route path="/about" element={<AboutPage />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

function RequireAuth(props) {
  const roles = props.roles;
  console.log('children', props.children);
  const user = authenticationService.currentUserValue;
  console.log('user', user);
  let location = useLocation();
  console.log('location', location);
  console.log('role', roles);
  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" state={{ from: location }} replace />
  }            // check if route is restricted by role
  if (roles && roles.indexOf(user.role) === -1) {
    // role not authorized so redirect to home page 
    console.log('Invalid Role', user.role);
    return <Navigate to="/" state={{ from: location }} replace />
  }

  return props.children;
}

export default App;