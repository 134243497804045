import { CheckBox, Label, RowingOutlined } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { Box, Button, Card, FormControl, Grid, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography, Tabs, Tab, Autocomplete, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { partService } from '../_services';
import './pages.css';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function PartsTable(props) {
    let dataRows = null;
    const [tableData, setTableData] = useState([]);
    const [filterData, setFilterData] = useState([]);

    const buttonClicked = (row) => {
        console.log(row);
        props.partNoClicked(row);
    }
    const columns = [
        {
            field: 'id',
            headerName: 'Part Number',
            minWidth: 150,
            width: 250,
            renderCell: (params) => (
                <strong>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ marginLeft: 16 }}
                        onClick={() => buttonClicked(params.row)}
                    >
                        {params.row.id}

                    </Button>
                </strong>
            ),
        },
        {
            field: 'title',
            headerName: 'Description',
            width: 250
        },
        {
            field: 'operationName',
            headerName: 'Operation',
            width: 100,
        },
        {
            field: 'product',
            headerName: 'Product',
            width: 100
        },
        {
            field: 'supplierName',
            headerName: 'Supplier',
            width: 100
        },
        {
            field: 'leadTimeText',
            headerName: 'Lead Time',
            width: 100
        },
        {
            field: 'safetyLevel',
            headerName: 'Safety Level',
            width: 120,
            align: 'right'
        },
        {
            field: 'onhand',
            headerName: 'Onhand',
            width: 100,
            align: 'right'
        },
        {
            field: 'statusText',
            headerName: 'Status',
            width: 80,
            align: 'center'
        },
        {
            field: 'category',
            headerName: 'Type'
        }
    ]

    /*
    2021-01-08
    Work around MUI DataGrid issue that sets `height: 0px;` when autoHeight is enabled 
    https://github.com/mui-org/material-ui-x/issues/604
    
    Get the first div (which is the MUI datagrid element) and clear the 0px CSS height style
    */
    /*
     const gridWrapperRef = useRef < HTMLDivElement > (null);
     useLayoutEffect(() => {
         const gridDiv = gridWrapperRef.current;
         if (gridDiv != null) {
             const gridEl = gridDiv.querySelector('div');
             gridEl.style.height = '';
         }
     });
     */

    return (

        <div style={{ width: '100%', height: '730px' }}>
            <Box
                sx={{
                    height: '730px',
                    width: '100%',
                    '& .cold': {
                        backgroundColor: '#86FF33',
                        color: '#101011',
                    },
                    '& .hot': {
                        backgroundColor: '#DC1104',
                        color: '#D6DFEB',
                    },
                }}
            >
                <DataGrid
                    rows={props.data}
                    columns={columns}
                    autoHeight={false}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    sx={{
                        boxShadow: 2,
                        border: 2,
                        borderColor: 'primary.light',
                        '& .MuiDataGrid-cell:hover': {
                            color: 'primary.main',
                        },
                    }}
                    onRowClick={(params, e, details) => {
                        console.log(params.row);
                        console.log('e', e);
                        console.log('detail', details);
                        props.rowClicked(params.row);
                    }}
                    getCellClassName={(params) => {
                        if (params.field !== 'statusText') {
                            return '';
                        }
                        return params.value == 'LOW' ? 'hot' : 'cold';
                    }}
                />
            </Box>
        </div>
    );
}


function PartsFormWithdraw(props) {
    const selectedPart = { ...props.part };
    const [activePart, setActivePart] = useState(selectedPart);
    const [open, setOpen] = useState(false);
    const [withdrawValue, setWithDrawValue] = useState(1);
    console.log(withdrawValue);

    const handleClose = () => {
        setOpen(false);
        props.notifyFormEvent({ name: 'close', value: 0, remark: '' });
    }
    const handleWithdrawNumberChanged = (e) => {
        setWithDrawValue(e.target.value);
    }
    const handleWithdrawConfirmClicked = (value) => {
        props.notifyFormEvent({ name: 'withdraw', value: value, remark: '' });
    };
    if ((open != props.show) && props.show) {
        setOpen(true);
    }
    return (
        <Modal
            open={open}
        >
            <Box sx={{ ...style, width: 550, height: 420, borderRadius: 3 }}>
                <h5 id="child-modal-title"># {selectedPart.id}</h5>
                <div className='part-withdrawer-form-line' />
                <Stack direction="row">
                    <div className="part-withdrawer-form-panel-field">
                        <div className='row col-12'>
                            <p id="child-modal-description">
                                {selectedPart.title}
                            </p>
                        </div>
                        <div className='row'>
                            <strong className='col-4'>Operation:</strong>
                            <div className='col-8'>{selectedPart.operationName}</div>
                        </div>
                        <div className='row'>
                            <strong className='col-4'>Product:</strong>
                            <div className='col-8'>{selectedPart.product}</div>
                        </div>
                        <div className='row'>
                            <strong className='col-4'>Type:</strong>
                            <div className='col-8'>{(selectedPart.category == 1 ? 'Consumable' : 'Spare Part')}</div>
                        </div>
                    </div>
                    <div className="part-withdrawer-form-image-field">
                        <img width={120} height={120} />
                    </div>
                </Stack>
                <div className='row'>
                    <div className="col">
                        <Card style={{ margin: '10px', padding: '5px', height: '150px' }}>
                            <Stack direction="row">
                                <div className='part-withdrawer-form-onhand-field'>
                                    <div className='row'>
                                        <strong className='col-6'>Safety Level:</strong>
                                        <div className='col-6'>{selectedPart.safetyLevel}</div>
                                    </div>
                                    <div className='row'>
                                        <strong className='col-6'>Onhand:</strong>
                                        <div className='col-6'>{selectedPart.onhand}</div>
                                    </div>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& > :not(style)': { m: 1, width: '25ch' },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <TextField id="outlined-basic" label="Remark" variant="outlined" type="text"
                                            rows="2"
                                            multiline={true}
                                            style={{ width: 250, height: 120 }}
                                        />
                                    </Box>
                                </div>
                                <div className='part-withdrawer-form-withdraw-buttons'>
                                    <Stack>
                                        <Box
                                            component="form"
                                            sx={{
                                                '& > :not(style)': { m: 1, width: '25ch' },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <TextField id="outlined-basic" label="Withdraw" variant="outlined" type="number"
                                                style={{ width: 150, marginTop: 15 }}
                                                defaultValue={1}
                                                inputProps={{ min: 1, max: selectedPart.onhand }}
                                                onChange={handleWithdrawNumberChanged}
                                            />
                                        </Box>

                                        <Button variant="contained" onClick={(e) => handleWithdrawConfirmClicked(withdrawValue)}
                                            style={{ width: 100, margin: 10 }}>Confirm</Button>
                                    </Stack>

                                </div>
                            </Stack>
                        </Card>
                    </div>
                </div>
                <Stack direction="row" justifyContent="space-between">
                    <Button onClick={handleClose} align='center'>Close</Button>
                    <Button variant="outlined" >Barcode</Button>
                </Stack>
            </Box>
        </Modal>
    );
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function PartsFormController(props) {
    const selectedPart = { ...props.part };
    const [tabIndex, setTabIndex] = useState(0);
    const [activePart, setActivePart] = useState(selectedPart);
    const [open, setOpen] = useState(false);
    const [withdrawValue, setWithDrawValue] = useState(1);

    /* Part Fields */
    const [isEditingPart, setEditingPartTab] = useState(false);
    const [mode, setMode] = useState('view');

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        mode: "onBlur"
    });

    const handleClose = () => {
        setOpen(false);
        props.notifyFormEvent({ name: 'close', value: 0, remark: '' });
    }
    const handleWithdrawNumberChanged = (e) => {
        setWithDrawValue(e.target.value);
    }
    const handleWithdrawConfirmClicked = (value) => {
        props.notifyFormEvent({ name: 'withdraw', value: value, remark: '' });
    };

    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleTextFieldChanged = e => {
        let newUser = { ...selectedPart };
        const { [e.target.name]: keyValue } = selectedPart;
        newUser[e.target.name] = e.target.value;
    }
    const handleEditClicked = e => {
        setEditingPartTab(true);
        setMode('edit');
    }
    const handleAddClicked = e => {
        setEditingPartTab(true);
        setMode('add');
        reset();
    }
    const handleSaveClicked = e => {
        /*
        console.log(props.userList);
        const duplicatedUser = props.userList.filter(u => u.username.toLowerCase() == user.username.toLowerCase());
        if (duplicatedUser) {
            alert('Duplicated');
        } else {
            alert('OK');
            //props.viewClicked();
            //setIsEditing(false);
            //setMode('view');
        }
        */
    }
    const handleCancelClicked = e => {
        //props.viewClicked();
        setOpen(false);
        setMode('view');
    }
    const handleDeleteClicked = e => {
        //console.log("Delete => " + selectedUser.username);
    }
    function onSubmit(data) {
        console.log('data', data);
    }

    if ((open != props.show) && props.show) {
        setOpen(true);
    }
    return (
        <Modal
            open={open}
        >
            <Box sx={{ ...style, width: 700, height: 720, borderRadius: 3 }}>
                <h5 id="child-modal-title"># {selectedPart.id}</h5>
                <div className='part-withdrawer-form-line' />
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabIndex} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Parts" {...a11yProps(0)} />
                            <Tab label="Transactions" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={tabIndex} index={0}>
                        <div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-row">
                                    <label className="form-custom-label col-5">Part Number</label>
                                    <div className="form-group col-7">
                                        <input name="firstName" type="text" {...register('id', { required: true })} className={`form-control ${errors.id ? 'is-invalid' : ''}`}
                                            onChange={handleTextFieldChanged}
                                            readOnly={!isEditingPart}
                                        />
                                        {errors.firstName?.type === 'required' && <span className="form-field-error-message">First Name is required</span>}
                                    </div>
                                </div>
                                <div className="form-row">
                                    <label className="form-custom-label col-5">Description</label>
                                    <div className="form-group col-7">
                                        <input name="description" type="text" {...register('description')} className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                                            onChange={handleTextFieldChanged}
                                            readOnly={!isEditingPart}
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <label className="form-custom-label col-5">Operation</label>
                                    <div className="form-group col-7">
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={props.operationList}
                                            sx={{ width: 300 }}
                                            renderInput={(params) => <TextField {...params} label="Operations" disabled={!isEditingPart} />}
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <label className="form-custom-label col-5">Product</label>
                                    <div className="form-group col-7">
                                        <input name="product" type="text" {...register('product')} className={`form-control ${errors.product ? 'is-invalid' : ''}`}
                                            onChange={handleTextFieldChanged}
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <label className="form-custom-label col-5">Store Location</label>
                                    <div className="form-group col-7">
                                        <input name="storeLocation" type="text" {...register('storeLocation')} className={`form-control ${errors.storeLocation ? 'is-invalid' : ''}`}
                                            onChange={handleTextFieldChanged}
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <label className="form-custom-label col-5">Group</label>
                                    <div className="form-group col-7">
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={props.groupList}
                                            sx={{ width: 300 }}
                                            renderInput={(params) => <TextField {...params} label="Group" disabled={!isEditingPart} />}
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <label className="form-custom-label col-5">Supplier</label>
                                    <div className="form-group col-7">
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={props.supplierList}
                                            sx={{ width: 300 }}
                                            renderInput={(params) => <TextField {...params} label="Supplier" disabled={!isEditingPart} />}
                                        />
                                    </div>
                                </div>
                                <div className="form-group master-button-panels">
                                    <div className="row">
                                        <div className="col" style={{ display: 'flex' }}>
                                            {(mode == 'view') && <div className="btn-group" role="group">
                                                <button type="button" className="btn btn-primary form-user-button"
                                                    onClick={handleAddClicked}>Add</button>
                                                <button type="button" className="btn btn-primary form-user-button"
                                                    onClick={handleEditClicked}>Edit</button>
                                            </div>}
                                            {(mode == 'add' || mode == 'edit') &&
                                                <div className="btn-group" role="group">
                                                    <button type="submit" className="btn btn-success form-user-button"
                                                        onClick={handleSaveClicked}>Save</button>
                                                    <button type="button" className="btn btn-secondary form-user-button"
                                                        onClick={handleCancelClicked}>Cancel</button>
                                                    {(mode == 'edit') &&
                                                        <button type="button" className="btn btn-danger form-user-button"
                                                            onClick={handleDeleteClicked}>Delete</button>
                                                    }
                                                </div>
                                            }
                                        </div>
                                        <div className="col">
                                            {(mode == 'add' || mode == 'edit') &&
                                                <button type="button" onClick={() => reset()}
                                                    className="btn btn-light form-user-button">Clear</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className='part-withdrawer-form-line' />
                        <Stack direction="row" justifyContent="space-between" style={{ marginTop: 20 }}>
                            <Button onClick={handleClose} align='center'>Close</Button>
                            <Button variant="outlined" >Barcode</Button>
                        </Stack>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>

                    </TabPanel>
                </Box>
            </Box>
        </Modal>
    );
}

const PartsPage = () => {

    const [tableData, setTableData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [selectedPart, setSelectedPart] = useState(null);
    const [openWithdrawer, setOpenWithdrawer] = useState(false);
    const [openController, setOpenController] = useState(false);
    const [operationList, setOperationList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [supplierList, setSupplierList] = useState([]);
    const [roleNow, setRowNow] = useState('guest');
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {

        partService.getAll().then((data) => {
            console.log(data[0]);
            setTableData(data)
            setFilterData(data)
            const unique = [...new Set(data.map(item => item.operationName))]; // [ 'A', 'B']
            setOperationList(unique);
            const unique2 = [...new Set(data.map(item => item.groupName))]; // [ 'A', 'B']
            setGroupList(unique2);
            const unique3 = [...new Set(data.map(item => item.supplierName))]; // [ 'A', 'B']
            setSupplierList(unique3);

        }).catch((error) => {
            console.log(error);
        });
    }, []);

    const [filterType, setFilterType] = useState(0);
    const requestFilter = (filterValue) => {
        setFilterType(filterValue);
        let filteredRows = [];
        if (filterValue == 0) {
            filteredRows = [...tableData];
        } else if (filterValue == 1) {
            filteredRows = tableData.filter((row) => {
                return row['category'] == 1;
            });
        } else {
            filteredRows = tableData.filter((row) => {
                return row['category'] == 2;
            });
        }
        setFilterData(filteredRows);
    }

    const handleChange = (event) => {
        console.log(event.target.value);
        requestFilter(event.target.value);
    }

    const handleRowClicked = (row) => {
        console.log('Row click')
        //setSelectedPart(row);
        //setOpenWithdrawer(true);
    }
    const handlePartNoClicked = (row) => {
        console.log('P/N click');
        setSelectedPart(row);
        if (roleNow == 'admin') {
            setOpenController(true);
        } else {
            setOpenWithdrawer(true);
        }
    }
    const handleWithdrawerFormNotify = (eventName) => {
        console.log(eventName);
        if (eventName.name == 'close') {
            if (roleNow == 'admin') {
                setOpenController(false);
            } else {
                setOpenWithdrawer(false);
            }
        } else if (eventName.name == 'open') {
            if (roleNow == 'admin') {
                setOpenController(true);
            } else {
                setOpenWithdrawer(true);
            }
        } else if (eventName.name == 'withdraw') {
            console.log('withdraw', eventName.value);
            partService.addTransaction(selectedPart.id, eventName.value, eventName.remark).then((data) => {
                console.log(data);
                setSelectedPart({ ...data });
            }).catch((error) => {
                console.log(error);
            });
        }
    }
    const changCheckHandle = (e) => {
        console.log('Check', e.target.value);
    }

    return (
        <>
            <Stack>
                <div className="part-type-filter-container">
                    <span>Part Type:</span>
                    <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        autoWidth
                        value={filterType}
                        onChange={handleChange}
                    >
                        <MenuItem value={0}>All</MenuItem>
                        <MenuItem value={1}>Comsuable</MenuItem>
                        <MenuItem value={2}>Spare Part</MenuItem>
                    </Select>
                </div>
                <PartsTable data={filterData} rowClicked={handleRowClicked} partNoClicked={handlePartNoClicked} />
            </Stack>
            <PartsFormWithdraw part={selectedPart} show={openWithdrawer} notifyFormEvent={handleWithdrawerFormNotify} />
            <PartsFormController part={selectedPart}
                operationList={operationList}
                groupList={groupList}
                supplierList={supplierList}
                show={openController} notifyFormEvent={handleWithdrawerFormNotify} />
        </>
    );
}

export default PartsPage;