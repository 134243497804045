import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Box, Typography, Select, MenuItem, Card, Paper, Container, Alert } from '@mui/material';
import { useForm } from "react-hook-form";
import { masterService } from '../_services';
import './pages.css';
import { format } from 'date-fns';
import { flexRender } from 'react-table/dist/react-table.development';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function GenericForm(props) {
    const itemData = [...props.data];
    const selectedId = props.selected;
    const itemSelectionChangedCallback = props.selectionChanged;
    const itemAddedCallback = props.itemAdded;
    const itemUpdatedCallback = props.itemUpdated;
    const eventHappendHandler = props.eventHappened;

    const formName = props.formName;

    const [mode, setMode] = useState('view');
    const [isEditing, setIsEditing] = useState(false);
    const [activeItem, setActiveItem] = useState(null);
    const [orgItem, setOrgItem] = useState(null);
    const [errorMsg, setErrorMsg] = useState("");

    let submitting = false;

    // if (currentSelectedId != selectedId) {
    //     console.log(`selectedId(${selectedId}) vs currentSelectedId(${currentSelectedId})`);
    //     setCurrentSelectedId(selectedId);
    // }
    //console.log("Generic Form, currentSelectedId", currentSelectedId);
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        mode: "onBlur"
    });

    const handleItemSelectionChanged = e => {
        //const newItem = itemData.find((item) => item.id == e.target.value);
        const id = parseInt(e.target.value);
        itemSelectionChangedCallback(id);
    }

    const handleTextFieldChanged = e => {
        let newUser = { ...activeItem };
        const { [e.target.name]: keyValue } = activeItem;
        newUser[e.target.name] = e.target.value;
    }
    const handleEditClicked = e => {
        setOrgItem(prevValue => prevValue = JSON.parse(JSON.stringify(activeItem)));
        setIsEditing(true);
        setMode('edit');
        eventHappendHandler({ name: 'editing', params: [] });
    }
    const handleAddClicked = e => {
        setIsEditing(true);
        setMode('add');
        reset();
        eventHappendHandler({ name: 'editing', params: [] });
        itemSelectionChangedCallback(0);
    }
    const handleCancelClicked = e => {
        if (mode === 'edit') {
            updateActiveItem(orgItem);
        }
        setErrorMsg("");
        setIsEditing(false);
        setMode('view');
        eventHappendHandler({ name: 'done', params: [] });
        itemSelectionChangedCallback(-1);
    }
    const handleDeleteClicked = e => {
        //console.log("Delete => " + selectedUser.username);
    }
    function onSubmit(data) {
        switch (formName) {
            case "group":
                if (mode === 'add') {
                    masterService.addGroup(data).then(result => {
                        setErrorMsg("");
                        setIsEditing(false);
                        setMode('view');
                        itemAddedCallback("group", result.id);
                        eventHappendHandler({ name: 'done', params: [] });
                        itemSelectionChangedCallback(result.id);
                    }).catch(error => {
                        setErrorMsg(error);
                        // setIsEditing(false);
                        // setMode('view');
                        // itemSelectionChangedCallback(selectedId);
                        // eventHappendHandler({ name: 'done', params: [] });
                        // itemSelectionChangedCallback(-1);
                    });
                } else {
                    masterService.updateGroup(data).then(result => {
                        setErrorMsg("");
                        setIsEditing(false);
                        setMode('view');
                        itemUpdatedCallback("group", result.id);
                        eventHappendHandler({ name: 'done', params: [] });
                    }).catch(error => {
                        setErrorMsg(error);
                        // setIsEditing(false);
                        // setMode('view');
                        // itemSelectionChangedCallback(selectedId);
                        // eventHappendHandler({ name: 'done', params: [] });
                    });
                }
                break;
            case "operation":
                if (mode === 'add') {
                    masterService.addOperation(data).then(result => {
                        setErrorMsg("");
                        setIsEditing(false);
                        setMode('view');
                        itemAddedCallback("operation", result.id);
                        eventHappendHandler({ name: 'done', params: [] });
                        itemSelectionChangedCallback(result.id);
                    }).catch(error => {
                        setErrorMsg(error);
                    });
                } else {
                    masterService.updateOperation(data).then(result => {
                        setErrorMsg("");
                        setIsEditing(false);
                        setMode('view');
                        itemUpdatedCallback("operation", result.id);
                        eventHappendHandler({ name: 'done', params: [] });
                    }).catch(error => {
                        setErrorMsg(error);
                    });
                }
                break;
            case "supplier":
                if (mode === 'add') {
                    masterService.addSupplier(data).then(result => {
                        setErrorMsg("");
                        setIsEditing(false);
                        setMode('view');
                        itemAddedCallback("supplier", result.id);
                        eventHappendHandler({ name: 'done', params: [] });
                        itemSelectionChangedCallback(result.id);
                    }).catch(error => {
                        setErrorMsg(error);
                    });
                } else {
                    masterService.updateSupplier(data).then(result => {
                        setErrorMsg("");
                        setIsEditing(false);
                        setMode('view');
                        itemUpdatedCallback("supplier", result.id);
                        eventHappendHandler({ name: 'done', params: [] });
                    }).catch(error => {
                        setErrorMsg(error);
                    });
                }
                break;
        }
        return false;
    }

    function updateActiveItem(newItem) {    
        const fields = ['id', 'name', 'description', 'updated', 'creatorName'];
        setActiveItem(prevValue => prevValue = newItem);
        fields.forEach(field => setValue(field, newItem[field]));
        console.log('Active Item', newItem);
    }

    useEffect(() => {
        console.log(`Form ${formName} useEffect`, selectedId);
        if (selectedId > 0) {
            const selectedItem = itemData.find((item) => item.id == selectedId);
            console.log('selectedItem', selectedItem);
            if (selectedItem != null) {
                updateActiveItem(selectedItem);
            }
        } else {
            setValue("id", 0);
        }
    }, [selectedId]);

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1,
                        width: 600,
                        height: 408,
                    },
                }}
            >
                <Paper sx={{
                    padding: 4,
                }}>
                    {selectedId === 0 ?
                        <Select
                            size="small"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={0}
                            autoWidth
                            disabled
                            fullWidth
                            sx={{
                                marginBottom: 5
                            }}
                        >
                            <MenuItem key={0} value={0}></MenuItem>)
                        </Select>
                        :
                        <Select
                            size="small"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            autoWidth
                            value={selectedId}
                            onChange={handleItemSelectionChanged}
                            disabled={isEditing}
                            fullWidth
                            sx={{
                                marginBottom: 5
                            }}
                        >
                            {
                                itemData.map((item) =>
                                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)
                            }
                        </Select>
                    }
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-row">
                            <input type="hidden" name="id" id="id" {...register('id')} />
                            <label className="form-custom-label col-5">Name</label>
                            <div className="form-group col-7">
                                <input name="firstName" type="text" {...register('name', { required: true })} className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                    onChange={handleTextFieldChanged}
                                    readOnly={!isEditing}
                                />
                                {errors.firstName?.type === 'required' && <span className="form-field-error-message">First Name is required</span>}
                            </div>
                        </div>
                        <div className="form-row">
                            <label className="form-custom-label col-5">Detail</label>
                            <div className="form-group col-7">
                                <input name="lastName" type="text" {...register('description')} className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                                    onChange={handleTextFieldChanged}
                                    readOnly={!isEditing}
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <label className="form-custom-label col-5">Last Updated</label>
                            <div className="form-group col-7">
                                <input name="lastName" type="text" className={`form-control`}
                                    value={(activeItem == null || isEditing) ? '' : format(new Date(activeItem.updated), 'dd-MMM-yyyy HH:mm')}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <label className="form-custom-label col-5">By</label>
                            <div className="form-group col-7">
                                <input name="lastName" type="text" {...register('creatorName')} className={`form-control ${errors.creatorName ? 'is-invalid' : ''}`}
                                    onChange={handleTextFieldChanged}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="form-group master-button-panels">
                            <div className="row">
                                <div className="col" style={{ display: 'flex' }}>
                                    {(mode == 'view') && <div className="btn-group" role="group">
                                        <button type="button" className="btn btn-primary form-user-button"
                                            onClick={handleAddClicked}>Add</button>
                                        <button type="button" className="btn btn-primary form-user-button"
                                            onClick={handleEditClicked}
                                            disabled={selectedId === 0}>Edit</button>
                                    </div>}
                                    {(mode == 'add' || mode == 'edit') &&
                                        <div className="btn-group" role="group">
                                            <button type="submit" className="btn btn-success form-user-button">Save</button>
                                            <button type="button" className="btn btn-secondary form-user-button"
                                                onClick={handleCancelClicked}>Cancel</button>
                                            {(mode == 'backup') &&
                                                <button type="button" className="btn btn-danger form-user-button"
                                                    onClick={handleDeleteClicked}>Delete</button>
                                            }
                                        </div>
                                    }
                                </div>
                                <div className="col">
                                    {(mode == 'add' || mode == 'edit') &&
                                        <button type="button" onClick={() => reset()}
                                            className="btn btn-light form-user-button">Clear</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </form>
                </Paper>
            </Box>
            {errorMsg && errorMsg !== '' && (<Alert severity="warning">{errorMsg}</Alert>)}
        </div>
    );
}

const MasterDataPage = (props) => {
    const pageStatusCallback = props.pageStatusChanged;

    const [value, setValue] = useState(0);
    const [groupItems, setGroupItems] = useState([]);
    const [operItems, setOperItems] = useState([]);
    const [supplierItems, setSupplierItems] = useState([]);

    const [selectedGroupId, setSelectedGroupId] = useState(0);
    const [selectedOperationId, setSelectedOperationId] = useState(0);
    const [selectedSupplierId, setSelectedSupplierId] = useState(0);

    const [prevGroupId, setPrevGroupId] = useState(0);
    const [prevOperationId, setPrevOperationId] = useState(0);
    const [prevSupplierId, setPrevSupplierId] = useState(0);

    const [isEditing, setIsEditing] = useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function groupSelectionChanged(id) {
        console.log("Page call groupSelectionChanged", id);
        if (id < 0) { // Cancel called, id < 0
            setSelectedGroupId(prevGroupId);
        } else {
            setPrevGroupId(selectedGroupId);
            setSelectedGroupId(id);
        }
    }
    function operationSelectionChanged(id) {
        console.log("Page call operationSelectionChanged", id);
        if (id < 0) {
            setSelectedOperationId(id);
        } else {
            setPrevOperationId(selectedGroupId);
            setSelectedOperationId(id);
        }
    }
    function supplierSelectionChanged(id) {
        console.log("Page call supplierSelectionChanged", id);
        setSelectedSupplierId(id);
    }

    function eventCallbackHandler(e) {
        if (e.name == "editing") {
            setIsEditing(true);
            pageStatusCallback("MasterData", "editing");
        } else {
            setIsEditing(false);
            pageStatusCallback("MasterData", "");
        }
    }
    function requeryDataAndSelectId(category, id) {
        console.log("requeryDataAndSelectId", category, id);
        switch (category) {
            case "group":
                masterService.getAllGroups().then((data) => {
                    setGroupItems(data)
                    if (data.length > 0) {
                        console.log("requestDataAndSelectId after fetch=> ID", id);
                        setSelectedGroupId(id);
                    }
                }).catch((error) => {
                    console.log(error);
                });
                break;
            case "operation":
                masterService.getAllOperations().then((data) => {
                    setOperItems(data)
                    if (data.length > 0) {
                        console.log("requestDataAndSelectId after fetch=> ID", id);
                        setSelectedOperationId(id);
                    }
                }).catch((error) => {
                    console.log(error);
                });
                break;
            case "supplier":
                masterService.getAllSuppliers().then((data) => {
                    setSupplierItems(data)
                    if (data.length > 0) {
                        console.log("requestDataAndSelectId after fetch=> ID", id);
                        setSelectedSupplierId(id);
                    }
                }).catch((error) => {
                    console.log(error);
                });
                break;
        }
    }

    useEffect(() => {
        console.log("Page UseEffect", selectedGroupId, selectedOperationId, selectedSupplierId);
        masterService.getAllGroups().then((data) => {
            setGroupItems(data)
            if ((data.length > 0) && (selectedGroupId === 0)) {
                setSelectedGroupId(data[0].id);
            }
        }).catch((error) => {
            console.log(error);
        });
        masterService.getAllOperations().then((data) => {
            setOperItems(data)
            if ((data.length > 0) && (selectedOperationId === 0)) {
                setSelectedOperationId(data[0].id);
            }
        }).catch((error) => {
            console.log(error);
        });
        masterService.getAllSuppliers().then((data) => {
            setSupplierItems(data)
            if ((data.length > 0) && (selectedSupplierId === 0)) {
                setSelectedSupplierId(data[0].id);
            }
        }).catch((error) => {
            console.log(error);
        });

    }, []);

    return (
        <Container style={{ maxWidth: 700, justifyContent: 'center', paddingTop: '20px' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Groups" {...a11yProps(0)} disabled={isEditing} />
                    <Tab label="Operations" {...a11yProps(1)} disabled={isEditing} />
                    <Tab label="Suppliers" {...a11yProps(2)} disabled={isEditing} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <GenericForm formName="group" data={groupItems} selected={selectedGroupId} selectionChanged={groupSelectionChanged}
                    itemAdded={requeryDataAndSelectId} itemUpdated={requeryDataAndSelectId} eventHappened={eventCallbackHandler} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <GenericForm formName="operation" data={operItems} selected={selectedOperationId} selectionChanged={operationSelectionChanged}
                    itemAdded={requeryDataAndSelectId} itemUpdated={requeryDataAndSelectId} eventHappened={eventCallbackHandler} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <GenericForm formName="supplier" data={supplierItems} selected={selectedSupplierId} selectionChanged={supplierSelectionChanged}
                    itemAdded={requeryDataAndSelectId} itemUpdated={requeryDataAndSelectId} eventHappened={eventCallbackHandler} />
            </TabPanel>
        </Container>
    );
};

export default MasterDataPage;