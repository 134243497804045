import { Card, Container, FormControl, FormControlLabel, Stack, Switch, ToggleButton, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import DialogYesNo from '../components/ModalYesNo';
import GenericAddItemDialog from '../components/Report/GenericAddItemDialog';
import { reportConfigService } from '../_services';

const ReportSettingPage = (props) => {
    const pageStatusCallback = props.pageStatusChanged;

    const [mode, setMode] = useState("view");
    const [reportId, setReportId] = useState(0);

    const [report, setReport] = useState(null);
    const [orgReport, setOrgReport] = useState(null);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [active, setActive] = useState(false);
    const [period, setPeriod] = useState(0);

    const [errMsg, setErrMsg] = useState("");

    const [dialogTitle, setDialogTitle] = useState("Title");
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState("");
    const [dialogConfirmCallback, setDialogConfirmCallback] = useState(null);

    const [genericAddDialogTitle, setGenericAddDialogTitle] = useState("Title");
    const [genericAddDialogOpen, setGenericAddDialogOpen] = useState(false);
    const [genericAddDialogContent, setGenericAddDialogContent] = useState("");
    const [genericAddDialogConfirmCallback, setGenericAddDialogConfirmCallback] = useState();

    const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const hours = [...Array(23).keys()];

    /* Handle Events */
    const handleTextFieldChanged = (e) => {
        switch (e.target.name) {
            case "subject":
                setReport(prevValue => prevValue = { ...report, subject: e.target.value });
                break;
            case "content":
                setReport(prevValue => prevValue = { ...report, content: e.target.value });
                break;
        }
    }
    const handleDistributionListChanged = (e) => {
        if (mode == "edit") {
            setEmail(e.target.value);
        }
    }
    const handlePeriodChanged = (e) => {
        if (report != null) {
            const newValue = parseInt(e.target.value);
            console.log("Before change", report.conditions.Parameters[0].Value);
            const parametersJson = JSON.stringify(report.conditions.Parameters);
            const parametersObj = JSON.parse(parametersJson);
            parametersObj[0].Value = newValue;
            const reportWithNewParameters = { ...report, conditions: { ...report.conditions, Parameters: parametersObj } };
            //console.log("New Parameters", reportWithNewParameters);
            setReport(prevValue => prevValue =
                reportWithNewParameters
            );
        }
    }
    //console.log("xxReport", report.conditions.parameters);
    const handleDayCheckChanged = (e) => {
        if (report != null) {
            const selDay = e.target.name;
            const selected = e.target.checked;
            let newSchedule = { days: [], time: report.schedule.time };
            if (selected) {
                newSchedule.days = [...report.schedule.days, selDay];
            } else {
                newSchedule.days = report.schedule.days.filter(d => d != selDay);
            }
            setReport(prevValue => prevValue = { ...report, schedule: newSchedule })
        }
    }
    const handleHourSelectionChanged = (e) => {
        if (report != null) {
            const newHour = e.target.value;
            let newSchedule = { days: [], time: parseInt(newHour) };
            newSchedule.days = report.schedule.days;
            setReport(prevValue => prevValue = { ...report, schedule: newSchedule })
        }
    }
    const handleOnBlur = (e) => {
        let fieldValue = e.target.value.toString().trim();
        switch (e.target.name) {
            case "subject":
                if (!fieldValue || fieldValue == "") {
                    setErrMsg("Subject is required");
                } else {
                    setErrMsg("");
                }
                break;
        }
    }
    const handleActivateChanged = (e) => {
        setActive(e.target.checked);
        setReport(prevValue => prevValue = { ...report, active: e.target.checked });
    }

    const handleButtonClicked = (e) => {
        switch (e.target.name) {
            case "addEmailButton":
                setGenericAddDialogTitle("Add Email");
                setGenericAddDialogContent("Add an email to distribution list");
                setGenericAddDialogConfirmCallback(prevValue => prevValue = (e) => {
                    let newList = [...report.distributionList, e];
                    setReport(prevValue => prevValue = { ...report, distributionList: newList });
                });
                setGenericAddDialogOpen(true);
                break;
            case "removeEmailButton":
                setDialogTitle("Remove Email");
                setDialogContent(`Do you want to remove this email (${email}) from the distribution list?`);
                setDialogConfirmCallback(prevValue => prevValue = () => {
                    var filtered = report.distributionList.filter(function (value, index, arr) {
                        return value != email;
                    });
                    setReport(prevValue => prevValue = { ...report, distributionList: filtered });
                });
                setDialogOpen(true);
                break;

        }
    }
    const handleEditClicked = (e) => {
        pageStatusCallback("MasterData", "editing");
        setMode("edit");
        setOrgReport(prevValue => prevValue = JSON.parse(JSON.stringify(report)));
    }
    const handleSaveClicked = (e) => {
        pageStatusCallback("MasterData", "save");
        //console.log(report);
        //console.log(JSON.stringify(report.conditions));
        report.conditionsJson = JSON.stringify(report.conditions);
        reportConfigService.update(report)
            .then((data) => {
                console.log("Data", data);
                setMode("view");
                alert("Success");
            }).catch(error => {
                alert("Error save setting: " + error);
            });
    }
    const handleCancelClicked = (e) => {
        pageStatusCallback("MasterData", "cancel");
        setMode("view");
        setReport(prevValue => prevValue = JSON.parse(JSON.stringify(orgReport)));
    }

    const [data, setData] = useState({
        dataSets: [
            {
                table: 'Parts',
                columns: ['Id', 'Title', 'Category', 'Owner', 'GroupId', 'SafetyLevel', 'SupplierId', 'OperationId', 'Price', 'LeadTime', 'Product', 'StoreLocation', 'Updated', 'Created', 'Remark', 'Onhand', 'CreatorId', 'ImageData', 'ImageContentType', 'Status', 'Active']
            },
            {
                table: 'Transaction',
                columns: ['Id', 'Updated', 'PartId', 'Category', 'Onhand', 'ReferenceId', 'Quantity', 'Note', 'CreatorId', 'Status']
            }
        ],
        records: []
    });
    if (data.records.length == 0) {
        // getReports();
        /*
        setData(
            prevValue => prevValue = {
                ...data,

                records: [
                    {
                        id: 1,
                        name: 'Weekly Active Parts',
                        subject: 'Attn: All buyers',
                        content: 'Please review data',
                        category: 'Parts',
                        distributionList: ['buyer1@fab.com', 'buyer2@fab.com', 'buyer3@fab.com'],
                        displayFields: [
                            { header: 'Part No', table: 'Parts', column: 'Id', key: 1, join: [] },
                            { header: 'Description', table: 'Parts', column: 'Title', key: 2, join: [] },
                            { header: 'Supplier', table: 'Suppliers', column: 'Name', key: 3, join: ['Parts.SupplierId', 'Suppliers.Id'] },
                            { header: 'Onhand', table: 'Parts', column: 'Onhand', key: 4, join: [] },
                            { header: 'Safety Level', table: 'Parts', column: 'SafetyLevel', key: 5, join: [] },
                            { header: 'Lead Time', table: 'Parts', column: 'LeadTime', key: 6, join: [] }
                        ],
                        schedule: {
                            days: ['Sun', 'Mon', 'Fri'],
                            time: 10
                        },
                        conditions: {
                            contents: {
                                "logic": "AND",
                                "expressions": [],
                                "evaluation": [
                                    {
                                        "logic": "AND",
                                        "expressions": [
                                            "[PartTransactions].[Updated] >= {Today - @param1}",
                                            "[PartTransactions].[Updated] <= {Today}"
                                        ],
                                        "evaluation": []
                                    }
                                ]
                            },
                            parameters: [
                                {
                                    id: "param1",
                                    label: "Report Period",
                                    dataType: "int",
                                    value: 7,
                                    unit: "day(s)",
                                    options: []
                                }
                            ]
                        },
                        active: true
                    }
                ]
            }
        );
        */
    }

    // if ((report == null) && (data.records.length > 0)) {
    //     setReport(prevValue => prevValue = data.records[0]);
    // }

    function getReports() {
        reportConfigService.getAll().then((result) => {
            result[0].conditions = JSON.parse(result[0].conditionsJson);
            setData(prevValue => prevValue = { ...data, records: result });
            if ((report == null) && (result.length > 0)) {
                setReport(prevValue => prevValue = result[0]);
                setReportId(result[0].id);
            }
        }).catch((error) => {
            console.log("Error", error);
        })
    }
    useEffect(() => {
        getReports();
    }, [])

    useEffect(() => {
        if (report) {
            setName(report.name);
            setActive(report.active);
            if (report.distributionList.length > 0) {
                console.log("DistributionList[0]", report.distributionList[0]);
                setEmail(report.distributionList[0]);
            }
        }
    }, [mode, reportId])


    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    return (
        <Stack>
            <Typography variant="h5" style={{ paddingTop: 20 }}>
                Report Settings
            </Typography>
            <Card>
                <div className="form-row" >
                    <Typography variant="h6" className="ml-4">
                        {name}
                    </Typography>
                </div>
                <div className="card m-3">
                    <div className="card-body">
                        <div className="form-row">
                            <label className="form-custom-label col-3">Subject:</label>
                            <div className="form-group col-5">
                                <input name="subject" type="text" value={report && report.subject ? report.subject : ''}
                                    className="form-control"
                                    onChange={handleTextFieldChanged}
                                    onBlur={handleOnBlur}
                                    disabled={mode !== 'edit'}
                                />
                                {(mode === 'edit') && (errMsg != "") && <span className="form-field-error-message">Subject is required</span>}
                            </div>
                            <div className="form-group col-col">
                            </div>
                        </div>
                        <div className="form-row">
                            <label className="form-custom-label col-3">Content:</label>
                            <div className="form-group col-5">
                                <textarea name="content" rows="4" value={report && report.content ? report.content : ''}
                                    className="form-control"
                                    onChange={handleTextFieldChanged}
                                    onBlur={handleOnBlur}
                                    disabled={mode !== 'edit'}
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <label className="form-custom-label col-3">Distribution List:</label>
                            <div className="form-group col-5">
                                <select value={email}
                                    className="form-control"
                                    onChange={handleDistributionListChanged}
                                >
                                    {report && report.distributionList.map((v) => (
                                        <option value={v} key={v}>{v}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group col-col">
                                <button className="btn btn-primary form-user-button" name="addEmailButton"
                                    style={{ visibility: (mode == 'view' ? 'hidden' : 'visible') }}
                                    onClick={handleButtonClicked}>Add</button>
                                <button className="btn btn-secondary form-user-button" name="removeEmailButton"
                                    style={{ visibility: (mode == 'view' ? 'hidden' : 'visible') }}
                                    onClick={handleButtonClicked}>Remove</button>
                            </div>
                        </div>
                        {report && report.conditions && report.conditions.Parameters && (report.conditions.Parameters.length > 0) &&
                            (
                                <div className="form-row">
                                    < label className="form-custom-label col-3">{report && report.conditions.Parameters[0].Label}</label>
                                    <div className="form-group col-5 mb-1 w-10 d-flex justify-content-start">
                                        <input type="number" name="paramValue"
                                            value={(report == null) ? 0 : report.conditions.Parameters[0].Value}
                                            disabled={mode == 'view'}
                                            onChange={handlePeriodChanged} />
                                        <span>&nbsp;&nbsp;Day(s)</span>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className="card m-3">
                    <div className="card-body">
                        <div className="form-row">
                            <label className="form-custom-label col-3">Schedule:</label>
                            <div className="col-7 mb-1 d-flex flex-row flex-wrap align-items-center">
                                {weekDays.map((day) => {
                                    let checkedValue = false;
                                    if (report && report.schedule.days.findIndex((d) => d === day) > -1) {
                                        checkedValue = true;
                                    }

                                    return (<div key={'div_' + day}>
                                        <input name={day} key={day} type="checkbox" disabled={mode == "view"}
                                            onChange={handleDayCheckChanged} checked={checkedValue} />
                                        <label className='pl-1 pr-3 m-0' key={'label_' + day}>{day}</label>
                                    </div>);
                                })}
                            </div>
                            <div className="form-group col-2 mb-1">
                                <label className="form-custom-label w-50">Hour:</label>
                                <select className="w-50" value={report == null ? 0 : report.schedule.time}
                                    disabled={mode == "view"}
                                    onChange={handleHourSelectionChanged}>
                                    {hours.map((hr) => (
                                        <option value={hr} key={hr}>{hr}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="form-row">
                            <label className="form-custom-label col-3">Activate:</label>
                            <div className="col-9 mb-1 d-flex justify-content-start">
                                <FormControlLabel control={<Switch checked={active} onChange={handleActivateChanged} />}
                                    label={active ? 'On' : 'Off'} disabled={mode == "view"} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card m-3">
                    <div className="row">
                        <div className="col" style={{ display: 'flex' }}>
                            {report && (mode == 'view') && <div className="btn-group" role="group">
                                <button type="button" className="btn btn-primary form-user-button"
                                    onClick={handleEditClicked}>Edit</button>
                            </div>}
                            {(mode === 'edit') &&
                                <div className="btn-group" role="group">
                                    <button type="submit" className="btn btn-primary form-user-button"
                                        disabled={errMsg != ""}
                                        onClick={handleSaveClicked}>Save</button>
                                    <button type="button" className="btn btn-secondary form-user-button"
                                        onClick={handleCancelClicked}>Cancel</button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Card>
            {
                report && (
                    <GenericAddItemDialog
                        title={genericAddDialogTitle}
                        open={genericAddDialogOpen}
                        setOpen={setGenericAddDialogOpen}
                        onConfirm={genericAddDialogConfirmCallback}
                        content={genericAddDialogContent}
                        itemList={report.distributionList}
                        validateFunc={validateEmail}
                    />
                )
            }
            <DialogYesNo
                title={dialogTitle}
                open={dialogOpen}
                setOpen={setDialogOpen}
                onConfirm={dialogConfirmCallback}
            >
                {dialogContent}
            </DialogYesNo>
        </Stack >
    );
}


export default ReportSettingPage;