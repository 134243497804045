import { authHeader, handleResponse } from '../_helpers';
import { config, fetchWrapper } from '../_helpers';

export const masterService = {
    getAllGroups,
    getAllOperations,
    getAllSuppliers,
    addGroup,
    updateGroup,
    addOperation,
    updateOperation,
    addSupplier,
    updateSupplier
};

function getAllGroups() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetchWrapper.get(`${config.apiUrl}/groups`);
}
function getAllOperations() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetchWrapper.get(`${config.apiUrl}/operations`);
}
function getAllSuppliers() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetchWrapper.get(`${config.apiUrl}/suppliers`);
}

function addGroup(group) {
    return fetchWrapper.post(`${config.apiUrl}/groups`, group);
}
function updateGroup(group) {
    return fetchWrapper.post(`${config.apiUrl}/groups/update`, group);
}

function addOperation(operation) {
    return fetchWrapper.post(`${config.apiUrl}/operations`, operation);
}
function updateOperation(operation) {
    return fetchWrapper.post(`${config.apiUrl}/operations/update`, operation);
}

function addSupplier(supplier) {
    return fetchWrapper.post(`${config.apiUrl}/suppliers`, supplier);
}
function updateSupplier(supplier) {
    return fetchWrapper.post(`${config.apiUrl}/suppliers/update`, supplier);
}

function getById(id) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetchWrapper(`${config.apiUrl}/parts/${id}`, requestOptions).then(handleResponse);
}
