import React, { useState, useEffect, useReducer, useRef } from 'react';
import {
    Modal, Tab, Tabs, Typography, Autocomplete, TextField, Box,
    Stack, Card, Switch, Grid, Radio, RadioGroup, Button
} from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function LargeImageForm(props) {

    const [modalShow, setModalShow] = useState(false);

    const handleClose = () => {
        setModalShow(prevValue => prevValue = false);
        props.closed();
    }

    if ((modalShow != props.show) && props.show) {
        setModalShow(prevValue => prevValue = true);
    }

    return (
        <Modal
            open={modalShow}
            >
            <Box sx={{ ...style, width: 820, height: 540, borderRadius: 3 }}>  
                <Box style={{ width: '800px', height: '430px', margin: '0px auto 10px auto' }}>
                    <img src={`data:${props.data.imageContentType};base64,${props.data.imageData}`} alt="part-image"
                        style={{ border: '1px solid black', borderRadius: '10px', objectFit: 'contain', width: '760px', height: '430px'}}/>
                </Box>  
                <div className='part-withdrawer-form-line' />
                <Stack direction="row" justifyContent="space-between" style={{ marginTop: 10 }}>
                    <Button onClick={handleClose} align='center'>Close</Button>
                </Stack>
            </Box>
        </Modal>   
    );    
}