import styled from "styled-components"

export const TableStyles = styled.div`
    display: block;
    max-width: 100%;

    .table {
        width: 100%;
        height: 600px;
        border-spacing: 0;
        border: 1px solid #ddd;

        .tr {
            :nth-child(even) {
                background-color: #f2f2f2;
            }
        },

        .tr {
            &.header {
                background-color: #1976d2;
                color: white;
            }
        },

        .th {
            margin: 0;
            padding: 0.5rem;
            background-color: #1976d2;
            overflow: hidden;
            white-space: nowrap;  
            position: relative;
            .resizer {
                display: inline-block;
                background: #325ca8;
                width: 5px;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                transform: translateX(50%);
                z-index: 1;
                ${'' /* prevents from scrolling while dragging on touch devices */}
                touch-action:none;

                &.isResizing {
                    background: #22f;
                }
            }
        },

        .td {
            &.cell {
                margin: 0;
                padding: 0.5rem;
                border-bottom: 1px solid #888888;
                border-right: 1px solid #888888;
                background-color: #fff;
                overflow: hidden;
                white-space: nowrap;
                /* The secret sauce */
                /* Each cell should grow equally */
                width: 1%;
                /* But "collapsed" cells should be as small as possible */
                &.collapse {
                    width: 0.0000000001%;
                }

                :last-child {
                    border-right: 0;
                }

                ${'' /* In this example we use an absolutely position resizer,
                so this is required. */}
                position: relative;
                .resizer {
                    display: inline-block;
                    background: #325ca8;
                    width: 5px;
                    height: 100%;
                    position: absolute;
                    right: 0;
                    top: 0;
                    transform: translateX(50%);
                    z-index: 1;
                    ${'' /* prevents from scrolling while dragging on touch devices */}
                    touch-action:none;

                    &.isResizing {
                        background: #22f;
                    }
                }    
            }        
        }

        &.sticky {
            overflow: scroll;
            .header,
            .footer {
                position: sticky;
                z-index: 1;
                width: fit-content;
            }

            .header {
                top: 0;
            }

            .footer {
                bottom: 0;
                box-shadow: 0px -3px 3px #ccc;
            }

            .body {
                position: relative;
                z-index: 0;
            }

            [data-sticky-td] {
                position: sticky;
            }
            [data-sticky-last-left-td] {
                box-shadow: 0px 0px 1px #ccc;
            }
            [data-sticky-first-right-td] {
                box-shadow: -2px 0xp 3px #ccc;
            }
        }
    }
`
