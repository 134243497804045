import { DateTimePicker } from '@mui/lab';
import { BehaviorSubject } from 'rxjs';

import { handleResponse, config, fetchWrapper, history } from '../_helpers';
import { utils } from '../_helpers';

const currentUserSubject = new BehaviorSubject(null);// BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    logout,
    refreshToken,
    fetchToken,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value },
    expireTimerCallback
};

function login(username, password, rememberMe) {
    return fetchWrapper.post(`${config.apiUrl}/account/authenticate`, { username, password, rememberMe })
        .then(user => {
            //localStorage.setItem('currentUser', JSON.stringify(user));
            if (utils.IsDeBugMode) {
                console.log("login() => User", user);
            }
            //localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);
            startRefreshTokenTimer();
            //startExpireTimer();
            return user;
        })
}

function logout() {
    if (utils.IsDeBugMode) {
        console.log("logout() => is Called");
    }
    stopExpireTimer();
    stopRefreshTokenTimer();
    return fetchWrapper.post(`${config.apiUrl}/account/revoke-token`, {})
        .then(data => {
            if (utils.IsDeBugMode) {
                console.log("Revoke Token done");
            }
            currentUserSubject.next(null);         
            //history.push("/");
        }).catch(error => {
            console.log(error);
        });
    //localStorage.removeItem('currentUser');

    //console.log(history.getUrl)
}

function refreshToken() {
    if (utils.IsDeBugMode) {
        console.log('refreshToken() => Called', new Date());
    }
    stopExpireTimer();
    return fetchWrapper.post(`${config.apiUrl}/account/refresh-token`, {})
        .then(user => {
            // publish user to subscribers and start timer to refresh token
            if (utils.IsDeBugMode) {
                console.log('---- refreshToken() => User', user);
            }
            currentUserSubject.next(user);
            startRefreshTokenTimer();
            //startExpireTimer();
            return user;
        });
}

function fetchToken() {
    if (utils.IsDeBugMode) {
        console.log('fetchToken() => Called', new Date());
    }
    stopExpireTimer();
    return fetchWrapper.post(`${config.apiUrl}/account/fetch-token`, {})
        .then(user => {
            // publish user to subscribers and start timer to refresh token
            if (utils.IsDeBugMode) {
                console.log('---- fetchToken() => User', user);
            }
            currentUserSubject.next(user);
            startRefreshTokenTimer();
            //startExpireTimer();
            return user;
        });
}

let refreshTokenOrgRemainingTime;
let actualExpireTime;
let refreshTokenTimeout;
function startRefreshTokenTimer() {
    //console.log("startRefreshTokenTimer() => User", currentUserSubject.value);
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(currentUserSubject.value.jwtToken.split('.')[1]));

    if (utils.IsDeBugMode) {
        console.log('---- startRefreshTokenTimer() => user', currentUserSubject.value);
        console.log('---- startRefreshTokenTimer() => jwtToken.expired-time', jwtToken.exp * 1000);
    }
    // set a timeout to refresh the token a minute before it expires
    
    /*
    const byNow = new Date();
    console.log('mock-byNow', byNow);
    const expires = new Date(byNow.getTime() + 60000); //new Date(jwtToken.exp * 1000);
    console.log('mock-expires:', expires);
    const timeout = expires.getTime() - byNow.getTime();// - (60 * 1000);
    console.log('mock-timeout', timeout);
    const thisMoment = byNow.getTime();
    console.log('mock-thisMoment', thisMoment);
    */
    const thisMoment = new Date().getTime();
    if (utils.IsDeBugMode) {
        console.log(`This moment time = ${thisMoment}`);
    }
    const expires = new Date(jwtToken.exp * 1000);
    //const timeout = expires.getTime() - (60 * 1000);
    
    actualExpireTime = expires;
    if (utils.IsDeBugMode) {
        console.log('actual expired time', actualExpireTime.getTime());
    }
    refreshTokenOrgRemainingTime =  actualExpireTime.getTime() - thisMoment;

    if (utils.IsDeBugMode) {
        console.log('---- startRefreshTokenTimer()', `expires = ${expires}`);
        console.log('---- startRefreshTokenTimer()', `refreshToken Original Remaining Time = ${refreshTokenOrgRemainingTime}`);
    }
    // console.log('---- startRefreshTokenTimer()', new Date());
    refreshTokenTimeout = setTimeout(refreshToken, refreshTokenOrgRemainingTime);
}

function stopRefreshTokenTimer() {
    if (utils.IsDeBugMode) {
        console.log('stopRefreshTokenTImer()');
    }
    clearTimeout(refreshTokenTimeout);
}

let expireFuncCallback;
function expireTimerCallback(callbackFunc) {
    expireFuncCallback = callbackFunc;
}

const normalise = (min, max, value) => ((value - min) * 100) / (max - min);
let expireTimeout;
function startExpireTimer() {
    expireTimeout = setTimeout(startExpireTimer, 5000);
    const thisMoment = new Date().getTime();
    const remainingTime = actualExpireTime.getTime() - thisMoment;
    console.log('Time remaining before Refresh Token (s) = ' + remainingTime);
    if (expireFuncCallback != null) {
        console.log('expireFuncCallback...', normVal);
        let normVal = normalise(0, refreshTokenOrgRemainingTime, remainingTime);
        expireFuncCallback(100 - normVal);
    }
    //console.log("Expire Values", remainingTime);
}
function stopExpireTimer() {
    console.log('stopExpireTimer()');
    clearTimeout(expireTimeout);
}

function getTimeSpanInMilliseconds(startTime, endTime) {
    return (endTime - startTime);
}